import React from 'react';
import { motion } from 'framer-motion';
import {
  MessageSquare,
  Calendar,
  Clock,
  Globe2,
  Shield,
  Zap,
  Sparkles,
  UserCheck,
  BarChart2,
  Smartphone,
} from 'lucide-react';

interface FeaturesProps {
  language: 'en' | 'tr';
}

export function Features({ language }: FeaturesProps) {
  const translations = {
    en: {
      title: "Powerful Features",
      subtitle: "Everything healthcare professionals need for appointment automation",
      features: [
        {
          icon: Smartphone,
          title: "WhatsApp Integration",
          description: "Allow your patients to easily book appointments through WhatsApp",
        },
        {
          icon: Clock,
          title: "Real-time Notifications",
          description: "Send automatic notifications about upcoming appointments to both you and your patients",
        },
        {
          icon: Calendar,
          title: "Automatic Appointment Management",
          description: "Automatically organize and track patient appointments",
        },
        {
          icon: Shield,
          title: "Secure & Reliable",
          description: "GDPR compliant secure data storage system",
        },
        {
          icon: UserCheck,
          title: "User-Friendly Interface",
          description: "Easy to understand, fully Turkish dashboard and user-friendly design",
        },
        {
          icon: BarChart2,
          title: "Analytics Reporting",
          description: "Detailed reporting of conversation and appointment data on daily, weekly and monthly basis",
        },
        {
          icon: Sparkles,
          title: "Personalized Assistant",
          description: "AI-powered personalized patient communication",
        },
        {
          icon: Clock,
          title: "24/7 Uninterrupted Service",
          description: "Your patients can reach you anytime, anywhere",
        },
        {
          icon: MessageSquare,
          title: "Smart Conversation",
          description: "Natural language understanding for seamless patient interactions",
        },
      ],
    },
    tr: {
      title: "Güçlü Özellikler",
      subtitle: "Sağlık profesyonellerinin ihtiyaç duyduğu tüm otomasyon araçları",
      features: [
        {
          icon: Smartphone,
          title: "WhatsApp Entegrasyonu",
          description: "Hastalarınızın WhatsApp üzerinden kolayca randevu almasını sağlayın",
        },
        {
          icon: Clock,
          title: "Gerçek Zamanlı Bildirimler",
          description: "Hem hastalarınıza hem de size yaklaşan randevular hakkında otomatik bildirimler gönderin",
        },
        {
          icon: Calendar,
          title: "Otomatik Randevu Yönetimi",
          description: "Hasta randevularını otomatik olarak düzenleyin ve takip edin",
        },
        {
          icon: Shield,
          title: "Güvenli & Güvenilir",
          description: "KVKK ve GDPR uyumlu güvenli veri saklama sistemi",
        },
        {
          icon: UserCheck,
          title: "Kolay Kullanıcı Arayüzü",
          description: "Kolay anlaşılır, tamamen Türkçe dashboard ve kullanıcı dostu tasarım",
        },
        {
          icon: BarChart2,
          title: "Analitik Raporlama",
          description: "Görüşme ve randevu verilerini günlük, haftalık ve aylık bazda detaylı raporlayın",
        },
        {
          icon: Sparkles,
          title: "Kişiselleştirilmiş Asistan",
          description: "Yapay zeka destekli kişiselleştirilmiş hasta iletişimi",
        },
        {
          icon: Clock,
          title: "7/24 Kesintisiz Hizmet",
          description: "Hastalarınız her zaman, her yerde size ulaşabilsin",
        },
        {
          icon: MessageSquare,
          title: "Akıllı Konuşma",
          description: "Doğal dil anlama yeteneği ile sorunsuz hasta etkileşimleri",
        },
      ],
    },
  };

  const currentLang = translations[language];

  return (
    <section id="features" className="py-24 bg-gradient-to-b from-white to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            {currentLang.title}
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-600"
          >
            {currentLang.subtitle}
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {currentLang.features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <feature.icon className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}