import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, X, Bot, User, Sparkles, Clock, ChevronDown } from 'lucide-react';
import { sendChatMessage, resetChatSession } from '../lib/n8nService';

interface ChatWindowProps {
  isOpen: boolean;
  onClose: () => void;
  remainingCredits: number;
  language?: 'en' | 'tr';
}

interface Message {
  id: string;
  content: string;
  role: 'user' | 'assistant';
  timestamp: Date;
}

export function ChatWindow({ isOpen, onClose, remainingCredits, language = 'en' }: ChatWindowProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const translations = {
    en: {
      title: "BOB AI Assistant",
      subtitle: "Always ready to help",
      initialMessage: "Hello! I'm BOB, how can I help you?",
      inputPlaceholder: "Type your message...",
      remainingCredits: "Remaining Credits:",
      buyCredits: "Buy Credits",
      noCredits: "You've run out of trial credits. Purchase a plan to continue.",
      sessionError: "Session error. Please refresh the page.",
    },
    tr: {
      title: "BOB AI Asistan",
      subtitle: "Her zaman yardıma hazır",
      initialMessage: "Merhaba! Ben BOB, size nasıl yardımcı olabilirim?",
      inputPlaceholder: "Mesajınızı yazın...",
      remainingCredits: "Kalan Krediler:",
      buyCredits: "Kredi Satın Al",
      noCredits: "Deneme kredileriniz tükendi. Devam etmek için bir plan satın alın.",
      sessionError: "Oturum hatası. Lütfen sayfayı yenileyin.",
    }
  };

  const currentLang = translations[language];

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (isOpen && messages.length === 0) {
      setMessages([
        {
          id: '1',
          content: currentLang.initialMessage,
          role: 'assistant',
          timestamp: new Date(),
        },
      ]);
    }
  }, [isOpen, currentLang]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || remainingCredits <= 0) return;

    const userMessage = {
      id: Date.now().toString(),
      content: input,
      role: 'user' as const,
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setIsTyping(true);

    try {
      // Send message to n8n webhook
      const response = await sendChatMessage(input);
      
      const botMessage = {
        id: (Date.now() + 1).toString(),
        content: response,
        role: 'assistant' as const,
        timestamp: new Date(),
      };

      setMessages((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      
      // Handle session errors
      if (error instanceof Error && error.message.includes('session')) {
        const errorMessage = {
          id: (Date.now() + 1).toString(),
          content: currentLang.sessionError,
          role: 'assistant' as const,
          timestamp: new Date(),
        };
        
        setMessages((prev) => [...prev, errorMessage]);
        
        // Reset the chat session
        resetChatSession();
      } else {
        // Generic error message
        const errorMessage = {
          id: (Date.now() + 1).toString(),
          content: 'Sorry, I encountered an error. Please try again later.',
          role: 'assistant' as const,
          timestamp: new Date(),
        };

        setMessages((prev) => [...prev, errorMessage]);
      }
    } finally {
      setIsTyping(false);
    }
  };

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString(language === 'tr' ? 'tr-TR' : 'en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, x: 300, y: 0 }}
          animate={{ opacity: 1, x: 0, y: 0 }}
          exit={{ opacity: 0, x: 300, y: 0 }}
          className="fixed bottom-24 right-6 w-[calc(100vw-3rem)] md:w-[400px] bg-white rounded-2xl shadow-2xl z-40 flex flex-col overflow-hidden"
          style={{ maxHeight: 'calc(100vh - 8rem)' }}
        >
          {/* Header */}
          <div 
            className={`transition-all duration-300 ${
              showHeader ? 'h-auto py-4' : 'h-12 py-2'
            } bg-gradient-to-r from-blue-500 to-indigo-600 px-4`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center">
                  <Bot className="w-5 h-5 text-white" />
                </div>
                <div>
                  <h3 className="font-semibold text-white">{currentLang.title}</h3>
                  {showHeader && (
                    <p className="text-sm text-blue-100">{currentLang.subtitle}</p>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => setShowHeader(!showHeader)}
                  className="text-white/80 hover:text-white transition-colors"
                >
                  <ChevronDown className={`w-5 h-5 transition-transform ${!showHeader ? 'rotate-180' : ''}`} />
                </button>
                <button
                  onClick={onClose}
                  className="text-white/80 hover:text-white transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>

          {/* Messages */}
          <div 
            ref={chatContainerRef}
            className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50"
          >
            {messages.map((message) => (
              <motion.div
                key={message.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className={`flex ${
                  message.role === 'user' ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`flex items-start space-x-2 max-w-[85%] ${
                    message.role === 'user' ? 'flex-row-reverse space-x-reverse' : ''
                  }`}
                >
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 ${
                      message.role === 'user'
                        ? 'bg-blue-500'
                        : 'bg-white border border-gray-200'
                    }`}
                  >
                    {message.role === 'user' ? (
                      <User className="w-5 h-5 text-white" />
                    ) : (
                      <Bot className="w-5 h-5 text-blue-500" />
                    )}
                  </div>
                  <div
                    className={`rounded-2xl px-4 py-2 ${
                      message.role === 'user'
                        ? 'bg-blue-500 text-white'
                        : 'bg-white border border-gray-200'
                    }`}
                  >
                    <p className={message.role === 'user' ? 'text-white' : 'text-gray-800'}>
                      {message.content}
                    </p>
                    <div className="flex items-center justify-end mt-1 space-x-2">
                      <Clock className={`w-3 h-3 ${
                        message.role === 'user' ? 'text-blue-100' : 'text-gray-400'
                      }`} />
                      <span className={`text-xs ${
                        message.role === 'user' ? 'text-blue-100' : 'text-gray-400'
                      }`}>
                        {formatTime(message.timestamp)}
                      </span>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
            {isTyping && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex justify-start"
              >
                <div className="flex items-start space-x-2 max-w-[85%]">
                  <div className="w-8 h-8 rounded-full bg-white border border-gray-200 flex items-center justify-center">
                    <Bot className="w-5 h-5 text-blue-500" />
                  </div>
                  <div className="bg-white border border-gray-200 rounded-2xl px-4 py-2">
                    <div className="flex space-x-1">
                      <motion.div
                        animate={{
                          scale: [1, 1.2, 1],
                          opacity: [0.4, 1, 0.4],
                        }}
                        transition={{ duration: 1, repeat: Infinity }}
                        className="w-2 h-2 bg-blue-500 rounded-full"
                      />
                      <motion.div
                        animate={{
                          scale: [1, 1.2, 1],
                          opacity: [0.4, 1, 0.4],
                        }}
                        transition={{ duration: 1, delay: 0.2, repeat: Infinity }}
                        className="w-2 h-2 bg-blue-500 rounded-full"
                      />
                      <motion.div
                        animate={{
                          scale: [1, 1.2, 1],
                          opacity: [0.4, 1, 0.4],
                        }}
                        transition={{ duration: 1, delay: 0.4, repeat: Infinity }}
                        className="w-2 h-2 bg-blue-500 rounded-full"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Credits Info */}
          <div className="px-4 py-2 bg-blue-50 border-t border-blue-100">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2 text-sm text-blue-600">
                <Sparkles className="w-4 h-4" />
                <span>{currentLang.remainingCredits}</span>
                <span className="font-semibold">{remainingCredits}</span>
              </div>
              {remainingCredits <= 10 && (
                <button className="text-sm text-white bg-blue-500 px-3 py-1 rounded-full hover:bg-blue-600 transition-colors">
                  {currentLang.buyCredits}
                </button>
              )}
            </div>
          </div>

          {/* Input */}
          <form onSubmit={handleSubmit} className="p-4 border-t bg-white">
            <div className="flex space-x-2">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={currentLang.inputPlaceholder}
                className="flex-1 px-4 py-2 rounded-xl border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition-all"
                disabled={remainingCredits <= 0}
              />
              <button
                type="submit"
                className="bg-blue-500 text-white rounded-xl p-2 hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                disabled={!input.trim() || remainingCredits <= 0}
              >
                <Send className="w-6 h-6" />
              </button>
            </div>
            {remainingCredits <= 0 && (
              <p className="mt-2 text-sm text-red-500 text-center">
                {currentLang.noCredits}
              </p>
            )}
          </form>
        </motion.div>
      )}
    </AnimatePresence>
  );
}