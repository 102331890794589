import React from 'react';
import { motion } from 'framer-motion';
import { Briefcase, MapPin, Clock, ArrowRight } from 'lucide-react';

interface CareersPageProps {
  language: 'en' | 'tr';
}

export function CareersPage({ language }: CareersPageProps) {
  const translations = {
    en: {
      title: "Join Our Team",
      subtitle: "Help us shape the future of customer service",
      description: "Want to contribute to the world of AI? Join the BeOneBot team! We offer opportunities to work on innovative projects with a dynamic team and develop future technologies.",
      benefits: {
        title: "Why Join Us?",
        items: [
          "Competitive salary and benefits",
          "Remote-first work culture",
          "Professional development opportunities",
          "Latest technology stack",
          "Health and wellness programs",
          "Stock options",
        ]
      },
      positions: [
        {
          title: "Senior Frontend Developer",
          location: "Remote",
          type: "Full-time",
          description: "We're looking for an experienced frontend developer to help build our next-generation AI interface.",
        },
        {
          title: "AI Research Engineer",
          location: "Istanbul, Turkey",
          type: "Full-time",
          description: "Join our AI team to develop cutting-edge natural language processing solutions.",
        },
        {
          title: "Product Manager",
          location: "Remote",
          type: "Full-time",
          description: "Lead the development of our AI products and shape the future of customer service.",
        },
      ],
      apply: "Apply Now",
    },
    tr: {
      title: "Ekibimize Katılın",
      subtitle: "Müşteri hizmetlerinin geleceğini şekillendirmemize yardımcı olun",
      description: "Yapay zeka dünyasına katkı sağlamak ister misiniz? BeOneBot ekibine katılın! Yenilikçi projelerde yer almak, dinamik bir ekiple çalışmak ve geleceğin teknolojilerini geliştirmek için fırsatlar sunuyoruz.",
      benefits: {
        title: "Neden Biz?",
        items: [
          "Rekabetçi maaş ve yan haklar",
          "Uzaktan çalışma öncelikli kültür",
          "Profesyonel gelişim fırsatları",
          "En son teknoloji stack",
          "Sağlık ve wellness programları",
          "Hisse senedi opsiyonları",
        ]
      },
      positions: [
        {
          title: "Kıdemli Frontend Geliştirici",
          location: "Uzaktan",
          type: "Tam Zamanlı",
          description: "Yeni nesil yapay zeka arayüzümüzü geliştirmek için deneyimli bir frontend geliştirici arıyoruz.",
        },
        {
          title: "Yapay Zeka Araştırma Mühendisi",
          location: "İstanbul, Türkiye",
          type: "Tam Zamanlı",
          description: "Yapay zeka ekibimize katılarak en son doğal dil işleme çözümleri geliştirin.",
        },
        {
          title: "Ürün Yöneticisi",
          location: "Uzaktan",
          type: "Tam Zamanlı",
          description: "Yapay zeka ürünlerimizin geliştirilmesine liderlik edin ve müşteri hizmetlerinin geleceğini şekillendirin.",
        },
      ],
      apply: "Başvur",
    }
  };

  const currentLang = translations[language];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            {currentLang.title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-600 mb-8"
          >
            {currentLang.subtitle}
          </motion.p>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-gray-600 max-w-3xl mx-auto"
          >
            {currentLang.description}
          </motion.p>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white rounded-2xl shadow-lg p-8 mb-16"
        >
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            {currentLang.benefits.title}
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {currentLang.benefits.items.map((benefit, index) => (
              <div
                key={index}
                className="flex items-center space-x-2 text-gray-700"
              >
                <div className="w-2 h-2 bg-blue-500 rounded-full" />
                <span>{benefit}</span>
              </div>
            ))}
          </div>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {currentLang.positions.map((position, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 + 0.4 }}
              className="bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow flex flex-col h-full"
            >
              <div className="p-6 flex-1">
                <div className="flex items-center space-x-2 text-blue-600 mb-4">
                  <Briefcase className="w-5 h-5" />
                  <span className="font-medium">{position.title}</span>
                </div>
                <p className="text-gray-600 mb-4">
                  {position.description}
                </p>
                <div className="flex items-center space-x-4 text-sm text-gray-500">
                  <div className="flex items-center space-x-1">
                    <MapPin className="w-4 h-4" />
                    <span>{position.location}</span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <Clock className="w-4 h-4" />
                    <span>{position.type}</span>
                  </div>
                </div>
              </div>
              <div className="p-6 pt-0">
                <button className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center space-x-2">
                  <span>{currentLang.apply}</span>
                  <ArrowRight className="w-4 h-4" />
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}