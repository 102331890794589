import React from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay, startOfWeek, addDays } from 'date-fns';
import { tr, enUS } from 'date-fns/locale';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface MiniCalendarProps {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  language: 'en' | 'tr';
}

export function MiniCalendar({ selectedDate, onDateChange, language }: MiniCalendarProps) {
  const [currentMonth, setCurrentMonth] = React.useState(startOfMonth(selectedDate));
  const dateLocale = language === 'tr' ? tr : enUS;

  const days = eachDayOfInterval({
    start: startOfMonth(currentMonth),
    end: endOfMonth(currentMonth),
  });

  const previousMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
  const nextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

  // Get day names starting from Monday
  const weekStart = startOfWeek(currentMonth, { weekStartsOn: 1 }); // 1 represents Monday
  const weekDays = Array.from({ length: 7 }).map((_, i) => addDays(weekStart, i));

  return (
    <div className="bg-white rounded-lg shadow p-4">
      {/* Header */}
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={previousMonth}
          className="p-1 hover:bg-gray-100 rounded-full"
        >
          <ChevronLeft className="w-5 h-5 text-gray-500" />
        </button>
        <h3 className="font-medium text-gray-900 text-base">
          {format(currentMonth, 'MMMM yyyy', { locale: dateLocale })}
        </h3>
        <button
          onClick={nextMonth}
          className="p-1 hover:bg-gray-100 rounded-full"
        >
          <ChevronRight className="w-5 h-5 text-gray-500" />
        </button>
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-1">
        {/* Weekday Headers */}
        {weekDays.map((day, i) => (
          <div
            key={i}
            className="h-8 flex items-center justify-center text-xs font-medium text-gray-500"
          >
            {format(day, 'EEEEE', { locale: dateLocale })}
          </div>
        ))}

        {/* Days */}
        {days.map((day, dayIdx) => {
          // Calculate the day of the week (0-6, where 0 is Monday)
          const dayOfWeek = (day.getDay() + 6) % 7; // Convert Sunday=0 to Monday=0
          
          // Calculate the start date to display (including days from previous/next month to fill the grid)
          const startDate = startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 1 });
          
          // Calculate the actual date to display
          const dateToDisplay = addDays(startDate, dayIdx);
          
          return (
            <button
              key={dateToDisplay.toString()}
              onClick={() => onDateChange(dateToDisplay)}
              className={`h-8 flex items-center justify-center text-sm rounded-full transition-colors ${
                isSameDay(dateToDisplay, selectedDate)
                  ? 'bg-blue-500 text-white font-medium'
                  : isSameMonth(dateToDisplay, currentMonth)
                  ? 'text-gray-900 hover:bg-gray-100'
                  : 'text-gray-400'
              }`}
            >
              {format(dateToDisplay, 'd')}
            </button>
          );
        })}
      </div>
    </div>
  );
}