import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Link, Navigate, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageSquare, Languages } from 'lucide-react';
import { supabase, clearAuthData } from './lib/supabase';
import { useDashboardStore } from './store/dashboardStore';
import { ParticleBackground } from './components/ParticleBackground';
import { Features } from './components/Features';
import { AppointmentChat } from './components/AppointmentChat';
import { ChatInterface } from './components/ChatInterface';
import { TestimonialCard } from './components/TestimonialCard';
import { ContactForm } from './components/ContactForm';
import { AuthModal } from './components/AuthModal';
import { BobAvatar } from './components/BobAvatar';
import { ChatWindow } from './components/ChatWindow';
import { Footer } from './components/Footer';
import { DocumentationPage } from './pages/Documentation';
import { AboutPage } from './pages/About';
import { BlogPage } from './pages/Blog';
import { CareersPage } from './pages/Careers';
import { PrivacyPage } from './pages/Privacy';
import { TermsPage } from './pages/Terms';
import { SecurityPage } from './pages/Security';

// Import BOBUniverse directly to avoid dynamic import issues
import BOBUniverse from './pages/BOBUniverse';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      setLoading(false);
    };
    
    checkSession();
  }, []);

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500" />
    </div>;
  }

  if (!session) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}

function ScrollToSection() {
  const location = useLocation();
  
  useEffect(() => {
    if (location.state?.scrollTo) {
      const section = document.querySelector(`#${location.state.scrollTo}`);
      section?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
}

function PageTransition({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

function App() {
  const [language, setLanguage] = useState<'en' | 'tr'>('tr');
  const [isAuthOpen, setIsAuthOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [remainingCredits, setRemainingCredits] = useState(100);
  const [isAuthComplete, setIsAuthComplete] = useState(false);
  const [isChangingLanguage, setIsChangingLanguage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = () => {
    setIsChangingLanguage(true);
    setTimeout(() => {
      const newLanguage = language === 'en' ? 'tr' : 'en';
      setLanguage(newLanguage);
      // Sync language with dashboard store
      useDashboardStore.getState().syncLanguage?.(newLanguage);
      setIsChangingLanguage(false);
    }, 200);
  };

  const translations = {
    en: {
      nav: {
        signIn: 'Sign In',
        signOut: 'Sign Out',
      },
      hero: {
        badge: 'AI-Powered Appointment Management',
        title: 'Automate Your Healthcare Services with BOB Universe',
        subtitle: 'AI-powered automatic appointment system for private practices and clinics. Integrate with WhatsApp and your website to streamline appointments and patient communication.',
        cta: {
          primary: 'Get Started',
          secondary: 'Learn More',
        },
      },
      testimonials: {
        title: 'Customer Reviews',
        items: [
          {
            name: "Dr. John Smith",
            role: "Dentist",
            company: "Smith Dental Clinic",
            content: "Thanks to BOB, my dependency on my secretary has decreased and my patient appointments have become more organized.",
            rating: 5,
            image: 'https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          },
          {
            name: "Dr. Sarah Johnson",
            role: "Plastic Surgeon",
            company: "Johnson Aesthetics",
            content: "Automatic notifications and appointment reminders have significantly reduced our patient no-show rate.",
            rating: 5,
            image: 'https://images.unsplash.com/photo-1594824476967-48c8b964273f?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          },
          {
            name: "Dr. Michael Brown",
            role: "Cardiologist",
            company: "Heart Care Center",
            content: "We've completely automated our appointment system, and both our patients and staff are very satisfied.",
            rating: 5,
            image: 'https://images.unsplash.com/photo-1537368910025-700350fe46c7?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          },
        ]
      },
      contact: {
        title: "Contact",
      },
    },
    tr: {
      nav: {
        signIn: 'Giriş Yap',
        signOut: 'Çıkış Yap',
      },
      hero: {
        badge: 'Yapay Zeka Destekli Randevu Yönetimi',
        title: 'Sağlık Hizmetlerinizi BOB Universe ile Otomatikleştirin',
        subtitle: 'Özel muayenehaneler ve klinikler için yapay zeka destekli otomatik randevu sistemi. WhatsApp ve web sitenizle entegre ederek randevularınızı ve hasta iletişimini kolaylaştırın.',
        cta: {
          primary: 'Hemen Başla',
          secondary: 'Daha Fazla Bilgi',
        },
      },
      testimonials: {
        title: 'Müşteri Yorumları',
        items: [
          {
            name: "Dt. Ahmet Yılmaz",
            role: "Diş Hekimi",
            company: "Yılmaz Diş Kliniği",
            content: "BOB sayesinde sekreterime bağımlılığım azaldı ve hasta randevularım daha düzenli hale geldi.",
            rating: 5,
            image: 'https://images.unsplash.com/photo-1612349317150-e413f6a5b16d?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          },
          {
            name: "Dr. Ayşe Demir",
            role: "Plastik Cerrah",
            company: "Demir Estetik",
            content: "Otomatik bildirimler ve randevu hatırlatmaları sayesinde hasta no-show oranımız ciddi oranda düştü.",
            rating: 5,
            image: 'https://images.unsplash.com/photo-1594824476967-48c8b964273f?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          },
          {
            name: "Dr. Mehmet Kaya",
            role: "Kardiyolog",
            company: "Kalp Sağlığı Merkezi",
            content: "Randevu sistemimizi tamamen otomatikleştirdik, hastalarımız ve çalışanlarımız çok memnun.",
            rating: 5,
            image: 'https://images.unsplash.com/photo-1537368910025-700350fe46c7?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
          },
        ]
      },
      contact: {
        title: "İletişim",
      },
    },
  };

  const currentLang = translations[language];

  useEffect(() => {
    // Initialize auth state
    const initAuth = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Session retrieval error:', error);
          clearAuthData();
          return;
        }

        if (session) {
          setUser(session.user);
          setTimeout(() => setIsAuthComplete(true), 500);
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        clearAuthData();
      }
    };

    initAuth();

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth event:', event);
      
      switch (event) {
        case 'SIGNED_IN':
          if (session?.user) {
            setUser(session.user);
            setTimeout(() => setIsAuthComplete(true), 500);
          }
          break;
          
        case 'SIGNED_OUT':
        case 'USER_DELETED':
          setUser(null);
          setIsAuthComplete(false);
          setIsChatOpen(false);
          clearAuthData();
          navigate('/');
          break;
          
        case 'TOKEN_REFRESHED':
          if (session?.user) {
            setUser(session.user);
          }
          break;
          
        case 'USER_UPDATED':
          if (session?.user) {
            setUser(session.user);
          }
          break;
      }
    });

    // Cleanup subscription
    return () => {
      subscription.unsubscribe();
    };
  }, [navigate]);

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      // clearAuthData is called in the onAuthStateChange handler
      navigate('/');
    } catch (error) {
      console.error('Sign out error:', error);
      // Clear auth data even if sign out fails
      clearAuthData();
      navigate('/');
    }
  };

  const scrollToFeatures = () => {
    const featuresSection = document.querySelector('#features');
    featuresSection?.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContact = () => {
    const contactSection = document.querySelector('#contact');
    contactSection?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-white text-gray-900 relative">
      <ScrollToSection />
      <ParticleBackground />
      
      {/* Header */}
      <header className="sticky top-0 z-[60] backdrop-blur-lg bg-white/50 border-b border-gray-200">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
          <div className="flex items-center space-x-8">
            <div className="flex items-center space-x-2">
              <MessageSquare className="h-8 w-8 text-blue-500" />
              <Link to="/" className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-500">
                BeOneBot
              </Link>
            </div>
            {user && (
              <Link
                to="/bob-universe"
                className={`text-base font-medium transition-colors px-4 py-2 rounded-lg ${
                  location.pathname.startsWith('/bob-universe')
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-600 hover:text-blue-600 bg-gray-50 hover:bg-blue-50'
                }`}
              >
                BOB Universe
              </Link>
            )}
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={handleLanguageChange}
              disabled={isChangingLanguage}
              className={`text-gray-600 hover:text-gray-900 transition-opacity ${
                isChangingLanguage ? 'opacity-50' : 'opacity-100'
              }`}
            >
              <Languages className="h-5 w-5" />
            </button>
            {user ? (
              <button
                onClick={handleSignOut}
                className="bg-gray-100 hover:bg-gray-200 text-gray-900 px-4 py-2 rounded-lg"
              >
                {currentLang.nav.signOut}
              </button>
            ) : (
              <button
                onClick={() => setIsAuthOpen(true)}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                {currentLang.nav.signIn}
              </button>
            )}
          </div>
        </nav>
      </header>

      <PageTransition>
        <Routes>
          <Route path="/docs" element={<DocumentationPage language={language} />} />
          <Route path="/about" element={<AboutPage language={language} />} />
          <Route path="/blog/*" element={<BlogPage language={language} />} />
          <Route path="/careers" element={<CareersPage language={language} />} />
          <Route path="/privacy" element={<PrivacyPage language={language} />} />
          <Route path="/terms" element={<TermsPage language={language} />} />
          <Route path="/security" element={<SecurityPage language={language} />} />
          <Route
            path="/bob-universe/*"
            element={
              <ProtectedRoute>
                <Suspense fallback={
                  <div className="min-h-screen flex items-center justify-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500" />
                  </div>
                }>
                  <BOBUniverse />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route path="/" element={
            <>
              {/* Hero Section */}
              <section className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, ease: "easeOut" }}
                  className="text-center space-y-8"
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.2, duration: 0.6 }}
                    className="inline-flex items-center space-x-2 px-4 py-2 rounded-full bg-blue-50 border border-blue-100"
                  >
                    <span className="text-blue-700">{currentLang.hero.badge}</span>
                  </motion.div>

                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.6 }}
                    className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-500"
                  >
                    {currentLang.hero.title}
                  </motion.h1>

                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6, duration: 0.6 }}
                    className="text-xl text-gray-600 max-w-2xl mx-auto"
                  >
                    {currentLang.hero.subtitle}
                  </motion.p>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.8, duration: 0.6 }}
                    className="flex justify-center space-x-4"
                  >
                    <button
                      onClick={() => setIsAuthOpen(true)}
                      className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg font-medium transform transition-all duration-200 hover:scale-105"
                    >
                      {currentLang.hero.cta.primary}
                    </button>
                    <button 
                      onClick={scrollToFeatures}
                      className="bg-gray-100 hover:bg-gray-200 text-gray-900 px-6 py-3 rounded-lg font-medium transform transition-all duration-200 hover:scale-105"
                    >
                      {currentLang.hero.cta.secondary}
                    </button>
                  </motion.div>
                </motion.div>
              </section>

              {/* Features */}
              <Features language={language} />

              {/* Appointment Booking Example */}
              <section className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                <AppointmentChat language={language} />
              </section>

              {/* Testimonials */}
              <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                <h2 className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-500">
                  {currentLang.testimonials.title}
                </h2>
                <div className="grid md:grid-cols-3 gap-8">
                  {currentLang.testimonials.items.map((testimonial, index) => (
                    <TestimonialCard key={testimonial.name} {...testimonial} delay={index * 0.2} />
                  ))}
                </div>
              </section>

              {/* E-commerce Example */}
              <section className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                <ChatInterface language={language} />
              </section>

              {/* Contact */}
              <section id="contact" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
                <h2 className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-500">
                  {currentLang.contact.title}
                </h2>
                <ContactForm language={language} />
              </section>
            </>
          } />
        </Routes>
      </PageTransition>

      <Footer language={language} />

      {/* Auth Modal */}
      <AuthModal 
        isOpen={isAuthOpen} 
        onClose={() => {
          setIsAuthOpen(false);
          if (user) {
            setTimeout(() => setIsAuthComplete(true), 500);
          }
        }} 
      />

      {/* BOB Avatar & Chat Window */}
      {user && isAuthComplete && (
        <>
          <BobAvatar onClick={() => setIsChatOpen(!isChatOpen)} isOpen={isChatOpen} />
          <ChatWindow
            isOpen={isChatOpen}
            onClose={() => setIsChatOpen(false)}
            remainingCredits={remainingCredits}
            language={language}
          />
        </>
      )}
    </div>
  );
}

export default App;