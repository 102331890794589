import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Bell, Calendar, MessageSquare, User, Clock, CheckCircle, X, Filter, Clipboard, RefreshCw, Search } from 'lucide-react';

interface Notification {
  id: string;
  type: 'appointment' | 'message' | 'reminder' | 'system';
  title: string;
  message: string;
  time: Date;
  read: boolean;
  patientName?: string;
  procedure?: string;
}

export default function Notifications() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Simulate API call to fetch notifications
    setTimeout(() => {
      const mockNotifications: Notification[] = [
        {
          id: '1',
          type: 'appointment',
          title: 'Yeni Randevu',
          message: 'Ahmet Yılmaz için yarın saat 14:30\'da diş dolgusu randevusu oluşturuldu.',
          time: new Date(Date.now() - 1000 * 60 * 30), // 30 minutes ago
          read: false,
          patientName: 'Ahmet Yılmaz',
          procedure: 'Diş Dolgusu'
        },
        {
          id: '2',
          type: 'message',
          title: 'Yeni Mesaj',
          message: 'Ayşe Demir randevusu hakkında bilgi almak istiyor.',
          time: new Date(Date.now() - 1000 * 60 * 60 * 2), // 2 hours ago
          read: false,
          patientName: 'Ayşe Demir'
        },
        {
          id: '3',
          type: 'reminder',
          title: 'Randevu Hatırlatması',
          message: 'Mehmet Kaya\'nın kontrol randevusu yarın saat 16:30\'da.',
          time: new Date(Date.now() - 1000 * 60 * 60 * 5), // 5 hours ago
          read: true,
          patientName: 'Mehmet Kaya',
          procedure: 'Kontrol'
        },
        {
          id: '4',
          type: 'system',
          title: 'Sistem Bildirimi',
          message: 'Haftalık randevu raporu hazır.',
          time: new Date(Date.now() - 1000 * 60 * 60 * 24), // 1 day ago
          read: true
        },
        {
          id: '5',
          type: 'appointment',
          title: 'İptal Edilen Randevu',
          message: 'Zeynep Şahin bugün saat 11:00\'deki randevusunu iptal etti.',
          time: new Date(Date.now() - 1000 * 60 * 60 * 26), // 26 hours ago
          read: true,
          patientName: 'Zeynep Şahin',
          procedure: 'Diş Çekimi'
        },
        {
          id: '6',
          type: 'message',
          title: 'Yeni Mesaj',
          message: 'Ali Öztürk tedavi sonrası bakım hakkında bilgi istiyor.',
          time: new Date(Date.now() - 1000 * 60 * 60 * 28), // 28 hours ago
          read: true,
          patientName: 'Ali Öztürk'
        },
        {
          id: '7',
          type: 'reminder',
          title: 'Malzeme Hatırlatması',
          message: 'Dolgu malzemesi stoğu azalıyor, sipariş vermeyi unutmayın.',
          time: new Date(Date.now() - 1000 * 60 * 60 * 48), // 2 days ago
          read: true
        }
      ];
      
      setNotifications(mockNotifications);
      setLoading(false);
    }, 1000);
  }, []);

  const markAsRead = (id: string) => {
    setNotifications(notifications.map(notification => 
      notification.id === id ? { ...notification, read: true } : notification
    ));
  };

  const markAllAsRead = () => {
    setNotifications(notifications.map(notification => ({ ...notification, read: true })));
  };

  const deleteNotification = (id: string) => {
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  const getTypeIcon = (type: string) => {
    switch (type) {
      case 'appointment':
        return <Calendar className="w-5 h-5 text-blue-500" />;
      case 'message':
        return <MessageSquare className="w-5 h-5 text-green-500" />;
      case 'reminder':
        return <Clock className="w-5 h-5 text-yellow-500" />;
      case 'system':
        return <Bell className="w-5 h-5 text-purple-500" />;
      default:
        return <Bell className="w-5 h-5 text-gray-500" />;
    }
  };

  const formatTime = (date: Date) => {
    const now = new Date();
    const diffInHours = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60));
    
    if (diffInHours < 1) {
      const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));
      return `${diffInMinutes} dakika önce`;
    } else if (diffInHours < 24) {
      return `${diffInHours} saat önce`;
    } else {
      const diffInDays = Math.floor(diffInHours / 24);
      return `${diffInDays} gün önce`;
    }
  };

  const filteredNotifications = notifications.filter(notification => {
    const matchesFilter = filter === 'all' || notification.type === filter;
    const matchesSearch = searchQuery === '' || 
      notification.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      notification.message.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (notification.patientName && notification.patientName.toLowerCase().includes(searchQuery.toLowerCase()));
    
    return matchesFilter && matchesSearch;
  });

  const unreadCount = notifications.filter(notification => !notification.read).length;

  const handleRefresh = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="space-y-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-xl border border-gray-200 p-6 shadow-lg"
      >
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <Bell className="w-5 h-5 text-blue-500" />
            </div>
            <h2 className="text-lg font-semibold text-gray-900">Bildirimler</h2>
            {unreadCount > 0 && (
              <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                {unreadCount} yeni
              </span>
            )}
          </div>
          
          <div className="flex items-center space-x-4">
            {/* Refresh Button */}
            <button
              onClick={handleRefresh}
              className="p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100"
              disabled={loading}
            >
              <RefreshCw className={`w-5 h-5 ${loading ? 'animate-spin' : ''}`} />
            </button>
            
            {unreadCount > 0 && (
              <button
                onClick={markAllAsRead}
                className="px-3 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center"
              >
                <CheckCircle className="w-4 h-4 mr-2" />
                <span>Tümünü Okundu İşaretle</span>
              </button>
            )}
          </div>
        </div>
        
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
          <div className="relative flex-grow">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Bildirimlerde ara..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
            />
          </div>
          
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
          >
            <option value="all">Tüm Bildirimler</option>
            <option value="appointment">Randevular</option>
            <option value="message">Mesajlar</option>
            <option value="reminder">Hatırlatmalar</option>
            <option value="system">Sistem</option>
          </select>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <RefreshCw className="w-8 h-8 text-blue-500 animate-spin" />
          </div>
        ) : filteredNotifications.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-64 text-gray-500">
            <Bell className="w-12 h-12 mb-4 text-gray-300" />
            <p>Bildirim bulunamadı</p>
            {searchQuery && (
              <button
                onClick={() => setSearchQuery('')}
                className="mt-2 text-blue-500 hover:text-blue-700"
              >
                Aramayı Temizle
              </button>
            )}
          </div>
        ) : (
          <div className="space-y-4">
            {filteredNotifications.map((notification) => (
              <motion.div
                key={notification.id}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className={`p-4 rounded-lg border ${
                  notification.read ? 'bg-white border-gray-200' : 'bg-blue-50 border-blue-200'
                } relative`}
              >
                <div className="flex">
                  <div className="mr-4">
                    <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                      notification.type === 'appointment' ? 'bg-blue-100' :
                      notification.type === 'message' ? 'bg-green-100' :
                      notification.type === 'reminder' ? 'bg-yellow-100' : 'bg-purple-100'
                    }`}>
                      {getTypeIcon(notification.type)}
                    </div>
                  </div>
                  <div className="flex-1" onClick={() => markAsRead(notification.id)}>
                    <div className="flex justify-between">
                      <h3 className="font-medium text-gray-900">{notification.title}</h3>
                      <span className="text-sm text-gray-500">{formatTime(notification.time)}</span>
                    </div>
                    <p className="text-gray-600 mt-1">{notification.message}</p>
                    {notification.patientName && (
                      <div className="mt-2 flex items-center text-sm text-gray-500">
                        <User className="w-4 h-4 mr-1" />
                        <span>{notification.patientName}</span>
                        {notification.procedure && (
                          <>
                            <span className="mx-2">•</span>
                            <Clipboard className="w-4 h-4 mr-1" />
                            <span>{notification.procedure}</span>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => deleteNotification(notification.id)}
                    className="ml-2 text-gray-400 hover:text-gray-600"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
                {!notification.read && (
                  <div className="absolute top-4 right-4 w-2 h-2 bg-blue-500 rounded-full"></div>
                )}
              </motion.div>
            ))}
          </div>
        )}
      </motion.div>
    </div>
  );
}