import React from 'react';
import { motion } from 'framer-motion';
import { Users, Target, Award, Sparkles } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface AboutPageProps {
  language: 'en' | 'tr';
}

export function AboutPage({ language }: AboutPageProps) {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/', { state: { scrollTo: 'contact' } });
  };

  const translations = {
    en: {
      title: 'About BeOneBot',
      subtitle: 'Transforming customer service with AI',
      description:
        "BeOneBot is redefining customer service through the power of artificial intelligence. Our advanced AI solutions enable businesses to make their customer interactions more effective, efficient, and personalized. Our platform increases customer satisfaction by up to 40%, providing 24/7 uninterrupted service that enhances businesses' competitive advantage while optimizing operational costs.\n\nStriking the optimal balance between technology and human interaction, BeOneBot's AI-powered chatbot solutions automate routine queries while enabling human customer service representatives to work more effectively on complex issues. Thanks to our advanced natural language processing technology, we accurately understand customer requests and improve the customer experience by providing fast and consistent responses.\n\nIn line with our sustainable growth vision, our continuously learning and evolving AI models gain valuable insights from every interaction. This allows us to quickly adapt to our customers' changing needs and continuously improve our service quality through data-driven decisions. As BeOneBot, our goal is to make AI technology accessible to businesses of all sizes, leading Turkey's digital transformation and becoming a significant player in the global market.",
      values: [
        {
          icon: Users,
          title: 'Customer First',
          description: 'We prioritize customer success in everything we do',
        },
        {
          icon: Target,
          title: 'Innovation',
          description: 'Continuously pushing the boundaries of AI technology',
        },
        {
          icon: Award,
          title: 'Excellence',
          description: 'Committed to delivering the highest quality solutions',
        },
        {
          icon: Sparkles,
          title: 'Accessibility',
          description:
            'Making AI technology accessible to businesses of all sizes',
        },
      ],
      team: {
        title: 'Our Team',
        founder: {
          name: 'Şafak Birol - Founder',
          bio: 'Şafak Birol is the founder of BeOneBot and a leading name in AI-powered customer service solutions. With expertise in digital transformation, smart manufacturing systems, and AI-based software development, Şafak Birol established BeOneBot to provide innovative, efficient, and accessible solutions to businesses.',
          teamDescription:
            "Under his leadership, BeOneBot aims to create a seamless synergy between humans and artificial intelligence, offering a user-friendly and effective platform. The platform's primary focus is to enhance user experience by automating customer service processes, improving operational efficiency, and addressing the unique needs of various industries.",
        },
      },
      digitalTeam: {
        title: 'Meet Our Digital Talents',
        subtitle:
          "For the seamless operation and continuous development of the BeOneBot platform, a team of artificial intelligence personas, each specialized in their own field, is at work. Meet our digital teammates, trained with human expertise and working towards BeOneBot's goals:",
        members: [
          {
            name: 'Kaan',
            role: 'Software Developer (AI)',
            description:
              'Builds the code infrastructure of the BeOneBot platform, develops new features, and continuously optimizes system performance.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//kaan1.png',
          },
          {
            name: 'Alara',
            role: 'Visual Designer (AI)',
            description:
              "Ensures the platform's user interface (UI) is aesthetic and user-friendly. Produces and updates visual materials consistent with the brand identity.",
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//kadn.png',
          },
          {
            name: 'Orion',
            role: 'Project Manager (AI)',
            description:
              'Plans, tracks, and coordinates development processes and tasks. Ensures efficient resource utilization and timely project completion.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//orion1%20.png',
          },
          {
            name: 'Aura',
            role: 'Data Analyst (AI)',
            description:
              'Analyzes user behavior and system metrics to provide meaningful reports and insights for platform improvement.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//aura1.png',
          },
          {
            name: 'Tayfun',
            role: 'Communication Specialist (AI)',
            description:
              'Improves chatbot dialogues, prepares internal informational texts for the platform, and ensures communication with users is clear and effective.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//adam%20.png',
          },
          {
            name: 'Bilge',
            role: 'Tech Lead & Mentor (AI)',
            description:
              "Guides the platform's overall technical architecture, sets technological standards, and helps the team focus on long-term goals.",
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//bilge.png',
          },
        ],
      },
      contact: {
        title: 'Get in Touch',
        description:
          "Want to learn more about BeOneBot? We'd love to hear from you.",
        button: 'Contact Us',
      },
    },
    tr: {
      title: 'BeOneBot Hakkında',
      subtitle: 'Yapay zeka ile müşteri hizmetlerini dönüştürüyoruz',
      description:
        "BeOneBot, yapay zeka teknolojisinin gücünü kullanarak müşteri hizmetlerini yeniden tanımlıyor. Geliştirdiğimiz ileri düzey yapay zeka çözümleri, işletmelerin müşterileriyle olan etkileşimlerini daha etkili, verimli ve kişiselleştirilmiş hale getiriyor. Müşteri memnuniyetini %40'a varan oranlarda artıran platformumuz, 7/24 kesintisiz hizmet sunarak işletmelerin rekabet gücünü artırıyor ve operasyonel maliyetlerini optimize ediyor.\n\nTeknoloji ve insan etkileşiminin optimal dengesini sağlayan BeOneBot, yapay zeka destekli chatbot çözümlerimizle rutin sorguları otomatikleştirirken, karmaşık konularda insan müşteri temsilcilerinin daha etkin çalışmasına olanak tanıyor. Gelişmiş doğal dil işleme teknolojimiz sayesinde müşteri taleplerini doğru anlıyor, hızlı ve tutarlı yanıtlar sunarak müşteri deneyimini iyileştiriyoruz.\n\nSürdürülebilir büyüme vizyonumuz doğrultusunda, sürekli öğrenen ve gelişen yapay zeka modellerimiz, her etkileşimden değerli içgörüler elde ediyor. Bu sayede müşterilerimizin değişen ihtiyaçlarına hızla adapte olabiliyor, veri odaklı kararlar alarak hizmet kalitemizi sürekli yükseltiyoruz. BeOneBot olarak amacımız, yapay zeka teknolojisini her ölçekteki işletme için erişilebilir kılarak, Türkiye'nin dijital dönüşümüne öncülük etmek ve global pazarda söz sahibi olmaktır.",
      values: [
        {
          icon: Users,
          title: 'Müşteri Önceliği',
          description:
            'Yaptığımız her şeyde müşteri başarısını önceliklendiriyoruz',
        },
        {
          icon: Target,
          title: 'İnovasyon',
          description:
            'Yapay zeka teknolojisinin sınırlarını sürekli zorluyoruz',
        },
        {
          icon: Award,
          title: 'Mükemmellik',
          description: 'En yüksek kalitede çözümler sunmaya kararlıyız',
        },
        {
          icon: Sparkles,
          title: 'Erişilebilirlik',
          description:
            'Yapay zeka teknolojisini her ölçekteki işletme için erişilebilir kılıyoruz',
        },
      ],
      team: {
        title: 'Ekibimiz',
        founder: {
          name: 'Şafak Birol - Kurucu',
          bio: "Şafak Birol, BeOneBot'un kurucusu ve yapay zeka destekli müşteri hizmetleri çözümleri alanında lider bir isimdir. Dijital dönüşüm, akıllı üretim sistemleri ve yapay zeka tabanlı yazılımlar geliştirme konularında derin uzmanlığa sahip olan Şafak Birol, BOB Universe ile işletmelere daha yenilikçi, verimli ve erişilebilir çözümler sunmayı amaçlamaktadır.",
          teamDescription:
            "BeOneBot, Şafak Birol'un liderliğinde; insan ve yapay zekanın uyumlu çalıştığı, kullanımı kolay ve etkili bir platform yaratmayı hedefler. Platformun odak noktası, müşteri hizmetlerini otomatikleştirerek kullanıcı deneyimini iyileştirmek, işletmelerin operasyonel verimliliğini artırmak ve farklı sektörlerin benzersiz ihtiyaçlarına cevap vermektir.",
        },
      },
      digitalTeam: {
        title: 'Dijital Yeteneklerimizle Tanışın',
        subtitle:
          "BeOneBot platformunun sorunsuz çalışması ve sürekli gelişimi için, her biri kendi alanında uzmanlaşmış yapay zeka kişiliklerinden oluşan bir ekip görev yapıyor. İnsan uzmanlığıyla eğitilen ve BeOneBot'un hedeflerine ulaşması için çalışan dijital takım arkadaşlarımızla tanışın:",
        members: [
          {
            name: 'Kaan',
            role: 'Yazılım Geliştirici (AI)',
            description:
              'BeOneBot platformunun kod altyapısını oluşturur, yeni özellikler geliştirir ve sistem performansını sürekli olarak optimize eder.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//kaan1.png',
          },
          {
            name: 'Alara',
            role: 'Görsel Tasarımcı (AI)',
            description:
              'Platformun kullanıcı arayüzünün (UI) estetik ve kullanıcı dostu olmasını sağlar. Marka kimliğine uygun görsel materyalleri üretir ve günceller.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//kadn.png',
          },
          {
            name: 'Orion',
            role: 'Proje Yöneticisi (AI)',
            description:
              'Geliştirme süreçlerini ve görevleri planlar, takip eder ve koordine eder. Kaynakların verimli kullanılmasını ve projelerin zamanında tamamlanmasını sağlar.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//orion1%20.png',
          },
          {
            name: 'Aura',
            role: 'Veri Analisti (AI)',
            description:
              'Kullanıcı davranışlarını ve sistem metriklerini analiz ederek platformun iyileştirilmesi için anlamlı raporlar ve içgörüler sunar.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//aura1.png',
          },
          {
            name: 'Tayfun',
            role: 'İletişim Uzmanı (AI)',
            description:
              'Chatbot diyaloglarını iyileştirir, platform içi bilgilendirme metinlerini hazırlar ve kullanıcılarla etkileşimin açık ve etkili olmasını sağlar.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//adam%20.png',
          },
          {
            name: 'Bilge',
            role: 'Teknik Lider & Mentor (AI)',
            description:
              'Platformun genel teknik mimarisine yön verir, teknolojik standartları belirler ve ekibin uzun vadeli hedeflere odaklanmasına yardımcı olur.',
            image:
              'https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//bilge.png',
          },
        ],
      },
      contact: {
        title: 'İletişime Geçin',
        description:
          'BeOneBot hakkında daha fazla bilgi edinmek ister misiniz? Sizden haber almaktan mutluluk duyarız.',
        button: 'Bize Ulaşın',
      },
    },
  };

  const currentLang = translations[language];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            {currentLang.title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-600 mb-12"
          >
            {currentLang.subtitle}
          </motion.p>

          <div className="grid md:grid-cols-2 gap-12 items-center mb-24">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="relative rounded-3xl overflow-hidden shadow-2xl"
            >
              <img
                src="https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//ChatGPT%20Image%204%20Nis%202025%2018_53_07.png"
                alt="BOB AI Assistant"
                className="w-full h-auto object-contain"
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3 }}
            >
              <p className="text-gray-600 whitespace-pre-line text-justify leading-relaxed text-base md:text-lg">
                {currentLang.description}
              </p>
            </motion.div>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-24">
            {currentLang.values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 + 0.3 }}
                className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow text-center"
              >
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 mx-auto">
                  <value.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {value.title}
                </h3>
                <p className="text-gray-600 text-base md:text-lg">
                  {value.description}
                </p>
              </motion.div>
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-white rounded-2xl p-8 md:p-12 shadow-lg mb-24"
          >
            <div className="max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">
                {currentLang.team.title}
              </h2>

              <div className="flex flex-col md:flex-row items-center gap-8 md:gap-12 mb-8 bg-gray-50 rounded-xl p-8 border border-gray-200">
                <div className="w-48 h-48 md:w-64 md:h-64 rounded-xl overflow-hidden shadow-lg flex-shrink-0">
                  <a
                    href="https://www.linkedin.com/in/safakbirol/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://ioxpgwpajulsqnknikil.supabase.co/storage/v1/object/public/v1//22223.jpg"
                      alt="Şafak Birol"
                      className="w-full h-full object-cover"
                    />
                  </a>
                </div>

                <div className="flex-1">
                  <h3 className="text-2xl font-semibold text-blue-600 mb-4 text-center md:text-left">
                    {currentLang.team.founder.name}
                  </h3>
                  <p
                    className="text-gray-600 text-center md:text-left text-justify leading-relaxed mb-6 text-base md:text-lg max-w-xl mx-auto md:mx-0"
                    style={{ textAlign: 'justify' }}
                  >
                    {currentLang.team.founder.bio}
                  </p>
                </div>
              </div>

              <p className="text-gray-600 text-center leading-relaxed text-base md:text-lg max-w-2xl mx-auto">
                {currentLang.team.founder.teamDescription}
              </p>
            </div>
          </motion.div>

          {/* Section Divider */}
          <div className="w-full max-w-4xl mx-auto h-px bg-gray-200 mb-24"></div>

          {/* AI Team Section */}
          <section className="py-12 mb-24 bg-blue-50 rounded-3xl p-8">
            <motion.h2
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: 'easeInOut' }}
              className="text-3xl font-bold text-gray-900 mb-8 text-center"
            >
              {currentLang.digitalTeam.title}
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2, ease: 'easeInOut' }}
              className="text-gray-600 max-w-2xl mx-auto text-center mb-12 text-base md:text-lg"
            >
              {currentLang.digitalTeam.subtitle}
            </motion.p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {currentLang.digitalTeam.members.map((member, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    delay: 0.3 + index * 0.1,
                    ease: 'easeInOut',
                  }}
                  className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow flex flex-col h-full"
                >
                  <div className="w-full h-48 md:h-52 mb-4 overflow-hidden rounded-xl">
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-full object-cover"
                      style={{
                        width: '100%',
                        height: '200px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div className="flex-1 flex flex-col">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {member.name}
                    </h3>
                    <p className="text-blue-600 font-medium text-sm mb-3">
                      {member.role}
                    </p>
                    <p className="text-gray-600 text-base flex-1">
                      {member.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </section>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="bg-gradient-to-r from-blue-500 to-indigo-600 rounded-2xl p-12 text-center text-white"
          >
            <h2 className="text-3xl font-bold mb-4">
              {currentLang.contact.title}
            </h2>
            <p className="mb-8 text-blue-100 max-w-2xl mx-auto text-base md:text-lg">
              {currentLang.contact.description}
            </p>
            <button
              onClick={handleContactClick}
              className="bg-white text-blue-600 px-8 py-3 rounded-lg font-medium hover:bg-blue-50 transition-colors"
            >
              {currentLang.contact.button}
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
