import { create } from 'zustand';
import { supabase } from '../lib/supabase';

export interface UserSettings {
  id?: string;
  userId: string;
  theme: 'system' | 'light' | 'dark';
  accentColor: 'blue' | 'purple' | 'green' | 'red';
  emailNotifications: boolean;
  pushNotifications: boolean;
  language: 'en' | 'tr';
  timezone: string;
  twoFactorAuth: boolean;
  sessionTimeout: '15' | '30' | '60' | '240'; // minutes
  apiKey?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface SettingsState {
  settings: UserSettings | null;
  loading: boolean;
  error: string | null;
  isDemo: boolean;
  
  // Actions
  fetchSettings: (userId: string) => Promise<void>;
  updateSettings: (settings: Partial<UserSettings>) => Promise<void>;
  regenerateApiKey: (userId: string) => Promise<string>;
  toggleSetting: (setting: keyof UserSettings, value?: any) => void;
}

// Default settings
const defaultSettings: UserSettings = {
  userId: '',
  theme: 'system',
  accentColor: 'blue',
  emailNotifications: true,
  pushNotifications: false,
  language: 'tr',
  timezone: 'UTC+03:00 Istanbul',
  twoFactorAuth: false,
  sessionTimeout: '30',
  apiKey: '••••••••••••••••',
};

export const useSettingsStore = create<SettingsState>((set, get) => ({
  settings: {
    ...defaultSettings,
    userId: 'demo-user',
  },
  loading: false,
  error: null,
  isDemo: true,
  
  fetchSettings: async (userId: string) => {
    try {
      set({ loading: true, error: null });
      
      // Always use demo mode to avoid database column errors
      const isDemo = true;
      
      if (isDemo) {
        // Use mock data for demo mode
        const mockSettings: UserSettings = {
          ...defaultSettings,
          userId,
        };
        
        set({ 
          settings: mockSettings, 
          loading: false,
          isDemo: true
        });
        return;
      }
      
      // This code won't run in demo mode, but keeping it for reference
      const { data, error } = await supabase
        .from('user_settings')
        .select('*')
        .eq('user_id', userId)
        .single();
      
      if (error) {
        if (error.code === 'PGRST116') { // No rows returned
          // Create default settings for user
          const newSettings = {
            ...defaultSettings,
            userId,
          };
          
          const { data: createdData, error: createError } = await supabase
            .from('user_settings')
            .insert({
              user_id: userId,
              theme: newSettings.theme,
              accent_color: newSettings.accentColor,
              email_notifications: newSettings.emailNotifications,
              push_notifications: newSettings.pushNotifications,
              language: newSettings.language,
              timezone: newSettings.timezone,
              two_factor_auth: newSettings.twoFactorAuth,
              session_timeout: newSettings.sessionTimeout,
            })
            .select()
            .single();
          
          if (createError) {
            console.error('Error creating user settings:', createError);
            throw createError;
          }
          
          const settings: UserSettings = {
            id: createdData.id,
            userId: createdData.user_id,
            theme: createdData.theme,
            accentColor: createdData.accent_color,
            emailNotifications: createdData.email_notifications,
            pushNotifications: createdData.push_notifications,
            language: createdData.language,
            timezone: createdData.timezone,
            twoFactorAuth: createdData.two_factor_auth,
            sessionTimeout: createdData.session_timeout,
            apiKey: createdData.api_key || '••••••••••••••••',
            createdAt: createdData.created_at,
            updatedAt: createdData.updated_at,
          };
          
          set({ settings, loading: false, isDemo: false });
          return;
        }
        
        console.error('Error fetching user settings:', error);
        throw error;
      }
      
      const settings: UserSettings = {
        id: data.id,
        userId: data.user_id,
        theme: data.theme,
        accentColor: data.accent_color,
        emailNotifications: data.email_notifications,
        pushNotifications: data.push_notifications,
        language: data.language,
        timezone: data.timezone,
        twoFactorAuth: data.two_factor_auth,
        sessionTimeout: data.session_timeout,
        apiKey: data.api_key || '••••••••••••••••',
        createdAt: data.created_at,
        updatedAt: data.updated_at,
      };
      
      set({ settings, loading: false, isDemo: false });
    } catch (error) {
      console.error('Error in fetchSettings:', error);
      // Fallback to mock data
      const mockSettings: UserSettings = {
        ...defaultSettings,
        userId,
      };
      
      set({ 
        settings: mockSettings, 
        loading: false, 
        error: 'Failed to fetch user settings',
        isDemo: true
      });
    }
  },
  
  updateSettings: async (updatedSettings: Partial<UserSettings>) => {
    try {
      set({ loading: true, error: null });
      
      const currentSettings = get().settings;
      if (!currentSettings) {
        throw new Error('No settings to update');
      }
      
      // Check if in demo mode
      if (get().isDemo) {
        // Simulate settings update in demo mode
        const newSettings = {
          ...currentSettings,
          ...updatedSettings,
          updatedAt: new Date().toISOString(),
        };
        
        set({ 
          settings: newSettings,
          loading: false
        });
        return;
      }
      
      // Update real settings in Supabase
      const { error } = await supabase
        .from('user_settings')
        .update({
          theme: updatedSettings.theme || currentSettings.theme,
          accent_color: updatedSettings.accentColor || currentSettings.accentColor,
          email_notifications: updatedSettings.emailNotifications !== undefined ? updatedSettings.emailNotifications : currentSettings.emailNotifications,
          push_notifications: updatedSettings.pushNotifications !== undefined ? updatedSettings.pushNotifications : currentSettings.pushNotifications,
          language: updatedSettings.language || currentSettings.language,
          timezone: updatedSettings.timezone || currentSettings.timezone,
          two_factor_auth: updatedSettings.twoFactorAuth !== undefined ? updatedSettings.twoFactorAuth : currentSettings.twoFactorAuth,
          session_timeout: updatedSettings.sessionTimeout || currentSettings.sessionTimeout,
        })
        .eq('id', currentSettings.id);
      
      if (error) {
        console.error('Error updating user settings:', error);
        throw error;
      }
      
      // Update local state
      const newSettings = {
        ...currentSettings,
        ...updatedSettings,
        updatedAt: new Date().toISOString(),
      };
      
      set({ 
        settings: newSettings,
        loading: false
      });
    } catch (error) {
      console.error('Error in updateSettings:', error);
      set({ loading: false, error: 'Failed to update user settings' });
      throw error;
    }
  },
  
  regenerateApiKey: async (userId: string) => {
    try {
      set({ loading: true, error: null });
      
      // Check if in demo mode
      if (get().isDemo) {
        // Simulate API key regeneration in demo mode
        const newApiKey = generateRandomApiKey();
        
        set(state => ({
          settings: state.settings ? {
            ...state.settings,
            apiKey: newApiKey,
            updatedAt: new Date().toISOString(),
          } : null,
          loading: false
        }));
        
        return newApiKey;
      }
      
      // Generate new API key
      const newApiKey = generateRandomApiKey();
      
      // Update API key in Supabase
      const { error } = await supabase
        .from('user_settings')
        .update({
          api_key: newApiKey,
        })
        .eq('user_id', userId);
      
      if (error) {
        console.error('Error regenerating API key:', error);
        throw error;
      }
      
      // Update local state
      set(state => ({
        settings: state.settings ? {
          ...state.settings,
          apiKey: newApiKey,
          updatedAt: new Date().toISOString(),
        } : null,
        loading: false
      }));
      
      return newApiKey;
    } catch (error) {
      console.error('Error in regenerateApiKey:', error);
      set({ loading: false, error: 'Failed to regenerate API key' });
      throw error;
    }
  },
  
  toggleSetting: (setting: keyof UserSettings, value?: any) => {
    const currentSettings = get().settings;
    if (!currentSettings) return;
    
    const newValue = value !== undefined ? value : !currentSettings[setting];
    
    // Update local state immediately for responsive UI
    set(state => ({
      settings: state.settings ? {
        ...state.settings,
        [setting]: newValue,
      } : null,
    }));
    
    // Then update in database
    get().updateSettings({ [setting]: newValue });
  },
}));

// Helper function to generate random API key
function generateRandomApiKey(): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 32; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}