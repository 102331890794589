import React from 'react';
import { MessageSquare } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface FooterProps {
  language: 'en' | 'tr';
}

export function Footer({ language }: FooterProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSectionClick = (sectionId: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      const section = document.querySelector(`#${sectionId}`);
      section?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const translations = {
    en: {
      description: 'AI-powered customer service solution.',
      product: {
        title: 'Product',
        items: [
          { title: 'Features', url: '#features', onClick: handleSectionClick('features') },
          { title: 'BOB Academy', url: '/docs' }
        ],
      },
      company: {
        title: 'Company',
        items: [
          { title: 'About', url: '/about' },
          { title: 'Blog', url: '/blog' },
          { title: 'Careers', url: '/careers' }
        ],
      },
      legal: {
        title: 'Legal',
        items: [
          { title: 'Privacy', url: '/privacy' },
          { title: 'Terms', url: '/terms' },
          { title: 'Security', url: '/security' }
        ],
      },
      copyright: '© {year} BeOneBot. All rights reserved.',
    },
    tr: {
      description: 'Yapay zeka destekli müşteri hizmetleri çözümü.',
      product: {
        title: 'Ürün',
        items: [
          { title: 'Özellikler', url: '#features', onClick: handleSectionClick('features') },
          { title: 'BOB Akademi', url: '/docs' }
        ],
      },
      company: {
        title: 'Şirket',
        items: [
          { title: 'Hakkımızda', url: '/about' },
          { title: 'Blog', url: '/blog' },
          { title: 'Kariyer', url: '/careers' }
        ],
      },
      legal: {
        title: 'Yasal',
        items: [
          { title: 'Gizlilik', url: '/privacy' },
          { title: 'Kullanım Şartları', url: '/terms' },
          { title: 'Güvenlik', url: '/security' }
        ],
      },
      copyright: '© {year} BeOneBot. Tüm hakları saklıdır.',
    },
  };

  const currentLang = translations[language];

  // Check if we're in BOB Universe to apply special styling
  const isInBobUniverse = location.pathname.includes('/bob-universe');

  return (
    <footer className="bg-gray-50 border-t border-gray-200 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center space-x-2 mb-4">
              <MessageSquare className="h-8 w-8 text-blue-500" />
              <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-indigo-500">
                BeOneBot
              </span>
            </div>
            <p className="text-gray-600">{currentLang.description}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{currentLang.product.title}</h3>
            <ul className="space-y-2 text-gray-600">
              {currentLang.product.items.map((item) => (
                <li key={item.title}>
                  {item.onClick ? (
                    <a
                      href={item.url}
                      onClick={item.onClick}
                      className="hover:text-gray-900 transition-colors"
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link
                      to={item.url}
                      className="hover:text-gray-900 transition-colors"
                    >
                      {item.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{currentLang.company.title}</h3>
            <ul className="space-y-2 text-gray-600">
              {currentLang.company.items.map((item) => (
                <li key={item.title}>
                  <Link
                    to={item.url}
                    className="hover:text-gray-900 transition-colors"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{currentLang.legal.title}</h3>
            <ul className="space-y-2 text-gray-600">
              {currentLang.legal.items.map((item) => (
                <li key={item.title}>
                  <Link
                    to={item.url}
                    className="hover:text-gray-900 transition-colors"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200 text-center text-gray-600">
          <p>
            {currentLang.copyright.replace('{year}', new Date().getFullYear().toString())}
          </p>
        </div>
      </div>
    </footer>
  );
}