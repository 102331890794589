import React from 'react';
import { motion } from 'framer-motion';

interface BobAvatarProps {
  onClick: () => void;
  isOpen: boolean;
}

export function BobAvatar({ onClick, isOpen }: BobAvatarProps) {
  return (
    <motion.button
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 200, damping: 10 }}
      onClick={onClick}
      className="fixed bottom-6 right-6 z-50 w-16 h-16 rounded-full bg-gradient-to-r from-blue-500 to-indigo-600 shadow-lg flex items-center justify-center overflow-hidden hover:shadow-blue-500/25"
      style={{ zIndex: 50 }}
    >
      <svg
        viewBox="0 0 120 120"
        className="w-full h-full"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Robot Head */}
        <defs>
          <linearGradient id="bodyGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#ffffff" />
            <stop offset="100%" stopColor="#e0e7ff" />
          </linearGradient>
          <linearGradient id="glowGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#60a5fa" stopOpacity="0.5" />
            <stop offset="100%" stopColor="#3b82f6" stopOpacity="0.2" />
          </linearGradient>
        </defs>

        {/* Outer Glow */}
        <circle cx="60" cy="60" r="58" fill="url(#glowGradient)" />
        
        {/* Main Head */}
        <path
          d="M30 40 L30 80 Q30 90 40 90 L80 90 Q90 90 90 80 L90 40 Q90 30 80 30 L40 30 Q30 30 30 40"
          fill="url(#bodyGradient)"
          stroke="#3b82f6"
          strokeWidth="2"
        />

        {/* Eyes */}
        <g className="eyes">
          <rect x="45" y="50" width="12" height="6" rx="2" fill="#3b82f6" />
          <rect x="65" y="50" width="12" height="6" rx="2" fill="#3b82f6" />
        </g>

        {/* Antenna */}
        <path
          d="M55 30 L60 20 L65 30"
          fill="none"
          stroke="#3b82f6"
          strokeWidth="2"
          strokeLinecap="round"
        />

        {/* Mouth/Speaker */}
        <path
          d="M45 70 Q60 80 75 70"
          fill="none"
          stroke="#3b82f6"
          strokeWidth="2"
          strokeLinecap="round"
        />

        {/* Side Panels */}
        <path
          d="M30 50 L25 55 L25 65 L30 70"
          fill="none"
          stroke="#3b82f6"
          strokeWidth="2"
        />
        <path
          d="M90 50 L95 55 L95 65 L90 70"
          fill="none"
          stroke="#3b82f6"
          strokeWidth="2"
        />

        {/* Energy Indicators */}
        <circle cx="35" cy="45" r="2" fill="#3b82f6" />
        <circle cx="85" cy="45" r="2" fill="#3b82f6" />
      </svg>

      {!isOpen && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-6 h-6 flex items-center justify-center"
        >
          1
        </motion.div>
      )}
    </motion.button>
  );
}