import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Key, Server } from 'lucide-react';

interface SecurityPageProps {
  language: 'en' | 'tr';
}

export function SecurityPage({ language }: SecurityPageProps) {
  const translations = {
    en: {
      title: "Security",
      subtitle: "How We Protect Your Data and Ensure Platform Security",
      sections: [
        {
          icon: Shield,
          title: "Platform Security",
          content: "At BeOneBot, protecting your data and maintaining a secure platform is our top priority. Our infrastructure undergoes regular audits and updates, ensuring compliance with the industry's highest security standards.",
          features: [
            "Continuous 24/7 infrastructure monitoring",
            "Routine and comprehensive security audits",
            "Advanced DDoS protection mechanisms",
            "Proactive intrusion detection systems (IDS) "
          ]
        },
        {
          icon: Lock,
          title: "Data Protection",
          content: "All data transmitted and stored through our platform is secured with robust encryption technologies and layered protective measures, safeguarding your sensitive information effectively.",
          features: [
            "End-to-end data encryption",
            "Secure storage solutions",
            "Periodic data backups",
            "Strict data access management"
          ]
        },
        {
          icon: Key,
          title: "Access Control",
          content: "We implement stringent access controls and robust authentication processes to ensure sensitive data and systems can only be accessed by authorized personnel.",
          features: [
            "Multi-factor authentication (MFA) ",
            "Role-based access control (RBAC) ",
            "Secure session management",
            "Detailed activity and audit logging"
          ]
        },
        {
          icon: Server,
          title: "Infrastructure",
          content: "Our platform operates within secure, certified data centers featuring redundant systems and automated security updates, ensuring maximum uptime and resilience.",
          features: [
            "ISO 27001 certified data centers",
            "Fully redundant architecture",
            "Automated security patching and updates",
            "Comprehensive disaster recovery and continuity plans"
          ]
        }
      ],
      contact: {
        title: "Reporting Security Concerns",
        content: "If you identify a potential security vulnerability or have any concerns regarding security, please contact our dedicated security team immediately at:\nEmail: security@beonehorizon.com"
      }
    },
    tr: {
      title: "Güvenlik",
      subtitle: "Verilerinizi Nasıl Koruyor ve Platform Güvenliğini Nasıl Sağlıyoruz?",
      sections: [
        {
          icon: Shield,
          title: "Platform Güvenliği",
          content: "BeOneBot olarak, verilerinizi korumak ve platform güvenliğini sağlamak en öncelikli görevimizdir. Altyapımız, düzenli güvenlik denetimlerinden geçirilmekte ve sektörün en yüksek güvenlik standartlarına sürekli olarak uyumlu tutulmaktadır.",
          features: [
            "Kesintisiz 7/24 altyapı izleme ve kontrol",
            "Periyodik ve detaylı güvenlik denetimleri",
            "Gelişmiş DDoS koruma sistemleri",
            "Proaktif saldırı tespit sistemleri (IDS) "
          ]
        },
        {
          icon: Lock,
          title: "Veri Koruma",
          content: "Platformumuz üzerinden iletilen ve depolanan tüm veriler, güçlü şifreleme teknolojileri ve katmanlı güvenlik önlemleri ile korunarak hassas bilgilerinizin güvenliği sağlanmaktadır.",
          features: [
            "Uçtan uca veri şifrelemesi",
            "Güvenli veri depolama altyapısı",
            "Düzenli veri yedeklemeleri",
            "Katı veri erişim yönetimi politikalarıi"
          ]
        },
        {
          icon: Key,
          title: "Erişim Kontrolü",
          content: "Hassas verilere ve sistemlere yalnızca yetkili kullanıcıların erişimini sağlamak üzere, katı erişim kontrolleri ve güçlü kimlik doğrulama süreçleri uygulamaktayız.",
          features: [
            "Çok faktörlü kimlik doğrulama (MFA) ",
            "Rol bazlı erişim kontrolü (RBAC) ",
            "OGüvenli oturum yönetimi",
            "Ayrıntılı aktivite ve denetim günlükleri"
          ]
        },
        {
          icon: Server,
          title: "Altyapı",
          content: "Platformumuz, ISO 27001 sertifikasına sahip veri merkezlerinde, yedekli sistemler ve otomatik güvenlik güncellemeleriyle desteklenen güvenilir bir altyapı üzerinde faaliyet göstermektedir.",
          features: [
            "ISO 27001 sertifikalı veri merkezleri",
            "Tamamen yedekli mimari sistemler",
            "Otomatik güvenlik yamaları ve güncellemeler",
            "Kapsamlı felaket kurtarma ve iş sürekliliği planları"
          ]
        }
      ],
      contact: {
        title: "Güvenlik Bildirimi",
        content: "Olası bir güvenlik açığı tespit ettiyseniz veya güvenlik ile ilgili herhangi bir sorunuz ya da endişeniz varsa, lütfen derhal güvenlik ekibimiz ile iletişime geçiniz:\nEposta: security@beonehorizon.com"
      }
    }
  };

  const currentLang = translations[language];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            {currentLang.title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-600"
          >
            {currentLang.subtitle}
          </motion.p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {currentLang.sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 + 0.2 }}
              className="bg-white rounded-2xl p-6 shadow-lg"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center">
                  <section.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {section.title}
                </h2>
              </div>
              <p className="text-gray-600 mb-4">
                {section.content}
              </p>
              <ul className="space-y-2">
                {section.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-center text-gray-600">
                    <span className="w-2 h-2 bg-blue-500 rounded-full mr-2" />
                    {feature}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="mt-12 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-2xl p-8 text-white text-center"
        >
          <h2 className="text-2xl font-bold mb-4">
            {currentLang.contact.title}
          </h2>
          <p className="text-blue-100">
            {currentLang.contact.content}
          </p>
        </motion.div>
      </div>
    </div>
  );
}