import React from 'react';
import { motion } from 'framer-motion';
import { DivideIcon as LucideIcon } from 'lucide-react';
import { useDashboardStore } from '../../store/dashboardStore';

interface ChartCardProps {
  title: string;
  icon: LucideIcon;
  children: React.ReactNode;
  className?: string;
}

export function ChartCard({ title, icon: Icon, children, className = '' }: ChartCardProps) {
  const user = useDashboardStore(state => state.user);
  const language = user?.settings?.language || 'en';

  const translations = {
    en: {
      loading: 'Loading chart data...',
      noData: 'No data available',
    },
    tr: {
      loading: 'Grafik verileri yükleniyor...',
      noData: 'Veri bulunamadı',
    },
  };

  const t = translations[language];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-shadow ${className}`}
    >
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <Icon className="w-5 h-5 text-blue-500" />
          </div>
          <h3 className="font-medium text-gray-900">{title}</h3>
        </div>
      </div>
      {children || (
        <div className="flex items-center justify-center h-64 text-gray-500">
          {t.noData}
        </div>
      )}
    </motion.div>
  );
}