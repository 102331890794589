import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { 
  Calendar as CalendarIcon,
  Clock,
  User,
  Clipboard,
  MessageSquare,
  AlertCircle,
  Check,
  X,
  ChevronLeft,
  ChevronRight,
  Search,
  Filter,
  RefreshCw,
  Plus
} from 'lucide-react';
import { useCalendarStore } from '../../../store/calendarStore';
import { AppointmentForm } from '../../../components/calendar/AppointmentForm';
import { AppointmentDetails } from '../../../components/calendar/AppointmentDetails';
import { MiniCalendar } from '../../../components/calendar/MiniCalendar';
import { UpcomingAppointments } from '../../../components/calendar/UpcomingAppointments';

// Define more accessible status colors with better contrast
const statusColors = {
  confirmed: 'bg-green-600 text-white border border-green-700 font-medium',
  pending: 'bg-yellow-600 text-white border border-yellow-700 font-medium',
  cancelled: 'bg-red-600 text-white border border-red-700 font-medium',
};

// Dental procedure categories
const procedureCategories = [
  { id: 'all', label: 'Tüm İşlemler' },
  { id: 'restorative', label: 'Restoratif' },
  { id: 'periodontal', label: 'Periodontoloji' },
  { id: 'surgical', label: 'Cerrahi' },
  { id: 'orthodontic', label: 'Ortodonti' },
  { id: 'endodontic', label: 'Endodonti' },
  { id: 'prosthodontic', label: 'Protez' },
  { id: 'preventive', label: 'Koruyucu' },
  { id: 'pediatric', label: 'Pedodonti' },
  { id: 'cosmetic', label: 'Estetik' },
];

export default function Calendar() {
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<string>('all');
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [showCategoryFilter, setShowCategoryFilter] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<{start: Date, end: Date} | null>(null);

  // Get calendar state and actions from calendar store
  const { 
    events, 
    loading, 
    error, 
    selectedDate, 
    view, 
    fetchEvents, 
    setSelectedDate, 
    setView 
  } = useCalendarStore();

  // Fetch events when component mounts
  useEffect(() => {
    fetchEvents('demo-user');
  }, [fetchEvents]);

  const translations = {
    title: 'Takvim',
    views: {
      day: 'Gün',
      week: 'Hafta',
      month: 'Ay',
    },
    filters: {
      search: 'Randevularda ara...',
      status: 'Duruma göre filtrele',
      category: 'İşlem kategorisi',
    },
    upcoming: 'Yaklaşan Randevular',
    noAppointments: 'Randevu bulunamadı',
    status: {
      confirmed: 'Onaylandı',
      pending: 'Beklemede',
      cancelled: 'İptal Edildi',
    },
    buttons: {
      addAppointment: 'Randevu Ekle',
      refresh: 'Yenile',
    },
    loading: 'Takvim verileri yükleniyor...',
    error: 'Takvim verileri yüklenirken hata oluştu',
    retry: 'Tekrar Dene',
  };

  // Filter events based on search query, status filter and category filter
  const filteredEvents = events.filter(event => {
    const matchesSearch = searchQuery === '' || 
      event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      event.customerName.toLowerCase().includes(searchQuery.toLowerCase());
    
    const matchesStatus = statusFilter.length === 0 || 
      statusFilter.includes(event.status);
    
    // Assuming we add a category field to events
    const matchesCategory = categoryFilter === 'all' || 
      (event as any).category === categoryFilter;
    
    return matchesSearch && matchesStatus && matchesCategory;
  });

  const handleDateSelect = (selectInfo: any) => {
    const start = selectInfo.start;
    const end = selectInfo.end;
    setSelectedTimeSlot({ start, end });
    setShowAppointmentForm(true);
  };

  const handleEventClick = (clickInfo: any) => {
    const eventId = clickInfo.event.id;
    const selectedEvent = events.find(event => event.id === eventId);
    if (selectedEvent) {
      setSelectedAppointment(selectedEvent);
      setShowAppointmentDetails(true);
    }
  };

  const handleRefresh = () => {
    fetchEvents('demo-user');
  };

  const toggleStatusFilter = (status: string) => {
    if (statusFilter.includes(status)) {
      setStatusFilter(statusFilter.filter(s => s !== status));
    } else {
      setStatusFilter([...statusFilter, status]);
    }
  };

  // Custom event rendering function to improve readability
  const renderEventContent = (eventInfo: any) => {
    const status = eventInfo.event.extendedProps.status;
    const patientName = eventInfo.event.extendedProps.customerName;
    const title = eventInfo.event.title;
    
    return (
      <div className="p-1 overflow-hidden h-full">
        <div className="text-sm font-semibold whitespace-normal text-white">{title}</div>
        <div className="text-xs whitespace-normal text-white">{patientName}</div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-xl border border-gray-200 p-6 shadow-lg"
      >
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-blue-50 rounded-lg">
              <CalendarIcon className="w-5 h-5 text-blue-500" />
            </div>
            <h2 className="text-lg font-semibold text-gray-900">{translations.title}</h2>
          </div>
          <div className="flex items-center space-x-4">
            {/* View Toggle */}
            <div className="bg-gray-100 rounded-lg p-1">
              <button
                onClick={() => setView('timeGridDay')}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                  view === 'timeGridDay'
                    ? 'bg-white text-blue-600 shadow'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                {translations.views.day}
              </button>
              <button
                onClick={() => setView('timeGridWeek')}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                  view === 'timeGridWeek'
                    ? 'bg-white text-blue-600 shadow'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                {translations.views.week}
              </button>
              <button
                onClick={() => setView('dayGridMonth')}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                  view === 'dayGridMonth'
                    ? 'bg-white text-blue-600 shadow'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                {translations.views.month}
              </button>
            </div>

            {/* Refresh Button */}
            <button
              onClick={handleRefresh}
              className="p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100"
              disabled={loading}
            >
              <RefreshCw className={`w-5 h-5 ${loading ? 'animate-spin' : ''}`} />
            </button>

            {/* Add Appointment Button */}
            <button
              onClick={() => setShowAppointmentForm(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center space-x-2"
            >
              <Plus className="w-4 h-4" />
              <span>{translations.buttons.addAppointment}</span>
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="flex flex-wrap items-center space-x-4 mb-6">
          <div className="flex-1 min-w-[200px]">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={translations.filters.search}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              />
            </div>
          </div>
          
          {/* Status Filter */}
          <div className="relative">
            <button
              onClick={() => {
                setShowStatusFilter(!showStatusFilter);
                if (showCategoryFilter) setShowCategoryFilter(false);
              }}
              className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              <Filter className="w-4 h-4 text-gray-500" />
              <span className="text-gray-700">{translations.filters.status}</span>
            </button>

            {showStatusFilter && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10 border border-gray-200">
                <div className="px-3 py-2 space-y-2">
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="status-confirmed"
                      checked={statusFilter.includes('confirmed')}
                      onChange={() => toggleStatusFilter('confirmed')}
                      className="rounded text-blue-500 focus:ring-blue-500"
                    />
                    <label htmlFor="status-confirmed" className="text-sm text-gray-700">
                      {translations.status.confirmed}
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="status-pending"
                      checked={statusFilter.includes('pending')}
                      onChange={() => toggleStatusFilter('pending')}
                      className="rounded text-blue-500 focus:ring-blue-500"
                    />
                    <label htmlFor="status-pending" className="text-sm text-gray-700">
                      {translations.status.pending}
                    </label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      id="status-cancelled"
                      checked={statusFilter.includes('cancelled')}
                      onChange={() => toggleStatusFilter('cancelled')}
                      className="rounded text-blue-500 focus:ring-blue-500"
                    />
                    <label htmlFor="status-cancelled" className="text-sm text-gray-700">
                      {translations.status.cancelled}
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
          
          {/* Category Filter */}
          <div className="relative">
            <button
              onClick={() => {
                setShowCategoryFilter(!showCategoryFilter);
                if (showStatusFilter) setShowStatusFilter(false);
              }}
              className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              <Clipboard className="w-4 h-4 text-gray-500" />
              <span className="text-gray-700">{translations.filters.category}</span>
            </button>

            {showCategoryFilter && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10 border border-gray-200">
                <div className="px-3 py-2 space-y-2 max-h-60 overflow-y-auto">
                  {procedureCategories.map(category => (
                    <div key={category.id} className="flex items-center space-x-2">
                      <input
                        type="radio"
                        id={`category-${category.id}`}
                        checked={categoryFilter === category.id}
                        onChange={() => setCategoryFilter(category.id)}
                        className="rounded-full text-blue-500 focus:ring-blue-500"
                      />
                      <label htmlFor={`category-${category.id}`} className="text-sm text-gray-700">
                        {category.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Calendar Grid */}
        <div className="flex gap-4">
          <div className="w-64 hidden lg:block space-y-4">
            <MiniCalendar
              selectedDate={selectedDate}
              onDateChange={setSelectedDate}
              language="tr"
            />
            <UpcomingAppointments 
              language="tr" 
              events={filteredEvents}
            />
          </div>

          <div className="flex-1">
            {loading ? (
              <div className="flex items-center justify-center h-64">
                <div className="text-center">
                  <RefreshCw className="w-8 h-8 text-blue-500 animate-spin mx-auto mb-4" />
                  <p className="text-gray-600">{translations.loading}</p>
                </div>
              </div>
            ) : error ? (
              <div className="flex items-center justify-center h-64">
                <div className="text-center">
                  <AlertCircle className="w-8 h-8 text-red-500 mx-auto mb-4" />
                  <p className="text-red-600 mb-2">{translations.error}</p>
                  <button
                    onClick={handleRefresh}
                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                  >
                    {translations.retry}
                  </button>
                </div>
              </div>
            ) : (
              <div className="calendar-container">
                <style>
                  {`
                    /* Increase the height of time slots */
                    .fc-timegrid-slot {
                      height: 3em !important;
                    }
                    
                    /* Improve event appearance */
                    .fc-event {
                      border-radius: 6px !important;
                      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
                      margin: 1px 1px !important;
                    }
                    
                    /* Increase font size for better readability */
                    .fc-event-title, .fc-event-time {
                      font-size: 0.9rem !important;
                      color: white !important;
                    }
                    
                    /* Improve header styling */
                    .fc-col-header-cell {
                      background-color: #f9fafb !important;
                      font-weight: 600 !important;
                      padding: 8px 0 !important;
                    }
                    
                    /* Improve time label styling */
                    .fc-timegrid-axis-cushion {
                      font-weight: 500 !important;
                      color: #4b5563 !important;
                    }
                    
                    /* Add hover effect to events */
                    .fc-event:hover {
                      filter: brightness(0.95);
                    }
                    
                    /* Improve today highlight */
                    .fc-day-today {
                      background-color: rgba(59, 130, 246, 0.05) !important;
                    }
                    
                    /* Improve month view */
                    .fc-daygrid-day-number {
                      font-size: 0.95rem !important;
                      font-weight: 500 !important;
                      padding: 8px !important;
                    }
                    
                    /* Improve event content padding */
                    .fc-event-main-frame {
                      padding: 2px 4px !important;
                    }
                    
                    /* Ensure event content is visible */
                    .fc-event-title {
                      white-space: normal !important;
                      overflow: visible !important;
                      font-weight: 600 !important;
                      color: white !important;
                    }
                    
                    /* Ensure event content wraps properly */
                    .fc-event-main {
                      padding: 2px !important;
                    }
                    
                    /* Improve event text readability */
                    .fc-event-main-frame {
                      line-height: 1.2 !important;
                    }
                    
                    /* Make sure text doesn't overflow */
                    .fc-event-title-container {
                      word-break: break-word !important;
                    }
                  `}
                </style>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView={view}
                  headerToolbar={false}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={true}
                  firstDay={1} // Set Monday as the first day of the week
                  events={filteredEvents.map(event => ({
                    ...event,
                    title: `${event.title} - ${event.customerName}`,
                    // Add custom properties for dental procedures
                    extendedProps: {
                      ...event,
                      category: ['restorative', 'periodontal', 'surgical', 'orthodontic', 'endodontic', 'prosthodontic', 'preventive', 'pediatric', 'cosmetic'][Math.floor(Math.random() * 9)],
                      doctor: ['Dr. Ayşe Yılmaz', 'Dr. Mehmet Demir', 'Dr. Zeynep Kaya'][Math.floor(Math.random() * 3)],
                    }
                  }))}
                  select={handleDateSelect}
                  eventClick={handleEventClick}
                  locale="tr"
                  initialDate={selectedDate}
                  slotMinTime="08:00:00"
                  slotMaxTime="20:00:00"
                  allDaySlot={false}
                  slotDuration="00:15:00"
                  eventContent={renderEventContent}
                  eventClassNames={(arg) => [
                    statusColors[arg.event.extendedProps.status as keyof typeof statusColors],
                  ]}
                  businessHours={{
                    daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Saturday
                    startTime: '09:00',
                    endTime: '19:00',
                  }}
                  height="auto"
                  contentHeight={700}
                  slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </motion.div>

      {/* Appointment Form Modal */}
      {showAppointmentForm && (
        <AppointmentForm
          onClose={() => {
            setShowAppointmentForm(false);
            setSelectedTimeSlot(null);
          }}
          language="tr"
          selectedTimeSlot={selectedTimeSlot}
          userId="demo-user"
        />
      )}

      {/* Appointment Details Modal */}
      {showAppointmentDetails && selectedAppointment && (
        <AppointmentDetails
          appointment={selectedAppointment}
          onClose={() => setShowAppointmentDetails(false)}
          language="tr"
          userId="demo-user"
        />
      )}
    </div>
  );
}