import React, { Suspense, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import {
  Loader2,
  LayoutDashboard,
  Calendar,
  Bell,
  Plug,
  Cog,
  User,
  LogOut,
  HelpCircle,
  X,
  ChevronDown,
  Search,
  Menu,
} from 'lucide-react';
import { useDashboardStore } from '../../store/dashboardStore';
import { useSettingsStore } from '../../store/settingsStore';
import { DemoNotice } from '../../components/dashboard/DemoNotice';
import { NotificationPanel } from '../../components/dashboard/NotificationPanel';
import { TutorialOverlay } from '../../components/dashboard/TutorialOverlay';
import { supabase, clearAuthData } from '../../lib/supabase';

// Import tab components directly to avoid dynamic imports
import Dashboard from './tabs/Dashboard';
import CalendarTab from './tabs/Calendar';
import Integrations from './tabs/Integrations';
import SettingsTab from './tabs/Settings';
import NotificationsTab from './tabs/Notifications';

interface TabConfig {
  id: string;
  labelTr: string;
  icon: React.ElementType;
  component: React.ComponentType<any>;
  requiresPaid?: boolean;
}

const tabs: TabConfig[] = [
  {
    id: 'dashboard',
    labelTr: 'Kontrol Paneli',
    icon: LayoutDashboard,
    component: Dashboard,
  },
  {
    id: 'calendar',
    labelTr: 'Takvim',
    icon: Calendar,
    component: CalendarTab,
  },
  {
    id: 'notifications',
    labelTr: 'Bildirimler',
    icon: Bell,
    component: NotificationsTab,
  },
  {
    id: 'integrations',
    labelTr: 'Entegrasyonlar',
    icon: Plug,
    component: Integrations,
  },
  {
    id: 'settings',
    labelTr: 'Ayarlar',
    icon: Cog,
    component: SettingsTab,
  },
];

const translations = {
  tr: {
    search: 'Ara...',
    errors: {
      title: 'Bir hata oluştu:',
      tryAgain: 'Tekrar dene',
    },
    notifications: {
      title: 'Bildirimler',
      noNotifications: 'Yeni bildirim yok',
      markAllRead: 'Tümünü okundu olarak işaretle',
    },
    userMenu: {
      profile: 'Profil',
      settings: 'Ayarlar',
      signOut: 'Çıkış Yap',
    },
    tutorial: {
      title: "BOB Universe'e Hoş Geldiniz",
      skip: 'Eğitimi Geç',
      next: 'İleri',
      finish: 'Bitir',
    },
    mobileMenu: {
      open: 'Menüyü aç',
      close: 'Menüyü kapat',
    },
  },
};

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  const t = translations.tr;

  return (
    <div className="text-center p-8">
      <h2 className="text-xl font-semibold text-red-600 mb-4">
        {t.errors.title}
      </h2>
      <pre className="text-sm text-red-500 mb-4 overflow-auto max-w-full">
        {error.message}
      </pre>
      <button
        onClick={resetErrorBoundary}
        className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
      >
        {t.errors.tryAgain}
      </button>
    </div>
  );
}

function LoadingFallback() {
  return (
    <div className="flex items-center justify-center p-8">
      <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
    </div>
  );
}

export default function BOBUniverse() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('dashboard');
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Initialize dashboard store with mock data for demo
  const user = {
    id: 'demo-user',
    email: 'demo@beonehorizon.com',
    name: 'Diş Kliniği',
    settings: {
      language: 'tr' as 'tr',
      timezone: 'Europe/Istanbul',
    },
  };

  const isDemo = true;
  const { settings } = useSettingsStore();

  const t = translations.tr;

  useEffect(() => {
    const tutorialShown = localStorage.getItem('bob_tutorial_shown');
    if (!tutorialShown) {
      setShowTutorial(true);
      localStorage.setItem('bob_tutorial_shown', 'true');
    }
  }, []);

  const handleSignOut = async () => {
    try {
      console.log('Signing out...');
      await supabase.auth.signOut();
      console.log('Sign out successful, redirecting to homepage');
      // Çıkış yaptıktan sonra anasayfaya yönlendir
      navigate('/');
    } catch (error) {
      console.error('Sign out error:', error);
      // Hata olsa bile anasayfaya yönlendir
      clearAuthData();
      navigate('/');
    }
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Searching for:', searchQuery);
    // Implement search functionality
  };

  // Get the active component based on the selected tab
  const ActiveComponent =
    tabs.find((tab) => tab.id === activeTab)?.component || Dashboard;

  // Close menus when clicking outside
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const notificationPanel = document.querySelector('#notification-panel');
      const userMenuPanel = document.querySelector('#user-menu-panel');

      if (
        notificationPanel && !notificationPanel.contains(e.target as Node) &&
        userMenuPanel && !userMenuPanel.contains(e.target as Node)
      ) {
        if (showNotifications) setShowNotifications(false);
        if (showUserMenu) setShowUserMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showNotifications, showUserMenu]);

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900 transition-colors duration-200">
      {/* Tab Navigation - Fixed - Desktop Only */}
      <div className="hidden md:block fixed top-0 left-0 right-0 border-b z-40 bg-white border-gray-200 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-8">
              <div className="flex items-center space-x-2">
                <div className="text-xl font-bold text-blue-600 dark:text-blue-400">
                  BOB Universe
                </div>
              </div>
            </div>
            
            <div className="flex items-center space-x-4">
              {/* Search */}
              <div className="relative max-w-md hidden md:block">
                <form onSubmit={handleSearch}>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Search className="w-4 h-4 text-gray-500" />
                    </div>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="block w-full pl-10 pr-3 py-2 rounded-lg text-sm bg-gray-100 border-gray-200 placeholder-gray-500 text-gray-900 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
                      placeholder={t.search}
                    />
                  </div>
                </form>
              </div>
              
              {/* Notifications */}
              <div className="relative" id="notification-panel">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowNotifications(!showNotifications);
                    if (showUserMenu) setShowUserMenu(false);
                  }}
                  className="p-2 rounded-lg text-gray-500 hover:text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                >
                  <Bell className="w-5 h-5" />
                  <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
                </button>
                <AnimatePresence>
                  {showNotifications && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                      className="absolute right-0 mt-2 w-80 rounded-lg shadow-lg py-1 z-50 bg-white border border-gray-200 transition-colors duration-200"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <NotificationPanel />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              {/* Help Button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowTutorial(true);
                }}
                className="p-2 rounded-lg text-gray-500 hover:text-gray-700 hover:bg-gray-100 transition-colors duration-200"
              >
                <HelpCircle className="w-5 h-5" />
              </button>

              {/* User Menu */}
              <div className="relative" id="user-menu-panel">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowUserMenu(!showUserMenu);
                    if (showNotifications) setShowNotifications(false);
                  }}
                  className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                >
                  <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center">
                    <User className="w-5 h-5 text-white" />
                  </div>
                  <span className="text-sm font-medium hidden sm:block text-gray-700">
                    {user?.name}
                  </span>
                  <ChevronDown className="w-4 h-4 hidden sm:block text-gray-500" />
                </button>

                <AnimatePresence>
                  {showUserMenu && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                      className="absolute right-0 mt-2 w-48 rounded-lg shadow-lg py-1 z-50 bg-white border border-gray-200 transition-colors duration-200"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        onClick={() => {
                          setActiveTab('settings');
                          setShowUserMenu(false);
                        }}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                      >
                        <Cog className="w-4 h-4 mr-2" />
                        {t.userMenu.settings}
                      </button>
                      <button
                        onClick={handleSignOut}
                        className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                      >
                        <LogOut className="w-4 h-4 mr-2" />
                        {t.userMenu.signOut}
                      </button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
          
          <nav className="flex p-2">
            {tabs.map((tab) => {
              const isDisabled = tab.requiresPaid && isDemo;
              return (
                <button
                  key={tab.id}
                  onClick={() => !isDisabled && setActiveTab(tab.id)}
                  disabled={isDisabled}
                  className={`flex items-center space-x-2 px-4 py-2 rounded-lg text-sm font-medium transition-colors relative ${
                    activeTab === tab.id
                      ? 'bg-blue-500 text-white'
                      : isDisabled
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <tab.icon className="w-4 h-4" />
                  <span>{tab.labelTr}</span>
                  {isDisabled && (
                    <span className="absolute -top-1 -right-1 w-2 h-2 bg-yellow-400 rounded-full" />
                  )}
                </button>
              );
            })}
          </nav>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {showMobileMenu && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2 }}
            className="md:hidden fixed top-16 left-0 right-0 z-40 border-b bg-white border-gray-200 transition-colors duration-200"
          >
            {/* Mobile Search */}
            <div className="p-4">
              <form onSubmit={handleSearch}>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Search className="w-4 h-4 text-gray-500" />
                  </div>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="block w-full pl-10 pr-3 py-2 rounded-lg text-sm bg-gray-100 border-gray-200 placeholder-gray-500 text-gray-900 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
                    placeholder={t.search}
                  />
                </div>
              </form>
            </div>

            {/* Mobile Navigation */}
            <nav className="px-4 pb-4 space-y-1">
              {tabs.map((tab) => {
                const isDisabled = tab.requiresPaid && isDemo;
                return (
                  <button
                    key={tab.id}
                    onClick={() => {
                      if (!isDisabled) {
                        setActiveTab(tab.id);
                        setShowMobileMenu(false);
                      }
                    }}
                    disabled={isDisabled}
                    className={`flex items-center w-full space-x-2 px-4 py-2 rounded-lg text-sm font-medium transition-colors relative ${
                      activeTab === tab.id
                        ? 'bg-blue-500 text-white'
                        : isDisabled
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-gray-600 hover:bg-gray-100'
                    }`}
                  >
                    <tab.icon className="w-4 h-4" />
                    <span>{tab.labelTr}</span>
                    {isDisabled && (
                      <span className="absolute -top-1 -right-1 w-2 h-2 bg-yellow-400 rounded-full" />
                    )}
                  </button>
                );
              })}
            </nav>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Main Content - Adjusted for fixed header and tab navigation */}
      <main className="pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Content Area */}
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => setActiveTab('dashboard')}
          >
            <Suspense fallback={<LoadingFallback />}>
              <ActiveComponent />
            </Suspense>
          </ErrorBoundary>
        </div>
      </main>

      {/* Demo Notice - Moved to bottom of page */}
      {isDemo && <DemoNotice language="tr" />}

      {/* Tutorial Overlay */}
      <AnimatePresence>
        {showTutorial && (
          <TutorialOverlay
            onClose={() => setShowTutorial(false)}
            language="tr"
          />
        )}
      </AnimatePresence>
    </div>
  );
}