import React, { useEffect } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase, clearAuthData } from '../lib/supabase';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function AuthModal({ isOpen, onClose }: AuthModalProps) {
  const navigate = useNavigate();

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_IN') {
        setTimeout(() => {
          onClose();
          navigate('/bob-universe');
        }, 1000);
      } else if (event === 'SIGNED_OUT') {
        clearAuthData();
      }
    });

    return () => subscription.unsubscribe();
  }, [onClose, navigate]);

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-[100]">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="absolute inset-0 bg-black/50 backdrop-blur-sm"
            onClick={onClose}
          />
          
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ type: "spring", damping: 20, stiffness: 300 }}
            className="relative w-[90%] max-w-md z-[101]"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
              <div className="relative flex items-center justify-between p-6 bg-gradient-to-r from-blue-500 to-indigo-500">
                <h2 className="text-xl font-semibold text-white">BeOneBot'a Hoş Geldiniz</h2>
                <button
                  onClick={onClose}
                  className="absolute right-4 top-1/2 -translate-y-1/2 text-white/80 hover:text-white transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="p-6">
                <Auth
                  supabaseClient={supabase}
                  appearance={{
                    theme: ThemeSupa,
                    variables: {
                      default: {
                        colors: {
                          brand: '#3B82F6',
                          brandAccent: '#2563EB',
                          inputBackground: 'white',
                          inputBorder: '#E5E7EB',
                          inputBorderHover: '#3B82F6',
                          inputBorderFocus: '#2563EB',
                        },
                        space: {
                          inputPadding: '0.75rem',
                          buttonPadding: '0.75rem',
                        },
                        borderWidths: {
                          buttonBorderWidth: '1px',
                          inputBorderWidth: '1px',
                        },
                        radii: {
                          borderRadiusButton: '0.5rem',
                          buttonBorderRadius: '0.5rem',
                          inputBorderRadius: '0.5rem',
                        },
                      },
                    },
                    className: {
                      container: 'w-full',
                      button: 'w-full px-4 py-3 rounded-lg font-medium bg-blue-500 hover:bg-blue-600 text-white transition-colors',
                      input: 'w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500 transition-all',
                      label: 'text-sm font-medium text-gray-700 mb-1',
                      anchor: 'text-blue-500 hover:text-blue-600 font-medium',
                    },
                  }}
                  providers={[]}
                  localization={{
                    variables: {
                      sign_in: {
                        email_label: 'E-posta',
                        password_label: 'Şifre',
                        button_label: 'Giriş Yap',
                        loading_button_label: 'Giriş yapılıyor...',
                        social_provider_text: 'ile giriş yap',
                        link_text: 'Zaten hesabınız var mı? Giriş yapın',
                      },
                      sign_up: {
                        email_label: 'E-posta',
                        password_label: 'Şifre',
                        button_label: 'Kayıt Ol',
                        loading_button_label: 'Kayıt olunuyor...',
                        social_provider_text: 'ile kayıt ol',
                        link_text: 'Hesabınız yok mu? Kayıt olun',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}