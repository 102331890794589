import React from 'react';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { X, Loader2 } from 'lucide-react';
import { useCalendarStore, CalendarEvent } from '../../store/calendarStore';
import { format } from 'date-fns';

interface AppointmentFormProps {
  onClose: () => void;
  language: 'en' | 'tr';
  selectedTimeSlot: { start: Date; end: Date } | null;
  userId: string;
}

const appointmentSchema = z.object({
  title: z.string().min(1, 'Başlık gereklidir'),
  date: z.string().min(1, 'Tarih gereklidir'),
  startTime: z.string().min(1, 'Başlangıç saati gereklidir'),
  endTime: z.string().min(1, 'Bitiş saati gereklidir'),
  customerName: z.string().min(1, 'Hasta adı gereklidir'),
  customerEmail: z.string().email('Geçersiz e-posta adresi'),
  companyName: z.string().optional(),
  notes: z.string().optional(),
  status: z.enum(['confirmed', 'pending', 'cancelled']),
  category: z.string().min(1, 'İşlem kategorisi gereklidir'),
  doctor: z.string().min(1, 'Doktor seçimi gereklidir'),
  phone: z.string().min(1, 'Telefon numarası gereklidir'),
});

type AppointmentFormData = z.infer<typeof appointmentSchema>;

// Dental procedure categories
const procedureCategories = [
  { id: 'restorative', label: 'Restoratif' },
  { id: 'periodontal', label: 'Periodontoloji' },
  { id: 'surgical', label: 'Cerrahi' },
  { id: 'orthodontic', label: 'Ortodonti' },
  { id: 'endodontic', label: 'Endodonti' },
  { id: 'prosthodontic', label: 'Protez' },
  { id: 'preventive', label: 'Koruyucu' },
  { id: 'pediatric', label: 'Pedodonti' },
  { id: 'cosmetic', label: 'Estetik' },
];

// Doctors list
const doctors = [
  { id: 'dr1', name: 'Dr. Ayşe Yılmaz' },
  { id: 'dr2', name: 'Dr. Mehmet Demir' },
  { id: 'dr3', name: 'Dr. Zeynep Kaya' },
];

export function AppointmentForm({ onClose, language, selectedTimeSlot, userId }: AppointmentFormProps) {
  const { createEvent, loading } = useCalendarStore();

  const defaultValues = selectedTimeSlot
    ? {
        date: format(selectedTimeSlot.start, 'yyyy-MM-dd'),
        startTime: format(selectedTimeSlot.start, 'HH:mm'),
        endTime: format(selectedTimeSlot.end, 'HH:mm'),
        status: 'pending' as const,
        category: 'restorative',
        doctor: 'dr1',
      }
    : {
        date: format(new Date(), 'yyyy-MM-dd'),
        startTime: format(new Date(), 'HH:mm'),
        endTime: format(new Date(new Date().getTime() + 30 * 60 * 1000), 'HH:mm'),
        status: 'pending' as const,
        category: 'restorative',
        doctor: 'dr1',
      };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AppointmentFormData>({
    resolver: zodResolver(appointmentSchema),
    defaultValues,
  });

  const translations = {
    title: 'Yeni Randevu',
    fields: {
      title: 'İşlem Adı',
      date: 'Tarih',
      startTime: 'Başlangıç Saati',
      endTime: 'Bitiş Saati',
      customerName: 'Hasta Adı',
      customerEmail: 'E-posta',
      phone: 'Telefon',
      companyName: 'Sigorta/Kurum (Opsiyonel)',
      notes: 'Notlar (Opsiyonel)',
      status: 'Durum',
      category: 'İşlem Kategorisi',
      doctor: 'Doktor',
    },
    status: {
      confirmed: 'Onaylandı',
      pending: 'Beklemede',
      cancelled: 'İptal Edildi',
    },
    buttons: {
      cancel: 'İptal',
      create: 'Randevu Oluştur',
      creating: 'Oluşturuluyor...',
    },
    success: 'Randevu başarıyla oluşturuldu',
    error: 'Randevu oluşturulurken hata oluştu',
  };

  const onSubmit = async (data: AppointmentFormData) => {
    try {
      // Convert form data to CalendarEvent format
      const startDate = new Date(`${data.date}T${data.startTime}`);
      const endDate = new Date(`${data.date}T${data.endTime}`);
      
      const newEvent: Omit<CalendarEvent, 'id'> = {
        title: data.title,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        status: data.status,
        customerName: data.customerName,
        customerEmail: data.customerEmail,
        companyName: data.companyName,
        notes: data.notes,
      };
      
      // Add custom properties for dental procedures
      (newEvent as any).category = data.category;
      (newEvent as any).doctor = data.doctor;
      (newEvent as any).phone = data.phone;
      
      // Create event using calendar store
      await createEvent(userId, newEvent);
      
      // Close the form
      onClose();
    } catch (error) {
      console.error('Error creating appointment:', error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-lg shadow-xl max-w-lg w-full mx-4"
      >
        <div className="flex items-center justify-between p-6 border-b">
          <h2 className="text-xl font-semibold text-gray-900">{translations.title}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
            disabled={isSubmitting || loading}
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
          <div className="space-y-4">
            {/* Title */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {translations.fields.title}
              </label>
              <input
                type="text"
                {...register('title')}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              />
              {errors.title && (
                <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
              )}
            </div>

            {/* Category and Doctor */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.category}
                </label>
                <select
                  {...register('category')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                >
                  {procedureCategories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.label}
                    </option>
                  ))}
                </select>
                {errors.category && (
                  <p className="mt-1 text-sm text-red-600">{errors.category.message}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.doctor}
                </label>
                <select
                  {...register('doctor')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                >
                  {doctors.map(doctor => (
                    <option key={doctor.id} value={doctor.id}>
                      {doctor.name}
                    </option>
                  ))}
                </select>
                {errors.doctor && (
                  <p className="mt-1 text-sm text-red-600">{errors.doctor.message}</p>
                )}
              </div>
            </div>

            {/* Date and Time */}
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.date}
                </label>
                <input
                  type="date"
                  {...register('date')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                />
                {errors.date && (
                  <p className="mt-1 text-sm text-red-600">{errors.date.message}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.startTime}
                </label>
                <input
                  type="time"
                  {...register('startTime')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                />
                {errors.startTime && (
                  <p className="mt-1 text-sm text-red-600">{errors.startTime.message}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.endTime}
                </label>
                <input
                  type="time"
                  {...register('endTime')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                />
                {errors.endTime && (
                  <p className="mt-1 text-sm text-red-600">{errors.endTime.message}</p>
                )}
              </div>
            </div>

            {/* Status */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {translations.fields.status}
              </label>
              <select
                {...register('status')}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              >
                <option value="pending">{translations.status.pending}</option>
                <option value="confirmed">{translations.status.confirmed}</option>
                <option value="cancelled">{translations.status.cancelled}</option>
              </select>
              {errors.status && (
                <p className="mt-1 text-sm text-red-600">{errors.status.message}</p>
              )}
            </div>

            {/* Patient Details */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.customerName}
                </label>
                <input
                  type="text"
                  {...register('customerName')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                />
                {errors.customerName && (
                  <p className="mt-1 text-sm text-red-600">{errors.customerName.message}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.customerEmail}
                </label>
                <input
                  type="email"
                  {...register('customerEmail')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                />
                {errors.customerEmail && (
                  <p className="mt-1 text-sm text-red-600">{errors.customerEmail.message}</p>
                )}
              </div>
            </div>

            {/* Phone and Insurance */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.phone}
                </label>
                <input
                  type="tel"
                  {...register('phone')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-600">{errors.phone.message}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {translations.fields.companyName}
                </label>
                <input
                  type="text"
                  {...register('companyName')}
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                />
              </div>
            </div>

            {/* Notes */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {translations.fields.notes}
              </label>
              <textarea
                {...register('notes')}
                rows={3}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
              disabled={isSubmitting || loading}
            >
              {translations.buttons.cancel}
            </button>
            <button
              type="submit"
              disabled={isSubmitting || loading}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center space-x-2"
            >
              {(isSubmitting || loading) && <Loader2 className="w-4 h-4 animate-spin" />}
              <span>{(isSubmitting || loading) ? translations.buttons.creating : translations.buttons.create}</span>
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
}