import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import { format, parseISO } from 'date-fns';

export interface CalendarEvent {
  id: string;
  title: string;
  start: string;
  end: string;
  status: 'confirmed' | 'pending' | 'cancelled';
  customerName: string;
  customerEmail: string;
  companyName?: string;
  notes?: string;
}

interface CalendarState {
  events: CalendarEvent[];
  loading: boolean;
  error: string | null;
  selectedDate: Date;
  view: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth';
  isDemo: boolean;
  
  // Actions
  fetchEvents: (userId: string) => Promise<void>;
  createEvent: (userId: string, event: Omit<CalendarEvent, 'id'>) => Promise<string>;
  updateEvent: (userId: string, event: CalendarEvent) => Promise<void>;
  deleteEvent: (userId: string, eventId: string) => Promise<void>;
  setSelectedDate: (date: Date) => void;
  setView: (view: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth') => void;
}

// Generate more realistic dental appointment mock data
const generateMockDentalAppointments = () => {
  const procedures = [
    'Diş Dolgusu', 'Diş Çekimi', 'Kanal Tedavisi', 'Diş Taşı Temizliği',
    'Diş Eti Tedavisi', 'Protez Uygulaması', 'İmplant', 'Ortodonti Kontrolü',
    'Diş Beyazlatma', 'Çene Ağrısı Kontrolü', 'Gülüş Tasarımı', 'Zirkonyum Kaplama'
  ];
  
  const patientNames = [
    'Ahmet Yılmaz', 'Ayşe Demir', 'Mehmet Kaya', 'Zeynep Şahin',
    'Ali Öztürk', 'Fatma Çelik', 'Mustafa Aydın', 'Elif Yıldız',
    'Hasan Koç', 'Selin Arslan', 'Emre Güneş', 'Deniz Yılmaz'
  ];
  
  const today = new Date();
  const events: CalendarEvent[] = [];
  
  // Generate appointments for the next 14 days
  for (let i = 0; i < 30; i++) {
    const appointmentDate = new Date();
    appointmentDate.setDate(today.getDate() - 7 + i); // Start from a week ago
    
    // Only create appointments for weekdays between 9 AM and 6 PM
    if (appointmentDate.getDay() !== 0) { // Skip Sundays
      // Generate 2-5 appointments per day
      const appointmentsCount = Math.floor(Math.random() * 4) + 2;
      
      for (let j = 0; j < appointmentsCount; j++) {
        const hour = 9 + Math.floor(Math.random() * 9); // 9 AM to 5 PM
        const minute = [0, 15, 30, 45][Math.floor(Math.random() * 4)]; // 15-minute intervals
        
        const startTime = new Date(appointmentDate);
        startTime.setHours(hour, minute, 0, 0);
        
        const endTime = new Date(startTime);
        // Procedures typically take 30-60 minutes
        endTime.setMinutes(endTime.getMinutes() + (Math.floor(Math.random() * 2) + 1) * 30);
        
        const patientIndex = Math.floor(Math.random() * patientNames.length);
        const procedureIndex = Math.floor(Math.random() * procedures.length);
        
        const status: ('confirmed' | 'pending' | 'cancelled') = 
          Math.random() > 0.8 ? 'cancelled' : 
          Math.random() > 0.3 ? 'confirmed' : 'pending';
        
        events.push({
          id: `appointment-${i}-${j}`,
          title: procedures[procedureIndex],
          start: startTime.toISOString(),
          end: endTime.toISOString(),
          status,
          customerName: patientNames[patientIndex],
          customerEmail: patientNames[patientIndex].toLowerCase().replace(' ', '.') + '@example.com',
          notes: Math.random() > 0.7 ? 'Hasta önceki tedaviden memnun kalmış. Kontrol randevusu.' : undefined,
          companyName: Math.random() > 0.6 ? 'SGK' : undefined,
        });
      }
    }
  }
  
  return events;
};

const mockEvents = generateMockDentalAppointments();

export const useCalendarStore = create<CalendarState>((set, get) => ({
  events: [],
  loading: false,
  error: null,
  selectedDate: new Date(),
  view: 'timeGridWeek',
  isDemo: true,
  
  fetchEvents: async (userId: string) => {
    try {
      set({ loading: true, error: null });
      
      // Use mock data for demo mode
      set({ 
        events: mockEvents, 
        loading: false,
        isDemo: true
      });
      
    } catch (error) {
      console.error('Error in fetchEvents:', error);
      // Fallback to mock data
      set({ 
        events: mockEvents, 
        loading: false, 
        error: 'Failed to fetch calendar events',
        isDemo: true
      });
    }
  },
  
  createEvent: async (userId: string, event: Omit<CalendarEvent, 'id'>) => {
    try {
      set({ loading: true, error: null });
      
      // Simulate event creation in demo mode
      const newEvent: CalendarEvent = {
        ...event,
        id: `event-${Date.now()}`,
      };
      
      set(state => ({ 
        events: [...state.events, newEvent],
        loading: false
      }));
      
      return newEvent.id;
      
    } catch (error) {
      console.error('Error in createEvent:', error);
      set({ loading: false, error: 'Failed to create calendar event' });
      throw error;
    }
  },
  
  updateEvent: async (userId: string, event: CalendarEvent) => {
    try {
      set({ loading: true, error: null });
      
      // Simulate event update in demo mode
      set(state => ({
        events: state.events.map(e => e.id === event.id ? event : e),
        loading: false
      }));
      
    } catch (error) {
      console.error('Error in updateEvent:', error);
      set({ loading: false, error: 'Failed to update calendar event' });
      throw error;
    }
  },
  
  deleteEvent: async (userId: string, eventId: string) => {
    try {
      set({ loading: true, error: null });
      
      // Simulate event deletion in demo mode
      set(state => ({
        events: state.events.filter(e => e.id !== eventId),
        loading: false
      }));
      
    } catch (error) {
      console.error('Error in deleteEvent:', error);
      set({ loading: false, error: 'Failed to delete calendar event' });
      throw error;
    }
  },
  
  setSelectedDate: (date: Date) => {
    set({ selectedDate: date });
  },
  
  setView: (view: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth') => {
    set({ view });
  },
}));