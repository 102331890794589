import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, Mail, Phone, Globe, Calendar, Database, Loader2, AlertCircle, RefreshCw, Check } from 'lucide-react';
import { useDashboardStore } from '../../../store/dashboardStore';
import { useIntegrationsStore, Integration } from '../../../store/integrationsStore';

export default function Integrations() {
  const user = useDashboardStore(state => state.user);
  const language = user?.settings?.language || 'tr';
  const [connectingId, setConnectingId] = useState<string | null>(null);
  const [disconnectingId, setDisconnectingId] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Get integrations state and actions from integrations store
  const { 
    integrations, 
    loading, 
    error, 
    fetchIntegrations, 
    connectIntegration, 
    disconnectIntegration 
  } = useIntegrationsStore();

  // Fetch integrations when component mounts
  useEffect(() => {
    if (user) {
      fetchIntegrations(user.id);
    }
  }, [user, fetchIntegrations]);

  // Clear success message after 3 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const translations = {
    title: 'Entegrasyonlar',
    integrations: {
      whatsapp: {
        name: 'WhatsApp',
        description: 'WhatsApp Business API ile müşterilerinizle bağlantı kurun',
      },
      email: {
        name: 'E-posta Entegrasyonu',
        description: 'Bilet yönetimi için e-posta sisteminizle entegre edin',
      },
      voice: {
        name: 'Ses Entegrasyonu',
        description: 'BOB sisteminize sesli görüşme özellikleri ekleyin',
      },
      website: {
        name: 'Website Aracı',
        description: 'BOB\'u doğrudan websitenize yerleştirin',
      },
      calendar: {
        name: 'Takvim Entegrasyonu',
        description: 'Google Takvim veya Outlook ile senkronize edin',
      },
      crm: {
        name: 'CRM Entegrasyonu',
        description: 'CRM sisteminizle bağlantı kurun',
      },
    },
    status: {
      connected: 'Bağlı',
      available: 'Kullanılabilir',
      pending: 'Beklemede',
    },
    actions: {
      connect: 'Bağlan',
      disconnect: 'Bağlantıyı Kes',
      connecting: 'Bağlanıyor...',
      disconnecting: 'Bağlantı Kesiliyor...',
    },
    confirmation: {
      title: 'Bağlantı Kesmeyi Onayla',
      message: 'Bu entegrasyonun bağlantısını kesmek istediğinizden emin misiniz? Bu, bot işlevselliğinizi etkileyebilir.',
      confirm: 'Evet, Bağlantıyı Kes',
      cancel: 'İptal',
    },
    success: {
      connected: 'Entegrasyon başarıyla bağlandı',
      disconnected: 'Entegrasyon bağlantısı başarıyla kesildi',
    },
    error: {
      general: 'Bir hata oluştu',
      retry: 'Tekrar Dene',
    },
    loading: 'Entegrasyonlar yükleniyor...',
    refresh: 'Yenile',
  };

  // Define integration icons
  const integrationIcons = {
    whatsapp: MessageSquare,
    email: Mail,
    voice: Phone,
    website: Globe,
    calendar: Calendar,
    crm: Database,
  };

  const handleConnect = async (integrationType: Integration['type']) => {
    if (!user) return;
    
    try {
      setConnectingId(integrationType);
      await connectIntegration(user.id, integrationType);
      setSuccessMessage(translations.success.connected);
    } catch (error) {
      console.error('Error connecting integration:', error);
    } finally {
      setConnectingId(null);
    }
  };

  const handleDisconnect = async (integrationId: string) => {
    try {
      setDisconnectingId(integrationId);
      await disconnectIntegration(integrationId);
      setSuccessMessage(translations.success.disconnected);
      setShowConfirmation(null);
    } catch (error) {
      console.error('Error disconnecting integration:', error);
    } finally {
      setDisconnectingId(null);
    }
  };

  const handleRefresh = () => {
    if (user) {
      fetchIntegrations(user.id);
    }
  };

  // Prepare integrations data
  const integrationTypes: Integration['type'][] = ['whatsapp', 'email', 'voice', 'website', 'calendar', 'crm'];
  
  const integrationsData = integrationTypes.map(type => {
    const existingIntegration = integrations.find(i => i.type === type);
    
    return {
      id: existingIntegration?.id || `${type}-default`,
      type,
      name: translations.integrations[type]?.name || existingIntegration?.name || 'Bilinmeyen Entegrasyon',
      description: translations.integrations[type]?.description || '',
      status: existingIntegration?.status || 'available',
      icon: integrationIcons[type] || Globe,
    };
  });

  return (
    <div className="space-y-4">
      {/* Header with title and refresh button */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">{translations.title}</h2>
        <button
          onClick={handleRefresh}
          className="p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100"
          disabled={loading}
        >
          <RefreshCw className={`w-5 h-5 ${loading ? 'animate-spin' : ''}`} />
        </button>
      </div>

      {/* Success message */}
      {successMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg flex items-center mb-4">
          <Check className="w-5 h-5 mr-2" />
          {successMessage}
        </div>
      )}

      {/* Loading state */}
      {loading && integrations.length === 0 && (
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <Loader2 className="w-8 h-8 text-blue-500 animate-spin mx-auto mb-4" />
            <p className="text-gray-600">{translations.loading}</p>
          </div>
        </div>
      )}

      {/* Error state */}
      {error && (
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <AlertCircle className="w-8 h-8 text-red-500 mx-auto mb-4" />
            <p className="text-red-600 mb-2">{translations.error.general}</p>
            <button
              onClick={handleRefresh}
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              {translations.error.retry}
            </button>
          </div>
        </div>
      )}

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <h3 className="text-lg font-semibold mb-4">{translations.confirmation.title}</h3>
            <p className="text-gray-600 mb-6">{translations.confirmation.message}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmation(null)}
                className="px-4 py-2 text-gray-700 hover:text-gray-900"
              >
                {translations.confirmation.cancel}
              </button>
              <button
                onClick={() => handleDisconnect(showConfirmation)}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
              >
                {translations.confirmation.confirm}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Integrations list */}
      {!loading && !error && (
        <div className="space-y-4">
          {integrationsData.map((integration, index) => (
            <motion.div
              key={integration.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-shadow"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="p-3 bg-blue-50 rounded-lg">
                    <integration.icon className="w-6 h-6 text-blue-500" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900">{integration.name}</h3>
                    <p className="text-gray-600 text-sm mt-1">{integration.description}</p>
                  </div>
                </div>
                <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                  integration.status === 'connected'
                    ? 'bg-green-100 text-green-700'
                    : integration.status === 'pending'
                    ? 'bg-yellow-100 text-yellow-700'
                    : 'bg-gray-100 text-gray-700'
                }`}>
                  {translations.status[integration.status]}
                </span>
              </div>
              <div className="mt-4">
                <button
                  onClick={() => 
                    integration.status === 'connected' 
                      ? setShowConfirmation(integration.id)
                      : handleConnect(integration.type)
                  }
                  disabled={
                    (connectingId === integration.type) || 
                    (disconnectingId === integration.id) ||
                    integration.status === 'pending'
                  }
                  className={`w-full py-2 rounded-lg font-medium transition-colors ${
                    integration.status === 'connected'
                      ? 'bg-red-50 text-red-600 hover:bg-red-100'
                      : integration.status === 'pending'
                      ? 'bg-yellow-50 text-yellow-600 cursor-not-allowed opacity-70'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  } ${
                    (connectingId === integration.type || disconnectingId === integration.id) 
                      ? 'opacity-70 cursor-wait' 
                      : ''
                  }`}
                >
                  {connectingId === integration.type ? (
                    <span className="flex items-center justify-center">
                      <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                      {translations.actions.connecting}
                    </span>
                  ) : disconnectingId === integration.id ? (
                    <span className="flex items-center justify-center">
                      <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                      {translations.actions.disconnecting}
                    </span>
                  ) : integration.status === 'connected' ? (
                    translations.actions.disconnect
                  ) : integration.status === 'pending' ? (
                    translations.status.pending
                  ) : (
                    translations.actions.connect
                  )}
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
}