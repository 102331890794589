import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, User, ArrowRight } from 'lucide-react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { BlogPost } from '../components/BlogPost';
import { getBlogPosts } from '../lib/blog';

interface BlogPageProps {
  language: 'en' | 'tr';
}

export function BlogPage({ language }: BlogPageProps) {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState('');
  
  const allPosts = getBlogPosts(language);
  const filteredPosts = allPosts.filter(post => {
    const matchesCategory = selectedCategory === 'all' || post.category === selectedCategory;
    const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         post.excerpt.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         post.content.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const translations = {
    en: {
      search: "Search articles...",
      categories: [
        { id: 'all', label: 'All' },
        { id: 'AI Technology', label: 'AI Technology' },
        { id: 'Customer Service', label: 'Customer Service' },
        { id: 'Case Studies', label: 'Case Studies' },
        { id: 'Updates', label: 'Updates' }
      ],
      readMore: "Read More",
      noResults: "No articles found"
    },
    tr: {
      search: "Makalelerde ara...",
      categories: [
        { id: 'all', label: 'Tümü' },
        { id: 'Yapay Zeka', label: 'Yapay Zeka' },
        { id: 'Müşteri Hizmetleri', label: 'Müşteri Hizmetleri' },
        { id: 'Vaka Çalışmaları', label: 'Vaka Çalışmaları' },
        { id: 'Güncellemeler', label: 'Güncellemeler' }
      ],
      readMore: "Devamını Oku",
      noResults: "Makale bulunamadı"
    }
  };

  const currentLang = translations[language];

  return (
    <AnimatePresence mode="wait">
      <Routes>
        <Route
          path="/"
          element={
            <div data-testid="blog-page" className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-12">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative max-w-xl mx-auto mb-8">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={currentLang.search}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20"
                  />
                </div>

                <div className="flex overflow-x-auto space-x-4 mb-8 pb-4">
                  {currentLang.categories.map((category) => (
                    <button
                      key={category.id}
                      onClick={() => setSelectedCategory(category.id)}
                      className={`px-4 py-2 whitespace-nowrap rounded-lg transition-colors ${
                        selectedCategory === category.id
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-gray-700 hover:bg-gray-50'
                      }`}
                    >
                      {category.label}
                    </button>
                  ))}
                </div>

                {filteredPosts.length > 0 ? (
                  <div className="grid gap-8">
                    {filteredPosts.map((post) => (
                      <motion.article
                        key={post.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="bg-white rounded-2xl shadow-lg overflow-hidden cursor-pointer"
                        onClick={() => navigate(`/blog/${post.slug}`)}
                      >
                        <div className="flex flex-col md:flex-row">
                          <div className="md:w-1/2 aspect-w-16 aspect-h-9 md:aspect-none md:h-[400px]">
                            <img
                              src={post.image_url}
                              alt={post.title}
                              className="w-full h-full object-cover"
                              loading="lazy"
                            />
                          </div>
                          <div className="md:w-1/2 p-8">
                            <div className="text-sm text-blue-600 mb-2">{post.category}</div>
                            <h3 className="text-2xl font-semibold text-gray-900 mb-4">
                              {post.title}
                            </h3>
                            <p className="text-gray-600 mb-6 text-lg">
                              {post.excerpt}
                            </p>
                            <div className="flex items-center justify-between text-sm text-gray-500 mb-6">
                              <div className="flex items-center space-x-2">
                                <User className="w-4 h-4" />
                                <span>{post.author}</span>
                              </div>
                              <div className="flex items-center space-x-2">
                                <Calendar className="w-4 h-4" />
                                <span>{new Date(post.date).toLocaleDateString(language === 'tr' ? 'tr-TR' : 'en-US')}</span>
                              </div>
                            </div>
                            <div className="flex items-center text-blue-600 hover:text-blue-700 font-medium">
                              <span className="mr-2">{currentLang.readMore}</span>
                              <ArrowRight className="w-4 h-4" />
                            </div>
                          </div>
                        </div>
                      </motion.article>
                    ))}
                  </div>
                ) : (
                  <div className="text-center text-gray-500 py-12">
                    {currentLang.noResults}
                  </div>
                )}
              </div>
            </div>
          }
        />
        <Route 
          path=":slug" 
          element={<BlogPost language={language} />} 
        />
      </Routes>
    </AnimatePresence>
  );
}