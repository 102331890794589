import React from 'react';
import { motion } from 'framer-motion';
import { Users, MessageSquare, Calendar, TrendingUp, BarChart2, Clock } from 'lucide-react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DashboardCard } from '../../../components/dashboard/DashboardCard';
import { ChartCard } from '../../../components/dashboard/ChartCard';

export default function Dashboard() {
  // Generate mock data for the chart
  const currentDate = new Date();
  const lastWeekData = Array.from({ length: 7 }, (_, i) => {
    const date = new Date();
    date.setDate(currentDate.getDate() - 6 + i);
    return {
      name: date.toLocaleDateString('tr-TR', { weekday: 'short' }),
      görüşmeler: Math.floor(Math.random() * 20) + 5,
      randevular: Math.floor(Math.random() * 15) + 3,
    };
  });

  // Mock data for today's stats
  const todayStats = {
    görüşmeler: Math.floor(Math.random() * 15) + 5,
    randevular: Math.floor(Math.random() * 10) + 3,
  };

  // Mock data for weekly stats
  const weeklyStats = {
    görüşmeler: lastWeekData.reduce((sum, day) => sum + day.görüşmeler, 0),
    randevular: lastWeekData.reduce((sum, day) => sum + day.randevular, 0),
  };

  // Mock data for monthly stats
  const monthlyStats = {
    görüşmeler: weeklyStats.görüşmeler * 4 + Math.floor(Math.random() * 50),
    randevular: weeklyStats.randevular * 4 + Math.floor(Math.random() * 30),
  };

  // Mock data for upcoming appointments
  const upcomingAppointments = [
    {
      id: '1',
      patientName: 'Ahmet Yılmaz',
      procedure: 'Diş Dolgusu',
      time: '14:30',
      date: new Date().toLocaleDateString('tr-TR'),
    },
    {
      id: '2',
      patientName: 'Ayşe Demir',
      procedure: 'Diş Çekimi',
      time: '15:45',
      date: new Date().toLocaleDateString('tr-TR'),
    },
    {
      id: '3',
      patientName: 'Mehmet Kaya',
      procedure: 'Kontrol',
      time: '16:30',
      date: new Date().toLocaleDateString('tr-TR'),
    },
  ];

  return (
    <div className="space-y-6">
      {/* Stats Grid */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
        <DashboardCard
          title="Bugünkü Görüşmeler"
          value={todayStats.görüşmeler.toString()}
          icon={MessageSquare}
          trend={{ value: 12, isPositive: true }}
          color="blue"
        />
        <DashboardCard
          title="Bugünkü Randevular"
          value={todayStats.randevular.toString()}
          icon={Calendar}
          trend={{ value: 8, isPositive: true }}
          color="green"
        />
        <DashboardCard
          title="Haftalık Görüşmeler"
          value={weeklyStats.görüşmeler.toString()}
          icon={MessageSquare}
          trend={{ value: 5, isPositive: true }}
          color="purple"
        />
        <DashboardCard
          title="Haftalık Randevular"
          value={weeklyStats.randevular.toString()}
          icon={Calendar}
          trend={{ value: 3, isPositive: true }}
          color="orange"
        />
        <DashboardCard
          title="Aylık Görüşmeler"
          value={monthlyStats.görüşmeler.toString()}
          icon={MessageSquare}
          trend={{ value: 15, isPositive: true }}
          color="blue"
        />
        <DashboardCard
          title="Aylık Randevular"
          value={monthlyStats.randevular.toString()}
          icon={Calendar}
          trend={{ value: 10, isPositive: true }}
          color="green"
        />
      </div>

      {/* Chart */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <ChartCard title="Görüşme ve Randevu Analizi" icon={BarChart2} className="lg:col-span-2">
          <div className="h-[400px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={lastWeekData}>
                <defs>
                  <linearGradient id="colorGörüşmeler" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.2}/>
                    <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
                  </linearGradient>
                  <linearGradient id="colorRandevular" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#10B981" stopOpacity={0.2}/>
                    <stop offset="95%" stopColor="#10B981" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                <XAxis 
                  dataKey="name" 
                  stroke="#6B7280"
                  fontSize={12}
                  tickLine={false}
                  axisLine={{ stroke: '#E5E7EB' }}
                />
                <YAxis
                  stroke="#6B7280"
                  fontSize={12}
                  tickLine={false}
                  axisLine={{ stroke: '#E5E7EB' }}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E5E7EB',
                    borderRadius: '0.5rem',
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="görüşmeler"
                  name="Görüşmeler"
                  stroke="#3B82F6"
                  fillOpacity={1}
                  fill="url(#colorGörüşmeler)"
                />
                <Area
                  type="monotone"
                  dataKey="randevular"
                  name="Randevular"
                  stroke="#10B981"
                  fillOpacity={1}
                  fill="url(#colorRandevular)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </ChartCard>

        {/* Upcoming Appointments */}
        <ChartCard title="Yaklaşan Randevular" icon={Clock}>
          <div className="space-y-4">
            {upcomingAppointments.map((appointment) => (
              <div 
                key={appointment.id}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium text-gray-900">{appointment.patientName}</h3>
                    <p className="text-sm text-gray-500">{appointment.procedure}</p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm font-medium text-blue-600">{appointment.time}</p>
                    <p className="text-xs text-gray-500">{appointment.date}</p>
                  </div>
                </div>
              </div>
            ))}
            <button className="w-full py-2 text-sm text-blue-600 hover:text-blue-800 font-medium">
              Tüm Randevuları Görüntüle
            </button>
          </div>
        </ChartCard>
      </div>

      {/* Patient Activity */}
      <ChartCard title="Hasta Aktivitesi" icon={Users}>
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={[
                { name: 'Pzt', yeni: 4, tekrar: 12 },
                { name: 'Sal', yeni: 3, tekrar: 10 },
                { name: 'Çar', yeni: 5, tekrar: 8 },
                { name: 'Per', yeni: 2, tekrar: 15 },
                { name: 'Cum', yeni: 6, tekrar: 11 },
                { name: 'Cmt', yeni: 8, tekrar: 6 },
                { name: 'Paz', yeni: 2, tekrar: 4 },
              ]}
            >
              <defs>
                <linearGradient id="colorYeni" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8B5CF6" stopOpacity={0.2}/>
                  <stop offset="95%" stopColor="#8B5CF6" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorTekrar" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F59E0B" stopOpacity={0.2}/>
                  <stop offset="95%" stopColor="#F59E0B" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
              <XAxis 
                dataKey="name" 
                stroke="#6B7280"
                fontSize={12}
                tickLine={false}
                axisLine={{ stroke: '#E5E7EB' }}
              />
              <YAxis
                stroke="#6B7280"
                fontSize={12}
                tickLine={false}
                axisLine={{ stroke: '#E5E7EB' }}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #E5E7EB',
                  borderRadius: '0.5rem',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                }}
              />
              <Area
                type="monotone"
                dataKey="yeni"
                name="Yeni Hastalar"
                stroke="#8B5CF6"
                fillOpacity={1}
                fill="url(#colorYeni)"
              />
              <Area
                type="monotone"
                dataKey="tekrar"
                name="Tekrar Gelen Hastalar"
                stroke="#F59E0B"
                fillOpacity={1}
                fill="url(#colorTekrar)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </ChartCard>
    </div>
  );
}