import React from 'react';
import { motion } from 'framer-motion';
import { format, parseISO } from 'date-fns';
import { tr } from 'date-fns/locale';
import { X, Calendar, Clock, User, Building, MessageSquare, Trash2, Edit, Loader2, Phone, Clipboard } from 'lucide-react';
import { useCalendarStore, CalendarEvent } from '../../store/calendarStore';

interface AppointmentDetailsProps {
  appointment: CalendarEvent;
  onClose: () => void;
  language: 'en' | 'tr';
  userId: string;
}

export function AppointmentDetails({ appointment, onClose, language, userId }: AppointmentDetailsProps) {
  const { updateEvent, deleteEvent, loading } = useCalendarStore();
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [status, setStatus] = React.useState<'confirmed' | 'pending' | 'cancelled'>(appointment.status);

  // Get custom properties for dental procedures
  const category = (appointment as any).category || 'restorative';
  const doctor = (appointment as any).doctor || 'Dr. Ayşe Yılmaz';
  const phone = (appointment as any).phone || '+90 555 123 4567';

  const translations = {
    title: 'Randevu Detayları',
    fields: {
      date: 'Tarih',
      time: 'Saat',
      customer: 'Hasta',
      company: 'Sigorta/Kurum',
      status: 'Durum',
      notes: 'Notlar',
      category: 'İşlem Kategorisi',
      doctor: 'Doktor',
      phone: 'Telefon',
    },
    status: {
      confirmed: 'Onaylandı',
      pending: 'Beklemede',
      cancelled: 'İptal Edildi',
    },
    buttons: {
      close: 'Kapat',
      edit: 'Düzenle',
      delete: 'Sil',
      save: 'Değişiklikleri Kaydet',
      saving: 'Kaydediliyor...',
      deleting: 'Siliniyor...',
      confirmDelete: 'Silmeyi Onayla',
      cancelDelete: 'İptal',
    },
    deleteConfirmation: 'Bu randevuyu silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  };

  const handleStatusChange = async () => {
    if (!isEditing || loading) return;
    
    try {
      const updatedAppointment = {
        ...appointment,
        status,
      };
      
      await updateEvent(userId, updatedAppointment);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating appointment status:', error);
    }
  };

  const handleDelete = async () => {
    if (loading) return;
    
    if (!isDeleting) {
      setIsDeleting(true);
      return;
    }
    
    try {
      await deleteEvent(userId, appointment.id);
      onClose();
    } catch (error) {
      console.error('Error deleting appointment:', error);
      setIsDeleting(false);
    }
  };

  const startDate = parseISO(appointment.start);
  const endDate = parseISO(appointment.end);

  // Get category label
  const getCategoryLabel = (categoryId: string) => {
    const categories = {
      'restorative': 'Restoratif',
      'periodontal': 'Periodontoloji',
      'surgical': 'Cerrahi',
      'orthodontic': 'Ortodonti',
      'endodontic': 'Endodonti',
      'prosthodontic': 'Protez',
      'preventive': 'Koruyucu',
      'pediatric': 'Pedodonti',
      'cosmetic': 'Estetik'
    };
    return categories[categoryId as keyof typeof categories] || categoryId;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-lg shadow-xl max-w-lg w-full mx-4"
      >
        <div className="flex items-center justify-between p-6 border-b">
          <h2 className="text-xl font-semibold text-gray-900">{translations.title}</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
            disabled={loading}
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 space-y-6">
          <div className="space-y-4">
            {/* Title */}
            <div>
              <h3 className="text-lg font-medium text-gray-900">{appointment.title}</h3>
            </div>

            {/* Category and Doctor */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-medium text-gray-500">{translations.fields.category}</p>
                <p className="mt-1 text-gray-900">{getCategoryLabel(category)}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{translations.fields.doctor}</p>
                <p className="mt-1 text-gray-900">{doctor}</p>
              </div>
            </div>

            {/* Date and Time */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-medium text-gray-500">{translations.fields.date}</p>
                <p className="mt-1 text-gray-900">
                  {format(startDate, 'PPP', { locale: tr })}
                </p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{translations.fields.time}</p>
                <p className="mt-1 text-gray-900">
                  {format(startDate, 'p', { locale: tr })} - {format(endDate, 'p', { locale: tr })}
                </p>
              </div>
            </div>

            {/* Patient Details */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-sm font-medium text-gray-500">{translations.fields.customer}</p>
                <p className="mt-1 text-gray-900">{appointment.customerName}</p>
                <p className="text-sm text-gray-500">{appointment.customerEmail}</p>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{translations.fields.phone}</p>
                <p className="mt-1 text-gray-900">{phone}</p>
              </div>
            </div>

            {/* Insurance */}
            {appointment.companyName && (
              <div>
                <p className="text-sm font-medium text-gray-500">{translations.fields.company}</p>
                <p className="mt-1 text-gray-900">{appointment.companyName}</p>
              </div>
            )}

            {/* Status */}
            <div>
              <p className="text-sm font-medium text-gray-500">{translations.fields.status}</p>
              {isEditing ? (
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value as 'confirmed' | 'pending' | 'cancelled')}
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  disabled={loading}
                >
                  <option value="confirmed">{translations.status.confirmed}</option>
                  <option value="pending">{translations.status.pending}</option>
                  <option value="cancelled">{translations.status.cancelled}</option>
                </select>
              ) : (
                <div className="mt-1">
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      appointment.status === 'confirmed'
                        ? 'bg-green-100 text-green-800'
                        : appointment.status === 'pending'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {translations.status[appointment.status]}
                  </span>
                </div>
              )}
            </div>

            {/* Notes */}
            {appointment.notes && (
              <div>
                <p className="text-sm font-medium text-gray-500">{translations.fields.notes}</p>
                <p className="mt-1 text-gray-900 whitespace-pre-line">{appointment.notes}</p>
              </div>
            )}
          </div>

          <div className="flex justify-between pt-4 border-t">
            <div>
              {isDeleting ? (
                <div className="flex items-center space-x-2">
                  <p className="text-sm text-red-600">{translations.deleteConfirmation}</p>
                </div>
              ) : (
                <button
                  onClick={handleDelete}
                  className="flex items-center space-x-1 text-red-600 hover:text-red-700"
                  disabled={loading}
                >
                  {loading && isDeleting ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <Trash2 className="w-4 h-4" />
                  )}
                  <span>{loading && isDeleting ? translations.buttons.deleting : translations.buttons.delete}</span>
                </button>
              )}
            </div>
            
            <div className="flex space-x-4">
              {isDeleting ? (
                <>
                  <button
                    onClick={() => setIsDeleting(false)}
                    className="px-4 py-2 text-gray-700 hover:text-gray-900"
                    disabled={loading}
                  >
                    {translations.buttons.cancelDelete}
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50 flex items-center space-x-2"
                    disabled={loading}
                  >
                    {loading && <Loader2 className="w-4 h-4 animate-spin" />}
                    <span>{loading ? translations.buttons.deleting : translations.buttons.confirmDelete}</span>
                  </button>
                </>
              ) : isEditing ? (
                <>
                  <button
                    onClick={() => setIsEditing(false)}
                    className="px-4 py-2 text-gray-700 hover:text-gray-900"
                    disabled={loading}
                  >
                    {translations.buttons.close}
                  </button>
                  <button
                    onClick={handleStatusChange}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 flex items-center space-x-2"
                    disabled={loading}
                  >
                    {loading && <Loader2 className="w-4 h-4 animate-spin" />}
                    <span>{loading ? translations.buttons.saving : translations.buttons.save}</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={onClose}
                    className="px-4 py-2 text-gray-700 hover:text-gray-900"
                  >
                    {translations.buttons.close}
                  </button>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="flex items-center space-x-1 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                  >
                    <Edit className="w-4 h-4" />
                    <span>{translations.buttons.edit}</span>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}