import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Eye, Database, FileText, UserCheck, Bell, Mail } from 'lucide-react';

interface PrivacyPageProps {
  language: 'en' | 'tr';
}

export function PrivacyPage({ language }: PrivacyPageProps) {
  const translations = {
    en: {
      title: "Privacy Policy",
      lastUpdated: "Last updated: April 3, 2025",
      intro: "BeOneBot is committed to protecting your privacy. This Privacy Policy outlines clearly how we collect, use, and safeguard your personal information while using the BeOneBot platform.",
      sections: [
        {
          icon: FileText,
          title: "1. Information We Collect",
          subsections: [
            {
              subtitle: "a. Personal Information",
              content: "We collect personal details such as your name, surname, email address, and phone number, provided voluntarily when creating an account or accessing our services.",
            },
            {
              subtitle: "b. Automatically Collected Information",
              content: "We automatically gather data including IP addresses, browser types, device types, operating systems, and usage details like pages visited, click patterns, and duration of site use.",
            },
            {
              subtitle: "c. Cookies and Similar Technologies",
              content: "We utilize cookies and similar technologies to enhance your user experience. You have the option to disable cookies through your browser settings; however, this may affect certain functionalities of our services.",
            },
          ],
        },
        {
          icon: Eye,
          title: "2. How We Use Your Information",
          content: "We use your personal data to:\n• Provide, operate, and maintain our services\n• Enhance customer support and experience\n• Perform analytics to improve our platform\n• Evaluate and respond to user feedback\n• Meet legal and regulatory obligations",
        },
        {
          icon: Lock,
          title: "3. Information Sharing",
          content: "BeOneBot does not sell, rent, or trade your personal data to third parties. Your information may be shared only under these circumstances:\n• Compliance with legal requirements (e.g., court orders, legal proceedings) \n• Sharing with trusted third-party providers (hosting, analytics) solely for operational purposes",
        },
        {
          icon: Shield,
          title: "4. Security Measures",
          content: "We apply rigorous technical and administrative safeguards to secure your data. However, no online transmission is entirely secure. In the event of a data breach or unauthorized access, we will promptly notify affected users.",
        },
        {
          icon: Database,
          title: "5. Data Retention",
          content: "We retain your personal information only as long as necessary to fulfill the purposes stated in this policy, unless longer retention is required by law.",
        },
        {
          icon: UserCheck,
          title: "6. Your Privacy Rights",
          content: "You have the right to:\n• Access and review your personal data\n• Request corrections or updates\n• Request deletion of your data\n• Restrict or object to data processing\n• To exercise your rights, please get in touch with us at: contact@beonehorizon.com",
        },
        {
          icon: Bell,
          title: "7. Updates to this Policy ",
          content: "This Privacy Policy may be updated periodically. All changes take effect upon publication on our site. We recommend checking this policy regularly.",
        },
        {
          icon: Mail,
          title: "8. Contact Information",
          content: "8. Contact Information If you have questions or concerns about our Privacy Policy, please contact us at: info@beonehorizon.com",
        },
      ],
    },
    tr: {
      title: "Gizlilik Politikası",
      lastUpdated: "Son güncelleme: 3 Nisan 2025",
      intro: "BeOneBot olarak gizliliğinizi korumaya kararlıyız. Bu Gizlilik Politikası, BeOneBot platformunu kullanırken kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı ve koruduğumuzu net bir şekilde açıklamaktadır.",
      sections: [
        {
          icon: FileText,
          title: "1. Topladığımız Bilgiler",
          subsections: [
            {
              subtitle: "a. Kişisel Bilgiler",
              content: "Hesap oluştururken veya hizmetlerimize erişirken gönüllü olarak sağladığınız isim, soyisim, e-posta adresi ve telefon numarası gibi bilgileri topluyoruz.",
            },
            {
              subtitle: "b. Otomatik Olarak Toplanan Bilgiler",
              content: "IP adresi, tarayıcı türü, cihaz türü ve işletim sistemi ile ziyaret edilen sayfalar, tıklama davranışları ve kullanım süresi gibi verileri otomatik olarak topluyoruz.",
            },
            {
              subtitle: "c. Çerezler ve Benzeri Teknolojiler",
              content: "Web sitemizde kullanıcı deneyimini iyileştirmek için çerezler ve benzeri teknolojiler kullanıyoruz. Çerezleri tarayıcı ayarlarınızdan devre dışı bırakabilirsiniz; ancak bu durum, hizmetlerimizin bazı işlevlerini etkileyebilir.",
            },
          ],
        },
        {
          icon: Eye,
          title: "2. Bilgilerin Kullanımı",
          content: "Kişisel verilerinizi aşağıdaki amaçlarla kullanıyoruz: \n• Hizmetlerimizi sunmak, işletmek ve sürdürmek\n• Kullanıcı desteğini ve deneyimini geliştirmek \n• Platformumuzu iyileştirmek için analiz yapmak\n• Kullanıcı geri bildirimlerini değerlendirmek ve yanıtlamak\n• Yasal yükümlülüklere uyum sağlamak",
        },
        {
          icon: Lock,
          title: "3. Bilgi Paylaşımı",
          content: "BeOneBot kişisel verilerinizi üçüncü şahıslara satmaz, kiralamaz veya ticaretini yapmaz. Bilgileriniz yalnızca aşağıdaki durumlarda paylaşılabilir:\n• Yasal yükümlülükler gereği (ör. mahkeme kararları, yasal işlemler) \n• Güvenilir üçüncü taraf hizmet sağlayıcılarıyla (barındırma, analiz gibi) yalnızca operasyonel amaçlar doğrultusunda",
        },
        {
          icon: Shield,
          title: "4. Güvenlik Önlemleri",
          content: "Verilerinizi korumak için sıkı teknik ve idari önlemler uyguluyoruz. Ancak internet üzerinden hiçbir veri aktarımı tamamen güvenli değildir. Herhangi bir veri ihlali veya yetkisiz erişim durumunda, kullanıcıları derhal bilgilendireceğiz.",
        },
        {
          icon: Database,
          title: "5. Veri Saklama Süresi",
          content: "Kişisel bilgileriniz, yalnızca bu politikada belirtilen amaçları yerine getirmek için sözleşmede belirlenen süre boyunca saklanır, yasal olarak daha uzun süre saklanması gereken durumlar hariçtir.",
        },
        {
          icon: UserCheck,
          title: "6. Gizlilik Haklarınız",
          content: "Kişisel verilerinizle ilgili olarak aşağıdaki haklara sahipsiniz:\n• Kişisel verilerinize erişme ve inceleme\n• Verilerinizin düzeltilmesini veya güncellenmesini talep etme\n• Verilerinizin silinmesini talep etme\n• Veri işlemeyi sınırlama veya veri işlemeye itiraz etme\n• Haklarınızla ilgili sorularınız için lütfen bizimle iletişime geçiniz: contact@beonehorizon.com",
        },
        {
          icon: Bell,
          title: "7. Politikada Güncellemeler",
          content: "Bu Gizlilik Politikası dönemsel olarak güncellenebilir. Tüm değişiklikler, web sitemizde yayımlandığında yürürlüğe girer. Bu politikayı düzenli olarak kontrol etmenizi öneririz.",
        },
        {
          icon: Mail,
          title: "8. İletişim Bilgileri",
          content: "Gizlilik Politikamızla ilgili sorularınız veya talepleriniz varsa, lütfen bizimle iletişime geçiniz: info@beonehorizon.com",
        },
      ],
    },
  };

  const currentLang = translations[language];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            {currentLang.title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-gray-600 mb-8"
          >
            {currentLang.lastUpdated}
          </motion.p>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-gray-600 max-w-3xl mx-auto"
          >
            {currentLang.intro}
          </motion.p>
        </div>

        <div className="space-y-12">
          {currentLang.sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 + 0.3 }}
              className="bg-white rounded-2xl p-8 shadow-lg"
            >
              <div className="flex items-center space-x-4 mb-6">
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center">
                  <section.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h2 className="text-2xl font-semibold text-gray-900">
                  {section.title}
                </h2>
              </div>

              {'subsections' in section ? (
                <div className="space-y-6">
                  {section.subsections.map((subsection, subIndex) => (
                    <div key={subIndex}>
                      <h3 className="text-lg font-medium text-gray-900 mb-2">
                        {subsection.subtitle}
                      </h3>
                      <p className="text-gray-600 whitespace-pre-line">
                        {subsection.content}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-600 whitespace-pre-line">
                  {section.content}
                </p>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}