import React from 'react';
import { motion } from 'framer-motion';
import { DivideIcon as LucideIcon } from 'lucide-react';
import { useDashboardStore } from '../../store/dashboardStore';

interface DashboardCardProps {
  title: string;
  value: string | number;
  icon: LucideIcon;
  trend?: {
    value: number;
    isPositive: boolean;
  };
  color?: 'blue' | 'green' | 'purple' | 'orange';
}

export function DashboardCard({ title, value, icon: Icon, trend, color = 'blue' }: DashboardCardProps) {
  const user = useDashboardStore(state => state.user);
  const language = user?.settings?.language || 'en';

  const translations = {
    en: {
      increase: 'increase',
      decrease: 'decrease',
    },
    tr: {
      increase: 'artış',
      decrease: 'azalış',
    },
  };

  const t = translations[language];

  const colorClasses = {
    blue: {
      bg: 'bg-blue-50',
      icon: 'text-blue-500',
      border: 'border-blue-200',
      trend: {
        positive: 'text-blue-600',
        negative: 'text-blue-600',
      },
    },
    green: {
      bg: 'bg-green-50',
      icon: 'text-green-500',
      border: 'border-green-200',
      trend: {
        positive: 'text-green-600',
        negative: 'text-green-600',
      },
    },
    purple: {
      bg: 'bg-purple-50',
      icon: 'text-purple-500',
      border: 'border-purple-200',
      trend: {
        positive: 'text-purple-600',
        negative: 'text-purple-600',
      },
    },
    orange: {
      bg: 'bg-orange-50',
      icon: 'text-orange-500',
      border: 'border-orange-200',
      trend: {
        positive: 'text-orange-600',
        negative: 'text-orange-600',
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-white rounded-xl border ${colorClasses[color].border} p-6 hover:shadow-lg transition-shadow`}
    >
      <div className="flex items-center justify-between mb-4">
        <div className={`p-3 rounded-lg ${colorClasses[color].bg}`}>
          <Icon className={`w-6 h-6 ${colorClasses[color].icon}`} />
        </div>
        {trend && (
          <div className={`flex items-center ${trend.isPositive ? 'text-green-600' : 'text-red-600'}`}>
            <span className="text-sm font-medium">
              {trend.isPositive ? '+' : '-'}{Math.abs(trend.value)}% {trend.isPositive ? t.increase : t.decrease}
            </span>
          </div>
        )}
      </div>
      <h3 className="text-gray-600 text-sm font-medium mb-2">{title}</h3>
      <p className="text-2xl font-bold text-gray-900">{value}</p>
    </motion.div>
  );
}