import React from 'react';
import { motion } from 'framer-motion';
import { format, parseISO, isToday, isTomorrow } from 'date-fns';
import { tr, enUS } from 'date-fns/locale';
import { Calendar, Clock, User, Clipboard } from 'lucide-react';
import { CalendarEvent } from '../../store/calendarStore';

interface UpcomingAppointmentsProps {
  language: 'en' | 'tr';
  events: CalendarEvent[];
}

export function UpcomingAppointments({ language, events }: UpcomingAppointmentsProps) {
  const translations = {
    en: {
      title: 'Upcoming Appointments',
      noAppointments: 'No upcoming appointments',
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
    tr: {
      title: 'Yaklaşan Randevular',
      noAppointments: 'Yaklaşan randevu yok',
      today: 'Bugün',
      tomorrow: 'Yarın',
    },
  };

  const t = translations[language];
  const dateLocale = language === 'tr' ? tr : enUS;

  // Filter and sort upcoming appointments
  const now = new Date();
  const upcomingEvents = events
    .filter(event => {
      const eventDate = parseISO(event.start);
      return eventDate > now && event.status !== 'cancelled';
    })
    .sort((a, b) => {
      return parseISO(a.start).getTime() - parseISO(b.start).getTime();
    })
    .slice(0, 5); // Show only the next 5 appointments

  return (
    <div className="bg-white rounded-lg shadow p-4">
      <h3 className="font-medium text-gray-900 mb-4 text-base">{t.title}</h3>
      
      {upcomingEvents.length > 0 ? (
        <div className="space-y-3">
          {upcomingEvents.map((event) => {
            const eventDate = parseISO(event.start);
            const isEventToday = isToday(eventDate);
            const isEventTomorrow = isTomorrow(eventDate);
            
            let dateDisplay;
            if (isEventToday) {
              dateDisplay = t.today;
            } else if (isEventTomorrow) {
              dateDisplay = t.tomorrow;
            } else {
              dateDisplay = format(eventDate, 'd MMMM', { locale: dateLocale });
            }
            
            return (
              <div
                key={event.id}
                className="flex items-start space-x-3 p-3 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer border border-gray-100"
              >
                <div className={`w-2 h-full mt-1 rounded-full flex-shrink-0 ${
                  event.status === 'confirmed'
                    ? 'bg-green-500'
                    : 'bg-yellow-500'
                }`} />
                <div className="flex-1 min-w-0">
                  <p className="font-medium text-gray-900 text-sm truncate">{event.title}</p>
                  <p className="text-xs text-gray-500 truncate">{event.customerName}</p>
                  <div className="flex items-center space-x-3 mt-1 text-xs text-gray-500">
                    <div className="flex items-center">
                      <Calendar className="w-3 h-3 mr-1" />
                      <span>{dateDisplay}</span>
                    </div>
                    <div className="flex items-center">
                      <Clock className="w-3 h-3 mr-1" />
                      <span>{format(eventDate, 'HH:mm', { locale: dateLocale })}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-center py-6 bg-gray-50 rounded-lg">
          <Calendar className="w-8 h-8 text-gray-400 mx-auto mb-2" />
          <p className="text-gray-500 text-sm">{t.noAppointments}</p>
        </div>
      )}
    </div>
  );
}