import React from 'react';
import { motion } from 'framer-motion';
import { Bot, User, Calendar } from 'lucide-react';

interface AppointmentChatProps {
  language: 'en' | 'tr';
}

export function AppointmentChat({ language }: AppointmentChatProps) {
  const translations = {
    en: {
      title: "See how BOB works",
      subtitle: "Example conversation flow for appointment booking",
      steps: [
        {
          user: "I'd like to schedule a dental check-up",
          bot: "I'll help you schedule an appointment. What date and time would work best for you?",
        },
        {
          user: "How about Friday afternoon?",
          bot: "Friday at 3:00 PM is available. Would you like me to create your appointment?",
        },
        {
          user: "Yes, please.",
          bot: "Your appointment has been created for Friday at 3:00 PM. I'll send you a reminder via WhatsApp. Is there anything else I can help you with?",
        },
      ],
      features: {
        scheduling: "Smart Scheduling",
        calendar: "Calendar Integration",
        reminders: "Automated Reminders",
      }
    },
    tr: {
      title: "BOB nasıl çalışır?",
      subtitle: "Randevu planlaması için örnek konuşma akışı",
      steps: [
        {
          user: "Merhaba, diş kontrolü için randevu almak istiyorum",
          bot: "Size en uygun tarih ve saati belirlemek için yardımcı olayım. Bu hafta hangi gün sizin için uygun olur?",
        },
        {
          user: "Cuma günü öğleden sonra uygun",
          bot: "Cuma günü saat 15:00 müsait. Randevunuzu oluşturayım mı?",
        },
        {
          user: "Evet lütfen",
          bot: "Randevunuz Cuma saat 15:00 için oluşturuldu. Size WhatsApp üzerinden hatırlatma mesajı göndereceğim. Başka yardımcı olabileceğim bir konu var mı?",
        },
      ],
      features: {
        scheduling: "Akıllı Planlama",
        calendar: "Takvim Entegrasyonu",
        reminders: "Otomatik Hatırlatmalar",
      }
    },
  };

  const currentLang = translations[language];

  return (
    <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-6 text-white">
        <h2 className="text-2xl font-bold">{currentLang.title}</h2>
        <p className="mt-2 text-blue-100">{currentLang.subtitle}</p>
      </div>

      <div className="p-6 space-y-8">
        {currentLang.steps.map((step, index) => (
          <div key={index} className="space-y-4">
            {/* User Message */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="flex items-start justify-end space-x-2"
            >
              <div className="bg-blue-500 text-white rounded-2xl rounded-tr-none p-4 max-w-md shadow-md">
                <div className="flex items-center space-x-2 mb-2">
                  <User className="w-4 h-4" />
                  <span className="text-sm font-medium">You</span>
                </div>
                <p>{step.user}</p>
              </div>
            </motion.div>

            {/* Bot Message */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 + 0.1 }}
              className="flex items-start space-x-2"
            >
              <div className="bg-gray-100 rounded-2xl rounded-tl-none p-4 max-w-md shadow-md">
                <div className="flex items-center space-x-2 mb-2">
                  <Bot className="w-4 h-4 text-blue-500" />
                  <span className="text-sm font-medium text-blue-500">BOB</span>
                </div>
                <p className="text-gray-700">{step.bot}</p>
              </div>
            </motion.div>

            {/* Step Indicator */}
            {index < currentLang.steps.length - 1 && (
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 + 0.2 }}
                className="flex justify-center"
              >
                <div className="w-px h-8 bg-gray-200" />
              </motion.div>
            )}
          </div>
        ))}
      </div>

      {/* Features */}
      <div className="border-t border-gray-100 bg-gray-50 p-6">
        <div className="grid grid-cols-3 gap-4">
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-600">
            <Calendar className="w-5 h-5 text-blue-500" />
            <span>{currentLang.features.scheduling}</span>
          </div>
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-600">
            <Calendar className="w-5 h-5 text-blue-500" />
            <span>{currentLang.features.calendar}</span>
          </div>
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-600">
            <Bot className="w-5 h-5 text-blue-500" />
            <span>{currentLang.features.reminders}</span>
          </div>
        </div>
      </div>
    </div>
  );
}