import React from 'react';
import { motion } from 'framer-motion';
import { FileText, AlertCircle, CheckCircle, HelpCircle } from 'lucide-react';

interface TermsPageProps {
  language: 'en' | 'tr';
}

export function TermsPage({ language }: TermsPageProps) {
  const translations = {
    en: {
      title: "Terms of Service",
      lastUpdated: "Last updated: March 15, 2025",
      sections: [
        {
          icon: FileText,
          title: "Acceptance of Terms",
          content: "By accessing or using BeOneBot services, you expressly agree to these Terms of Service. Our platform provides AI-powered customer service solutions. Your use of our services constitutes your acknowledgment and acceptance of our operational procedures outlined herein."
        },
        {
          icon: AlertCircle,
          title: "User Responsibilities",
          content: "As a user, you are solely responsible for safeguarding your account credentials and all activities occurring under your account. Any unauthorized use or breach of security must immediately be reported to our support team."
        },
        {
          icon: CheckCircle,
          title: "Service Availability and Limitations",
          content: "Our services are provided on an 'as is' and 'as available' basis. While we take every reasonable measure to ensure consistent and reliable service, we do not warrant uninterrupted or error-free operation. Users agree to comply fully with applicable local and international laws when using our services."
        },
        {
          icon: HelpCircle,
          title: "Technical Support and Updates",
          content: "We continuously enhance our services through regular updates and improvements. Significant changes or updates will be communicated to users in advance. Technical support availability depends upon your selected subscription plan."
        },
         {
          icon: FileText,
          title: "Modifications to Terms",
          content: "BeOneBot reserves the right to modify or revise these Terms of Service at any time. Updates will be posted on this page, and continued use of the services after any changes constitutes acceptance of those changes. We recommend reviewing this page periodically."
        }
      ],
      contact: {
        title: "Contact Information",
        content: "If you have any questions or require clarification regarding these Terms, please reach out to our legal department: Email: legal@beonehorizon.com."
      }
    },
    tr: {
      title: "Kullanım Şartları",
      lastUpdated: "Son güncelleme: 15 Mart 2025",
      sections: [
        {
          icon: FileText,
          title: "Kullanım Koşulları",
          content: "BeOneBot hizmetlerine erişerek veya hizmetleri kullanarak, bu Kullanım Şartları'nı açıkça kabul etmiş sayılırsınız. Platformumuz, yapay zeka destekli müşteri hizmetleri çözümleri sunmaktadır. Hizmetlerimizi kullanmanız, işbu belgede belirtilen operasyonel prosedürlerimizi kabul ettiğiniz anlamına gelir."
        },
        {
          icon: AlertCircle,
          title: "Kullanıcı Sorumlulukları",
          content: "Kullanıcı olarak, hesap bilgilerinizin güvenliğini sağlamak ve hesabınız altında gerçekleşen tüm işlemlerden sorumlu bulunmaktasınız. Hesabınızın yetkisiz kullanımı veya herhangi bir güvenlik ihlalini derhal destek ekibimize bildirmeniz gerekmektedir."
        },
        {
          icon: CheckCircle,
          title: "Hizmet Kullanımı ve Sınırlamalar",
          content: "Hizmetlerimiz \"olduğu gibi\" ve \"mevcut durumda\" sağlanmaktadır. Kesintisiz ve hatasız hizmet sunmak için makul her türlü tedbiri almakla birlikte, sürekli hizmet garantisi veremeyiz. Kullanıcılar, hizmetleri kullanırken geçerli yerel ve uluslararası tüm yasa ve yönetmeliklere eksiksiz olarak uymayı kabul eder."
        },
        {
          icon: HelpCircle,
          title: "Teknik Destek ve Güncellemeler",
          content: "Hizmetlerimizi sürekli olarak güncellemekte ve geliştirmekteyiz. Önemli değişiklikler veya güncellemeler kullanıcılarımıza önceden bildirilecektir. Teknik destek kapsamı ve erişilebilirliği, seçmiş olduğunuz abonelik planına bağlıdır."
        },
        {
          icon: FileText,
          title: "Şartların Değiştirilmesi",
          content: "BeOneBot, bu Kullanım Şartları'nı herhangi bir zamanda değiştirme hakkını saklı tutar. Güncellemeler bu sayfada yayınlanacak olup, değişikliklerden sonra hizmetleri kullanmaya devam etmeniz, bu değişiklikleri kabul ettiğiniz anlamına gelecektir. Bu sayfayı periyodik olarak gözden geçirmenizi öneririz."
        }
      ],
      contact: {
        title: "İletişim Bilgileri",
        content: "Kullanım Şartlarımız hakkında sorularınız veya açıklama talepleriniz varsa, lütfen hukuk departmanımız ile iletişime geçiniz: E-posta: legal@beonehorizon.com"
      }
    }
  };

  const currentLang = translations[language];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            {currentLang.title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-gray-600"
          >
            {currentLang.lastUpdated}
          </motion.p>
        </div>

        <div className="space-y-12">
          {currentLang.sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 + 0.2 }}
              className="bg-white rounded-2xl p-6 shadow-lg"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center">
                  <section.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h2 className="text-xl font-semibold text-gray-900">
                  {section.title}
                </h2>
              </div>
              <p className="text-gray-600 leading-relaxed">
                {section.content}
              </p>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="mt-12 bg-blue-50 rounded-2xl p-6 border border-blue-100"
        >
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            {currentLang.contact.title}
          </h2>
          <p className="text-gray-600">
            {currentLang.contact.content}
          </p>
        </motion.div>
      </div>
    </div>
  );
}