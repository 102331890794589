import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { DashboardState, DashboardUser, DashboardStats, AppointmentData, CustomerInteraction } from '../types/dashboard';

// Mock data for demo mode with both English and Turkish content
const mockData = {
  en: {
    stats: {
      totalAppointments: 156,
      completedAppointments: 134,
      pendingAppointments: 22,
      totalCustomers: 89,
      averageResponseTime: 1.5,
      customerSatisfaction: 94,
    },
    appointments: [
      {
        id: '1',
        customerName: 'John Doe',
        date: '2025-04-15',
        time: '14:00',
        status: 'confirmed',
        service: 'Consultation',
      },
      {
        id: '2',
        customerName: 'Jane Smith',
        date: '2025-04-16',
        time: '15:30',
        status: 'pending',
        service: 'Technical Support',
      },
    ],
    interactions: [
      {
        id: '1',
        customerId: '1',
        customerName: 'John Doe',
        channel: 'whatsapp',
        timestamp: '2025-04-15T14:00:00Z',
        message: 'Hello, I need help with my appointment',
        status: 'resolved',
      },
      {
        id: '2',
        customerId: '2',
        customerName: 'Jane Smith',
        channel: 'telegram',
        timestamp: '2025-04-15T15:00:00Z',
        message: 'When is my next appointment?',
        status: 'pending',
      },
    ],
  },
  tr: {
    stats: {
      totalAppointments: 156,
      completedAppointments: 134,
      pendingAppointments: 22,
      totalCustomers: 89,
      averageResponseTime: 1.5,
      customerSatisfaction: 94,
    },
    appointments: [
      {
        id: '1',
        customerName: 'Ahmet Yılmaz',
        date: '2025-04-15',
        time: '14:00',
        status: 'confirmed',
        service: 'Danışmanlık',
      },
      {
        id: '2',
        customerName: 'Ayşe Demir',
        date: '2025-04-16',
        time: '15:30',
        status: 'pending',
        service: 'Teknik Destek',
      },
    ],
    interactions: [
      {
        id: '1',
        customerId: '1',
        customerName: 'Ahmet Yılmaz',
        channel: 'whatsapp',
        timestamp: '2025-04-15T14:00:00Z',
        message: 'Merhaba, randevum hakkında yardıma ihtiyacım var',
        status: 'resolved',
      },
      {
        id: '2',
        customerId: '2',
        customerName: 'Ayşe Demir',
        channel: 'telegram',
        timestamp: '2025-04-15T15:00:00Z',
        message: 'Bir sonraki randevum ne zaman?',
        status: 'pending',
      },
    ],
  },
};

export const useDashboardStore = create<DashboardState>((set, get) => ({
  user: {
    id: 'demo-user',
    email: 'demo@beonebot.com',
    name: 'Demo User',
    subscription: {
      type: 'free',
      status: 'trial',
      expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
    },
    settings: {
      language: 'tr', // Default to Turkish
      timezone: 'Europe/Istanbul',
      notifications: true,
      integrations: {
        whatsapp: true,
        telegram: true,
        calendar: true,
      },
    },
  },
  isDemo: true,
  stats: mockData.tr.stats,
  appointments: mockData.tr.appointments,
  interactions: mockData.tr.interactions,
  loading: false,
  error: null,

  initializeDashboard: async () => {
    try {
      set({ loading: true, error: null });
      
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        // If no authenticated user, use demo mode
        const demoUser: DashboardUser = {
          id: 'demo-user',
          email: 'demo@beonebot.com',
          name: 'Demo User',
          subscription: {
            type: 'free',
            status: 'trial',
            expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
          },
          settings: {
            language: 'tr', // Default to Turkish
            timezone: 'Europe/Istanbul',
            notifications: true,
            integrations: {
              whatsapp: true,
              telegram: true,
              calendar: true,
            },
          },
        };
        
        set({ 
          user: demoUser,
          loading: false, 
          isDemo: true,
          stats: mockData.tr.stats,
          appointments: mockData.tr.appointments,
          interactions: mockData.tr.interactions,
        });
        return;
      }

      // Check subscription status
      const { data: userData, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
        throw error;
      }

      const dashboardUser: DashboardUser = {
        id: userData.id,
        email: userData.email,
        name: userData.name,
        subscription: {
          type: userData.subscription_type || 'free',
          status: userData.subscription_status || 'trial',
          expiresAt: userData.subscription_expires_at,
        },
        settings: {
          language: userData.language || 'tr', // Default to Turkish
          timezone: userData.timezone || 'UTC',
          notifications: userData.notifications_enabled || false,
          integrations: {
            whatsapp: userData.whatsapp_enabled || false,
            telegram: userData.telegram_enabled || false,
            calendar: userData.calendar_enabled || false,
          },
        },
      };

      // If user has active paid subscription, fetch real data
      if (dashboardUser.subscription.status === 'active' && 
          dashboardUser.subscription.type !== 'free') {
        const [stats, appointments, interactions] = await Promise.all([
          fetchStats(user.id),
          fetchAppointments(user.id),
          fetchInteractions(user.id),
        ]);

        set({
          user: dashboardUser,
          isDemo: false,
          stats,
          appointments,
          interactions,
          loading: false,
        });
      } else {
        // Use mock data for free/trial users based on language preference
        const mockLang = dashboardUser.settings.language;
        set({
          user: dashboardUser,
          isDemo: true,
          stats: mockData[mockLang].stats,
          appointments: mockData[mockLang].appointments,
          interactions: mockData[mockLang].interactions,
          loading: false,
        });
      }
    } catch (error) {
      console.error('Dashboard initialization error:', error);
      set({ 
        error: 'Failed to initialize dashboard',
        loading: false,
        isDemo: true,
        user: {
          id: 'error-user',
          email: 'error@beonebot.com',
          name: 'Error User',
          subscription: {
            type: 'free',
            status: 'trial',
            expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
          },
          settings: {
            language: 'tr',
            timezone: 'Europe/Istanbul',
            notifications: false,
            integrations: {
              whatsapp: false,
              telegram: false,
              calendar: false,
            },
          },
        },
        stats: mockData.tr.stats,
        appointments: mockData.tr.appointments,
        interactions: mockData.tr.interactions,
      });
    }
  },

  updateUserSettings: async (settings: Partial<DashboardUser['settings']>) => {
    const { user } = get();
    if (!user) return;

    try {
      set({ loading: true, error: null });
      
      // If in demo mode, just update local state
      if (get().isDemo) {
        const mockLang = settings.language || user.settings.language;
        set({
          user: {
            ...user,
            settings: {
              ...user.settings,
              ...settings,
            },
          },
          stats: mockData[mockLang].stats,
          appointments: mockData[mockLang].appointments,
          interactions: mockData[mockLang].interactions,
          loading: false,
        });
        return;
      }

      // Update user settings in database
      const { error } = await supabase
        .from('users')
        .update({
          language: settings.language,
          timezone: settings.timezone,
          notifications_enabled: settings.notifications,
          whatsapp_enabled: settings.integrations?.whatsapp,
          telegram_enabled: settings.integrations?.telegram,
          calendar_enabled: settings.integrations?.calendar,
        })
        .eq('id', user.id);

      if (error) throw error;

      set({
        user: {
          ...user,
          settings: {
            ...user.settings,
            ...settings,
          },
        },
        loading: false,
      });
      
      // If language changed, refresh data
      if (settings.language && settings.language !== user.settings.language) {
        const [stats, appointments, interactions] = await Promise.all([
          fetchStats(user.id),
          fetchAppointments(user.id),
          fetchInteractions(user.id),
        ]);
        
        set({
          stats,
          appointments,
          interactions,
        });
      }
    } catch (error) {
      console.error('Settings update error:', error);
      set({ 
        error: 'Failed to update settings',
        loading: false 
      });
    }
  },

  // Add a new method to sync language with app
  syncLanguage: (language: 'en' | 'tr') => {
    const { user, isDemo } = get();
    
    if (user) {
      set({
        user: {
          ...user,
          settings: {
            ...user.settings,
            language,
          },
        },
      });
    }

    if (isDemo) {
      set({
        stats: mockData[language].stats,
        appointments: mockData[language].appointments,
        interactions: mockData[language].interactions,
      });
    } else if (user) {
      // If not in demo mode, update language in database and refresh data
      get().updateUserSettings({ language });
    }
  },
  
  // Add method to refresh dashboard data
  refreshDashboard: async () => {
    const { user, isDemo } = get();
    if (!user) return;
    
    try {
      set({ loading: true, error: null });
      
      if (isDemo) {
        const mockLang = user.settings.language;
        set({
          stats: mockData[mockLang].stats,
          appointments: mockData[mockLang].appointments,
          interactions: mockData[mockLang].interactions,
          loading: false,
        });
        return;
      }
      
      const [stats, appointments, interactions] = await Promise.all([
        fetchStats(user.id),
        fetchAppointments(user.id),
        fetchInteractions(user.id),
      ]);
      
      set({
        stats,
        appointments,
        interactions,
        loading: false,
      });
    } catch (error) {
      console.error('Dashboard refresh error:', error);
      set({ 
        error: 'Failed to refresh dashboard',
        loading: false 
      });
    }
  }
}));

async function fetchStats(userId: string): Promise<DashboardStats> {
  try {
    // Fetch real stats from Supabase
    const { data, error } = await supabase
      .from('dashboard_stats')
      .select('*')
      .eq('user_id', userId)
      .single();
    
    if (error) {
      console.error('Error fetching stats:', error);
      throw error;
    }
    
    if (!data) {
      throw new Error('No stats found');
    }
    
    return {
      totalAppointments: data.total_appointments,
      completedAppointments: data.completed_appointments,
      pendingAppointments: data.pending_appointments,
      totalCustomers: data.total_customers,
      averageResponseTime: data.average_response_time,
      customerSatisfaction: data.customer_satisfaction,
    };
  } catch (error) {
    console.error('Error in fetchStats:', error);
    // Return mock data as fallback
    const { user } = useDashboardStore.getState();
    const language = user?.settings?.language || 'tr';
    return mockData[language].stats;
  }
}

async function fetchAppointments(userId: string): Promise<AppointmentData[]> {
  try {
    // Fetch real appointments from Supabase
    const { data, error } = await supabase
      .from('appointments')
      .select('*')
      .eq('user_id', userId)
      .order('date', { ascending: true });
    
    if (error) {
      console.error('Error fetching appointments:', error);
      throw error;
    }
    
    if (!data || data.length === 0) {
      throw new Error('No appointments found');
    }
    
    return data.map(item => ({
      id: item.id,
      customerName: item.customer_name,
      date: item.date,
      time: item.time,
      status: item.status,
      service: item.service,
      notes: item.notes,
    }));
  } catch (error) {
    console.error('Error in fetchAppointments:', error);
    // Return mock data as fallback
    const { user } = useDashboardStore.getState();
    const language = user?.settings?.language || 'tr';
    return mockData[language].appointments;
  }
}

async function fetchInteractions(userId: string): Promise<CustomerInteraction[]> {
  try {
    // Fetch real interactions from Supabase
    const { data, error } = await supabase
      .from('customer_interactions')
      .select('*, customers(name)')
      .eq('user_id', userId)
      .order('timestamp', { ascending: false });
    
    if (error) {
      console.error('Error fetching interactions:', error);
      throw error;
    }
    
    if (!data || data.length === 0) {
      throw new Error('No interactions found');
    }
    
    return data.map(item => ({
      id: item.id,
      customerId: item.customer_id,
      customerName: item.customers?.name || 'Unknown Customer',
      channel: item.channel,
      timestamp: item.timestamp,
      message: item.message,
      status: item.status,
    }));
  } catch (error) {
    console.error('Error in fetchInteractions:', error);
    // Return mock data as fallback
    const { user } = useDashboardStore.getState();
    const language = user?.settings?.language || 'tr';
    return mockData[language].interactions;
  }
}