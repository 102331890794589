import React from 'react';
import { motion } from 'framer-motion';
import { Bell, X, Check } from 'lucide-react';

interface NotificationPanelProps {
  isDarkMode?: boolean;
  onClose?: () => void;
}

export function NotificationPanel({ isDarkMode = false, onClose }: NotificationPanelProps) {
  const [notifications, setNotifications] = React.useState<Notification[]>([
    {
      id: '1',
      title: 'Yeni randevu',
      message: 'Yarın saat 14:00\'de Ali Yılmaz ile randevunuz var.',
      time: '10 dakika önce',
      read: false,
      type: 'info',
    },
    {
      id: '2',
      title: 'Entegrasyon başarılı',
      message: 'WhatsApp entegrasyonu başarıyla tamamlandı.',
      time: '1 saat önce',
      read: false,
      type: 'success',
    },
    {
      id: '3',
      title: 'Abonelik hatırlatması',
      message: 'Premium aboneliğiniz 5 gün içinde sona erecek.',
      time: '3 saat önce',
      read: true,
      type: 'warning',
    },
  ]);

  const markAllAsRead = () => {
    setNotifications(notifications.map(n => ({ ...n, read: true })));
  };

  const markAsRead = (id: string) => {
    setNotifications(notifications.map(n => 
      n.id === id ? { ...n, read: true } : n
    ));
  };

  const removeNotification = (id: string) => {
    setNotifications(notifications.filter(n => n.id !== id));
  };

  const getTypeStyles = (type: Notification['type'], read: boolean) => {
    const baseStyles = read ? 'opacity-60 ' : '';
    
    switch (type) {
      case 'info':
        return `${baseStyles}${isDarkMode ? 'bg-blue-900/20 border-blue-700' : 'bg-blue-50 border-blue-200'}`;
      case 'success':
        return `${baseStyles}${isDarkMode ? 'bg-green-900/20 border-green-700' : 'bg-green-50 border-green-200'}`;
      case 'warning':
        return `${baseStyles}${isDarkMode ? 'bg-yellow-900/20 border-yellow-700' : 'bg-yellow-50 border-yellow-200'}`;
      case 'error':
        return `${baseStyles}${isDarkMode ? 'bg-red-900/20 border-red-700' : 'bg-red-50 border-red-200'}`;
      default:
        return baseStyles;
    }
  };

  const getTypeIcon = (type: Notification['type']) => {
    switch (type) {
      case 'info':
        return <Bell className={`w-4 h-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />;
      case 'success':
        return <Check className={`w-4 h-4 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />;
      case 'warning':
        return <Bell className={`w-4 h-4 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-500'}`} />;
      case 'error':
        return <Bell className={`w-4 h-4 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`} />;
      default:
        return null;
    }
  };

  return (
    <div className={`max-h-96 overflow-y-auto ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
      <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
        <h3 className="font-medium">Bildirimler</h3>
        {notifications.some(n => !n.read) && (
          <button 
            onClick={markAllAsRead}
            className={`text-xs ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}`}
          >
            Tümünü okundu işaretle
          </button>
        )}
      </div>
      
      {notifications.length === 0 ? (
        <div className="p-4 text-center text-gray-500 dark:text-gray-400">
          Yeni bildirim yok
        </div>
      ) : (
        <div>
          {notifications.map((notification) => (
            <motion.div
              key={notification.id}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, height: 0 }}
              className={`p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-100'} relative`}
              onClick={() => markAsRead(notification.id)}
            >
              <div className="flex">
                <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center mr-3 ${getTypeStyles(notification.type, notification.read)}`}>
                  {getTypeIcon(notification.type)}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-start justify-between">
                    <p className={`text-sm font-medium ${notification.read ? (isDarkMode ? 'text-gray-400' : 'text-gray-500') : ''}`}>
                      {notification.title}
                    </p>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        removeNotification(notification.id);
                      }}
                      className={`ml-2 p-1 rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
                    >
                      <X className="w-3 h-3 text-gray-400" />
                    </button>
                  </div>
                  <p className={`text-xs mt-1 ${notification.read ? (isDarkMode ? 'text-gray-500' : 'text-gray-400') : (isDarkMode ? 'text-gray-300' : 'text-gray-600')}`}>
                    {notification.message}
                  </p>
                  <p className="text-xs text-gray-400 mt-1">
                    {notification.time}
                  </p>
                </div>
              </div>
              {!notification.read && (
                <span className="absolute top-4 right-4 w-2 h-2 bg-blue-500 rounded-full"></span>
              )}
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
}

export default NotificationPanel;