import React from 'react';
import { motion } from 'framer-motion';
import { X, ChevronRight, Info } from 'lucide-react';

interface TutorialOverlayProps {
  onClose: () => void;
  language: string;
  isDarkMode?: boolean;
}

interface TutorialStep {
  title: string;
  description: string;
  image: string;
}

export function TutorialOverlay({ onClose, language, isDarkMode = false }: TutorialOverlayProps) {
  const [currentStep, setCurrentStep] = React.useState(0);

  const translations = {
    en: {
      title: 'Welcome to BOB Universe',
      skip: 'Skip Tutorial',
      next: 'Next',
      finish: 'Finish',
      steps: [
        {
          title: 'Welcome to BOB Universe',
          description: 'Your all-in-one dashboard for managing your BOB chatbot. Let\'s take a quick tour to help you get started.',
          image: '/assets/tutorial/welcome.png',
        },
        {
          title: 'Dashboard',
          description: 'View key metrics and insights about your chatbot\'s performance at a glance.',
          image: '/assets/tutorial/dashboard.png',
        },
        {
          title: 'Bot Builder',
          description: 'Customize your chatbot\'s appearance, personality, and behavior to match your brand.',
          image: '/assets/tutorial/bot-builder.png',
        },
        {
          title: 'Integrations',
          description: 'Connect BOB with your favorite tools like WhatsApp, Email, and more.',
          image: '/assets/tutorial/integrations.png',
        },
        {
          title: 'You\'re All Set!',
          description: 'You\'re now ready to start using BOB Universe. If you need help, click the question mark icon in the top right corner.',
          image: '/assets/tutorial/complete.png',
        },
      ],
    },
    tr: {
      title: 'BOB Universe\'e Hoş Geldiniz',
      skip: 'Eğitimi Geç',
      next: 'İleri',
      finish: 'Bitir',
      steps: [
        {
          title: 'BOB Universe\'e Hoş Geldiniz',
          description: 'BOB chatbot\'unuzu yönetmek için hepsi bir arada kontrol paneliniz. Başlamanıza yardımcı olmak için hızlı bir tur atalım.',
          image: '/assets/tutorial/welcome.png',
        },
        {
          title: 'Kontrol Paneli',
          description: 'Chatbot\'unuzun performansı hakkında önemli metrikleri ve içgörüleri bir bakışta görüntüleyin.',
          image: '/assets/tutorial/dashboard.png',
        },
        {
          title: 'Bot Oluşturucu',
          description: 'Chatbot\'unuzun görünümünü, kişiliğini ve davranışını markanıza uyacak şekilde özelleştirin.',
          image: '/assets/tutorial/bot-builder.png',
        },
        {
          title: 'Entegrasyonlar',
          description: 'BOB\'u WhatsApp, E-posta ve daha fazlası gibi favori araçlarınızla bağlayın.',
          image: '/assets/tutorial/integrations.png',
        },
        {
          title: 'Hazırsınız!',
          description: 'Artık BOB Universe\'i kullanmaya başlamaya hazırsınız. Yardıma ihtiyacınız olursa, sağ üst köşedeki soru işareti simgesine tıklayın.',
          image: '/assets/tutorial/complete.png',
        },
      ],
    },
  };

  const t = translations[language as keyof typeof translations] || translations.en;
  const steps = t.steps;
  const currentStepData = steps[currentStep];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: 'spring', damping: 20, stiffness: 300 }}
        className={`relative w-full max-w-2xl rounded-xl shadow-2xl overflow-hidden ${
          isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className={`absolute top-4 right-4 p-2 rounded-full z-10 ${
            isDarkMode 
              ? 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white' 
              : 'bg-gray-100 text-gray-500 hover:bg-gray-200 hover:text-gray-700'
          } transition-colors`}
        >
          <X className="w-4 h-4" />
        </button>

        {/* Progress indicator */}
        <div className="absolute top-0 left-0 right-0 flex">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`h-1 flex-1 transition-colors duration-300 ${
                index <= currentStep 
                  ? 'bg-blue-500' 
                  : isDarkMode ? 'bg-gray-700' : 'bg-gray-200'
              }`}
            />
          ))}
        </div>

        <div className="flex flex-col md:flex-row">
          {/* Image section */}
          <div className="w-full md:w-1/2 bg-blue-500 flex items-center justify-center p-8">
            <div className="relative w-full h-48 md:h-64 flex items-center justify-center">
              <Info className="w-16 h-16 text-white opacity-20" />
              <div className="absolute inset-0 flex items-center justify-center">
                <p className="text-white text-center font-medium">
                  {currentStepData.title}
                </p>
              </div>
            </div>
          </div>

          {/* Content section */}
          <div className="w-full md:w-1/2 p-8">
            <h2 className="text-xl font-bold mb-4">{currentStepData.title}</h2>
            <p className={`mb-8 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              {currentStepData.description}
            </p>

            <div className="flex justify-between items-center">
              <button
                onClick={onClose}
                className={`text-sm ${
                  isDarkMode 
                    ? 'text-gray-400 hover:text-gray-300' 
                    : 'text-gray-500 hover:text-gray-700'
                } transition-colors`}
              >
                {t.skip}
              </button>
              <button
                onClick={handleNext}
                className="flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
              >
                <span>{currentStep === steps.length - 1 ? t.finish : t.next}</span>
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}