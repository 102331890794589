import React from 'react';
import { motion } from 'framer-motion';
import { Bot, User, Calendar, MessageSquare, Clock } from 'lucide-react';

export function ChatInterface({ language }: { language: 'en' | 'tr' }) {
  const translations = {
    en: {
      title: "See how BOB works",
      subtitle: "Example conversation flow for appointment notification",
      steps: [
        {
          user: "Hi, do I have any appointments today?",
          bot: "Hello! Yes, you have an appointment with Dr. Smith today at 2:30 PM for a dental check-up. Would you like me to send you the details?",
        },
        {
          user: "Yes, please. And can you tell me if I need to prepare anything?",
          bot: "I've sent the appointment details to your email. For your dental check-up today, it's recommended to brush and floss before your appointment. Also, please bring your insurance card if you have one.",
        },
        {
          user: "Thanks! Can I reschedule if needed?",
          bot: "Yes, you can reschedule if needed. Please let us know at least 2 hours in advance. Would you like me to help you reschedule your appointment?",
        },
      ],
      features: {
        notifications: "Appointment Notifications",
        support: "24/7 Support",
        ai: "AI Powered",
      }
    },
    tr: {
      title: "BOB nasıl çalışır?",
      subtitle: "Randevu bildirimi için örnek konuşma akışı",
      steps: [
        {
          user: "Merhaba, bugünkü randevum kaçtaydı acaba?",
          bot: "Merhaba! Evet, bugünkü randevunuz saat 14:30'da Dr. Yılmaz ile diş kontrolü için gözükmektedir. Size detayları göndermemi ister misiniz?",
        },
        {
          user: "Evet, lütfen. Herhangi bir hazırlık yapmam gerekiyor mu?",
          bot: "Randevu detaylarını e-postanıza gönderdim. Bugünkü diş kontrolünüz için, randevunuzdan önce diş fırçalamanız ve diş ipi kullanmanız önerilir. Ayrıca, varsa sigorta kartınızı getirmeyi unutmayın.",
        },
        {
          user: "Teşekkürler! Gerekirse randevuyu erteleyebilir miyim?",
          bot: "Evet, gerekirse randevunuzu erteleyebilirsiniz. Lütfen en az 2 saat önceden bize bildirin. Randevunuzu yeniden planlamanıza yardımcı olmamı ister misiniz?",
        },
      ],
      features: {
        notifications: "Randevu Bildirimleri",
        support: "7/24 Destek",
        ai: "Yapay Zeka Destekli",
      }
    },
  };

  const currentLang = translations[language];

  return (
    <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-6 text-white">
        <h2 className="text-2xl font-bold">{currentLang.title}</h2>
        <p className="mt-2 text-blue-100">{currentLang.subtitle}</p>
      </div>

      <div className="p-6 space-y-8">
        {currentLang.steps.map((step, index) => (
          <div key={index} className="space-y-4">
            {/* User Message */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="flex items-start justify-end space-x-2"
            >
              <div className="bg-blue-500 text-white rounded-2xl rounded-tr-none p-4 max-w-md shadow-md">
                <div className="flex items-center space-x-2 mb-2">
                  <User className="w-4 h-4" />
                  <span className="text-sm font-medium">You</span>
                </div>
                <p>{step.user}</p>
              </div>
            </motion.div>

            {/* Bot Message */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 + 0.1 }}
              className="flex items-start space-x-2"
            >
              <div className="bg-gray-100 rounded-2xl rounded-tl-none p-4 max-w-md shadow-md">
                <div className="flex items-center space-x-2 mb-2">
                  <Bot className="w-4 h-4 text-blue-500" />
                  <span className="text-sm font-medium text-blue-500">BOB</span>
                </div>
                <p className="text-gray-700 whitespace-pre-line">{step.bot}</p>
              </div>
            </motion.div>

            {/* Step Indicator */}
            {index < currentLang.steps.length - 1 && (
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 + 0.2 }}
                className="flex justify-center"
              >
                <div className="w-px h-8 bg-gray-200" />
              </motion.div>
            )}
          </div>
        ))}
      </div>

      {/* Features */}
      <div className="border-t border-gray-100 bg-gray-50 p-6">
        <div className="grid grid-cols-3 gap-4">
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-600">
            <Calendar className="w-5 h-5 text-blue-500" />
            <span>{currentLang.features.notifications}</span>
          </div>
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-600">
            <Clock className="w-5 h-5 text-blue-500" />
            <span>{currentLang.features.support}</span>
          </div>
          <div className="flex items-center justify-center space-x-2 text-sm text-gray-600">
            <Bot className="w-5 h-5 text-blue-500" />
            <span>{currentLang.features.ai}</span>
          </div>
        </div>
      </div>
    </div>
  );
}