import { create } from 'zustand';

interface ChatResponse {
  output: string;
  sessionId?: string;
  timestamp?: string;
}

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  timestamp: string;
}

// Local storage keys
const SESSION_ID_KEY = 'bob_session_id';
const CHAT_HISTORY_KEY = 'bob_chat_history';

// n8n configuration
const N8N_WORKFLOW_ID = 'ikWFsirC29iEFCJM';
const N8N_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkNmY4ZTFlOS1hZjQ1LTRhM2YtOWRkNy02N2U2ZmY0MTk5MWMiLCJpc3MiOiJuOG4iLCJhdWQiOiJwdWJsaWMtYXBpIiwiaWF0IjoxNzQ0MjE2NTgyfQ.o8Mrxa76YsQ16Gf66ok2Wkeu_t4DoojhjwfUae2NZXQ';
const N8N_BASE_URL = 'https://safakbirol.app.n8n.cloud';

// Generate a unique browser ID for the session
function generateBrowserId(): string {
  let browserId = '';
  
  try {
    browserId = localStorage.getItem(SESSION_ID_KEY) || '';
    
    if (!browserId) {
      browserId = 'user_' + Date.now() + '_' + Math.random().toString(36).substring(2, 9);
      localStorage.setItem(SESSION_ID_KEY, browserId);
    }
  } catch (e) {
    browserId = 'user_' + Date.now() + '_' + Math.random().toString(36).substring(2, 9);
  }
  
  return browserId;
}

// Get chat history from local storage
function getChatHistory(): ChatMessage[] {
  try {
    const history = localStorage.getItem(CHAT_HISTORY_KEY);
    return history ? JSON.parse(history) : [];
  } catch (e) {
    console.error('Error getting chat history:', e);
    return [];
  }
}

// Save chat history to local storage
function saveChatHistory(history: ChatMessage[]): void {
  try {
    localStorage.setItem(CHAT_HISTORY_KEY, JSON.stringify(history));
  } catch (e) {
    console.error('Error saving chat history:', e);
  }
}

// Send chat message to n8n workflow
export async function sendChatMessage(message: string): Promise<string> {
  try {
    // Generate a unique ID for the user
    const sessionId = generateBrowserId();
    
    // Add user message to local history
    const history = getChatHistory();
    history.push({
      role: 'user',
      content: message,
      timestamp: new Date().toISOString()
    });
    saveChatHistory(history);
    
    // Send request to n8n webhook
    const response = await fetch(`${N8N_BASE_URL}/webhook/bob-chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${N8N_API_KEY}`
      },
      body: JSON.stringify({ 
        chatInput: message,
        sessionId,
        language: 'tr',
        workflowId: N8N_WORKFLOW_ID
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const output = data.output || "Üzgünüm, şu anda yanıt veremiyorum. Lütfen daha sonra tekrar deneyin.";

    // Add bot response to local history
    history.push({
      role: 'assistant',
      content: output,
      timestamp: new Date().toISOString()
    });
    saveChatHistory(history);

    return output;
  } catch (error) {
    console.error('Error sending message:', error);
    return 'Üzgünüm, şu anda yanıt veremiyorum. Lütfen daha sonra tekrar deneyin.';
  }
}

// Get conversation history
export function getConversationHistory(): ChatMessage[] {
  return getChatHistory();
}

// Reset user session
export function resetChatSession(): void {
  try {
    localStorage.removeItem(SESSION_ID_KEY);
    localStorage.removeItem(CHAT_HISTORY_KEY);
  } catch (e) {
    console.error('Error resetting chat session:', e);
  }
}

// Execute n8n workflow directly
export async function executeN8nWorkflow(data: any): Promise<any> {
  try {
    const response = await fetch(`${N8N_BASE_URL}/api/v1/workflows/${N8N_WORKFLOW_ID}/execute`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-N8N-API-KEY': N8N_API_KEY
      },
      body: JSON.stringify({ data }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error executing n8n workflow:', error);
    throw error;
  }
}