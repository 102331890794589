import React from 'react';
import { motion } from 'framer-motion';
import { Book, Code, Terminal, Settings, Database, Shield, Sparkles, GraduationCap, BookOpen, Lightbulb } from 'lucide-react';

interface DocumentationPageProps {
  language: 'en' | 'tr';
}

export function DocumentationPage({ language }: DocumentationPageProps) {
  const translations = {
    en: {
      title: "BOB Academy",
      subtitle: "Learn about AI and chatbot technologies to maximize your platform usage",
      sections: [
        {
          icon: BookOpen,
          title: "Basic Training",
          content: "Get started with the fundamentals of AI and chatbot technologies.",
          links: [
            "Introduction to AI 101",
            "What are Large Language Models (LLM)?",
            "Benefits of AI for Your Business",
            "Chatbot Basics",
            "How to Choose the Right Chatbot Platform",
            "Advantages of Using Chatbots"
          ]
        },
        {
          icon: GraduationCap,
          title: "Advanced Training",
          content: "Take your knowledge to the next level with advanced topics.",
          links: [
            "Prompt Engineering",
            "Effective Prompt Design",
            "Prompt Tips for Improving User Experience",
            "Fine-Tuning",
            "Customizing Models for Your Business",
            "Best Practices for Fine-Tuning"
          ]
        },
        {
          icon: Lightbulb,
          title: "Stories and Inspiration",
          content: "Learn from success stories and get inspired.",
          links: [
            "ChatGPT Success Story",
            "OpenAI and Sam Altman's Story",
            "Successful Chatbot Implementation Examples and Case Studies",
            "Turkish LLM Success Story: The Rise of TRGPT"
          ]
        },
        {
          icon: Book,
          title: "BOB User Guides",
          content: "Learn how to use BOB effectively with our comprehensive guides.",
          links: [
            "BOB Dashboard Guide",
            "Reviewing Daily and Monthly Reports",
            "BOB Calendar Guide",
            "Appointment Management",
            "Customizing Calendar Settings",
            "BOB Settings Guide",
            "Personalized Notification Settings",
            "Integration Settings"
          ]
        },
        {
          icon: Terminal,
          title: "BOB Integrations",
          content: "Connect BOB with your favorite tools and platforms.",
          links: [
            "WhatsApp Integration Setup",
            "Website Integration Guide",
            "Telegram Bot Setup"
          ]
        }
      ]
    },
    tr: {
      title: "BOB Akademi",
      subtitle: "Yapay zeka ve chatbot teknolojileri konusunda bilgi edinerek platformu daha verimli kullanın",
      sections: [
        {
          icon: BookOpen,
          title: "Temel Eğitimler",
          content: "Yapay zeka ve chatbot teknolojilerinin temelleriyle başlayın.",
          links: [
            "Yapay Zekaya Giriş 101",
            "Büyük Dil Modelleri (LLM) Nedir?",
            "Yapay Zekanın İşletmenize Sağlayabileceği Faydalar",
            "Chatbot Temelleri",
            "Doğru Chatbot Platformu Nasıl Seçilir?",
            "Chatbot Kullanmanın Avantajları"
          ]
        },
        {
          icon: GraduationCap,
          title: "İleri Seviye Eğitimler",
          content: "İleri düzey konularla bilginizi bir sonraki seviyeye taşıyın.",
          links: [
            "Prompt Engineering (Komut Mühendisliği)",
            "Etkili Prompt Tasarımı",
            "Kullanıcı Deneyimini Geliştirmek için Prompt İpuçları",
            "Fine-Tuning (Model İnce Ayarları)",
            "Modelinizi İşletmeniz İçin Özelleştirme",
            "Fine-Tuning İçin En İyi Uygulamalar"
          ]
        },
        {
          icon: Lightbulb,
          title: "Hikayeler ve İlham",
          content: "Başarı hikayelerinden öğrenin ve ilham alın.",
          links: [
            "ChatGPT'nin Başarı Hikayesi",
            "OpenAI ve Sam Altman'ın Hikayesi",
            "Başarılı Chatbot Uygulama Örnekleri ve Vaka Analizleri",
            "Türkiye'den Yerli LLM Başarı Hikayesi: Türkçe Büyük Dil Modeli TRGPT'nin Yükselişi"
          ]
        },
        {
          icon: Book,
          title: "BOB Kullanıcı Rehberleri",
          content: "Kapsamlı rehberlerimizle BOB'u etkili bir şekilde kullanmayı öğrenin.",
          links: [
            "BOB Kontrol Paneli Rehberi",
            "Günlük ve Aylık Raporları İnceleme",
            "BOB Takvim Rehberi",
            "Randevu Yönetimi Nasıl Yapılır?",
            "Takvim Ayarlarını Kişiselleştirme",
            "BOB Ayarlar Rehberi",
            "Kişiselleştirilmiş Bildirim Ayarları",
            "Entegrasyon Ayarları Nasıl Yapılır?"
          ]
        },
        {
          icon: Terminal,
          title: "BOB Entegrasyonları",
          content: "BOB'u favori araçlarınız ve platformlarınızla bağlayın.",
          links: [
            "WhatsApp Entegrasyonu Kurulumu",
            "Web Sitesi Entegrasyonu Nasıl Yapılır?",
            "Telegram Bot Kurulumu"
          ]
        }
      ]
    }
  };

  const currentLang = translations[language];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            {currentLang.title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl text-gray-600"
          >
            {currentLang.subtitle}
          </motion.p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {currentLang.sections.map((section, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                <section.icon className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {section.title}
              </h3>
              <p className="text-gray-600 mb-4">
                {section.content}
              </p>
              <ul className="space-y-2">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex} className="flex items-center text-blue-600 hover:text-blue-700 cursor-pointer">
                    <span className="w-1.5 h-1.5 bg-blue-500 rounded-full mr-2" />
                    {link}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}