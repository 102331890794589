import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Moon, Bell, Globe2, Shield, Key, Check, Loader2, RefreshCw, AlertCircle, Eye, EyeOff } from 'lucide-react';
import { useDashboardStore } from '../../../store/dashboardStore';
import { useSettingsStore, UserSettings } from '../../../store/settingsStore';

export default function Settings() {
  const user = useDashboardStore(state => state.user);
  const { 
    settings, 
    loading, 
    error, 
    fetchSettings, 
    updateSettings, 
    regenerateApiKey, 
    toggleSetting 
  } = useSettingsStore();
  
  const [showApiKey, setShowApiKey] = useState(false);
  const [apiKey, setApiKey] = useState('••••••••••••••••');
  const [regeneratingKey, setRegeneratingKey] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // Fetch settings when component mounts
  useEffect(() => {
    if (user) {
      fetchSettings(user.id);
    }
  }, [user, fetchSettings]);

  // Update apiKey state when settings change
  useEffect(() => {
    if (settings?.apiKey) {
      setApiKey(settings.apiKey);
    }
  }, [settings]);

  // Clear success message after 3 seconds
  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const language = settings?.language || user?.settings?.language || 'tr';

  const translations = {
    en: {
      title: 'Settings',
      sections: {
        appearance: {
          title: 'Appearance',
          description: 'Customize how BOB Universe looks',
          settings: {
            theme: {
              label: 'Theme',
              options: ['System', 'Light', 'Dark'],
            },
            accentColor: {
              label: 'Accent Color',
              options: ['Blue', 'Purple', 'Green', 'Red'],
            },
          },
        },
        notifications: {
          title: 'Notifications',
          description: 'Manage your notification preferences',
          settings: {
            email: {
              label: 'Email Notifications',
            },
            push: {
              label: 'Push Notifications',
            },
          },
        },
        language: {
          title: 'Language & Region',
          description: 'Set your language and regional preferences',
          settings: {
            language: {
              label: 'Language',
              options: ['English', 'Turkish'],
            },
            timezone: {
              label: 'Timezone',
              options: ['UTC+03:00 Istanbul', 'UTC+00:00 London', 'UTC-05:00 New York'],
            },
          },
        },
        security: {
          title: 'Security',
          description: 'Manage your security settings',
          settings: {
            twoFactor: {
              label: 'Two-Factor Authentication',
            },
            sessionTimeout: {
              label: 'Session Timeout',
              options: ['15 minutes', '30 minutes', '1 hour', '4 hours'],
            },
          },
        },
        api: {
          title: 'API Access',
          description: 'Manage your API keys and access tokens',
          settings: {
            apiKey: {
              label: 'API Key',
              value: '••••••••••••••••',
              regenerate: 'Regenerate',
              show: 'Show',
              hide: 'Hide',
            },
          },
        },
      },
      success: {
        saved: 'Settings saved successfully',
        apiKey: 'API key regenerated successfully',
      },
      error: {
        general: 'An error occurred',
        retry: 'Retry',
      },
      loading: 'Loading settings...',
      refresh: 'Refresh',
    },
    tr: {
      title: 'Ayarlar',
      sections: {
        appearance: {
          title: 'Görünüm',
          description: 'BOB Universe görünümünü özelleştirin',
          settings: {
            theme: {
              label: 'Tema',
              options: ['Sistem', 'Açık', 'Koyu'],
            },
            accentColor: {
              label: 'Vurgu Rengi',
              options: ['Mavi', 'Mor', 'Yeşil', 'Kırmızı'],
            },
          },
        },
        notifications: {
          title: 'Bildirimler',
          description: 'Bildirim tercihlerinizi yönetin',
          settings: {
            email: {
              label: 'E-posta Bildirimleri',
            },
            push: {
              label: 'Anlık Bildirimler',
            },
          },
        },
        language: {
          title: 'Dil ve Bölge',
          description: 'Dil ve bölge tercihlerinizi ayarlayın',
          settings: {
            language: {
              label: 'Dil',
              options: ['İngilizce', 'Türkçe'],
            },
            timezone: {
              label: 'Saat Dilimi',
              options: ['UTC+03:00 İstanbul', 'UTC+00:00 Londra', 'UTC-05:00 New York'],
            },
          },
        },
        security: {
          title: 'Güvenlik',
          description: 'Güvenlik ayarlarınızı yönetin',
          settings: {
            twoFactor: {
              label: 'İki Faktörlü Kimlik Doğrulama',
            },
            sessionTimeout: {
              label: 'Oturum Zaman Aşımı',
              options: ['15 dakika', '30 dakika', '1 saat', '4 saat'],
            },
          },
        },
        api: {
          title: 'API Erişimi',
          description: 'API anahtarlarınızı ve erişim tokenlarınızı yönetin',
          settings: {
            apiKey: {
              label: 'API Anahtarı',
              value: '••••••••••••••••',
              regenerate: 'Yeniden Oluştur',
              show: 'Göster',
              hide: 'Gizle',
            },
          },
        },
      },
      success: {
        saved: 'Ayarlar başarıyla kaydedildi',
        apiKey: 'API anahtarı başarıyla yeniden oluşturuldu',
      },
      error: {
        general: 'Bir hata oluştu',
        retry: 'Tekrar Dene',
      },
      loading: 'Ayarlar yükleniyor...',
      refresh: 'Yenile',
    },
  };

  const t = translations[language];

  const handleRefresh = () => {
    if (user) {
      fetchSettings(user.id);
    }
  };

  const handleThemeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value.toLowerCase();
    updateSettings({ theme: value as UserSettings['theme'] });
    setSuccessMessage(t.success.saved);
  };

  const handleAccentColorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value.toLowerCase();
    updateSettings({ accentColor: value as UserSettings['accentColor'] });
    setSuccessMessage(t.success.saved);
  };

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const langCode = value === 'English' || value === 'İngilizce' ? 'en' : 'tr';
    updateSettings({ language: langCode });
    setSuccessMessage(t.success.saved);
  };

  const handleTimezoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateSettings({ timezone: event.target.value });
    setSuccessMessage(t.success.saved);
  };

  const handleSessionTimeoutChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    let minutes: UserSettings['sessionTimeout'] = '30';
    
    if (value.includes('15')) {
      minutes = '15';
    } else if (value.includes('30')) {
      minutes = '30';
    } else if (value.includes('1 hour') || value.includes('1 saat')) {
      minutes = '60';
    } else if (value.includes('4')) {
      minutes = '240';
    }
    
    updateSettings({ sessionTimeout: minutes });
    setSuccessMessage(t.success.saved);
  };

  const handleToggleEmailNotifications = () => {
    if (!settings) return;
    toggleSetting('emailNotifications');
    setSuccessMessage(t.success.saved);
  };

  const handleTogglePushNotifications = () => {
    if (!settings) return;
    toggleSetting('pushNotifications');
    setSuccessMessage(t.success.saved);
  };

  const handleToggleTwoFactorAuth = () => {
    if (!settings) return;
    toggleSetting('twoFactorAuth');
    setSuccessMessage(t.success.saved);
  };

  const handleRegenerateApiKey = async () => {
    if (!user) return;
    
    try {
      setRegeneratingKey(true);
      const newKey = await regenerateApiKey(user.id);
      setApiKey(newKey);
      setShowApiKey(true);
      setSuccessMessage(t.success.apiKey);
    } catch (error) {
      console.error('Error regenerating API key:', error);
    } finally {
      setRegeneratingKey(false);
    }
  };

  const settingsSections = [
    {
      id: 'appearance',
      icon: Moon,
      title: t.sections.appearance.title,
      description: t.sections.appearance.description,
      settings: {
        theme: {
          label: t.sections.appearance.settings.theme.label,
          options: t.sections.appearance.settings.theme.options,
          value: settings?.theme === 'system' 
            ? t.sections.appearance.settings.theme.options[0]
            : settings?.theme === 'light'
            ? t.sections.appearance.settings.theme.options[1]
            : t.sections.appearance.settings.theme.options[2],
          onChange: handleThemeChange,
        },
        accentColor: {
          label: t.sections.appearance.settings.accentColor.label,
          options: t.sections.appearance.settings.accentColor.options,
          value: settings?.accentColor === 'blue'
            ? t.sections.appearance.settings.accentColor.options[0]
            : settings?.accentColor === 'purple'
            ? t.sections.appearance.settings.accentColor.options[1]
            : settings?.accentColor === 'green'
            ? t.sections.appearance.settings.accentColor.options[2]
            : t.sections.appearance.settings.accentColor.options[3],
          onChange: handleAccentColorChange,
        },
      },
    },
    {
      id: 'notifications',
      icon: Bell,
      title: t.sections.notifications.title,
      description: t.sections.notifications.description,
      settings: {
        email: {
          label: t.sections.notifications.settings.email.label,
          value: settings?.emailNotifications,
          onChange: handleToggleEmailNotifications,
        },
        push: {
          label: t.sections.notifications.settings.push.label,
          value: settings?.pushNotifications,
          onChange: handleTogglePushNotifications,
        },
      },
    },
    {
      id: 'language',
      icon: Globe2,
      title: t.sections.language.title,
      description: t.sections.language.description,
      settings: {
        language: {
          label: t.sections.language.settings.language.label,
          options: t.sections.language.settings.language.options,
          value: settings?.language === 'en'
            ? t.sections.language.settings.language.options[0]
            : t.sections.language.settings.language.options[1],
          onChange: handleLanguageChange,
        },
        timezone: {
          label: t.sections.language.settings.timezone.label,
          options: t.sections.language.settings.timezone.options,
          value: settings?.timezone || t.sections.language.settings.timezone.options[0],
          onChange: handleTimezoneChange,
        },
      },
    },
    {
      id: 'security',
      icon: Shield,
      title: t.sections.security.title,
      description: t.sections.security.description,
      settings: {
        twoFactor: {
          label: t.sections.security.settings.twoFactor.label,
          value: settings?.twoFactorAuth,
          onChange: handleToggleTwoFactorAuth,
        },
        sessionTimeout: {
          label: t.sections.security.settings.sessionTimeout.label,
          options: t.sections.security.settings.sessionTimeout.options,
          value: settings?.sessionTimeout === '15'
            ? t.sections.security.settings.sessionTimeout.options[0]
            : settings?.sessionTimeout === '30'
            ? t.sections.security.settings.sessionTimeout.options[1]
            : settings?.sessionTimeout === '60'
            ? t.sections.security.settings.sessionTimeout.options[2]
            : t.sections.security.settings.sessionTimeout.options[3],
          onChange: handleSessionTimeoutChange,
        },
      },
    },
    {
      id: 'api',
      icon: Key,
      title: t.sections.api.title,
      description: t.sections.api.description,
      settings: {
        apiKey: {
          label: t.sections.api.settings.apiKey.label,
          value: apiKey,
          regenerate: t.sections.api.settings.apiKey.regenerate,
          show: showApiKey ? t.sections.api.settings.apiKey.hide : t.sections.api.settings.apiKey.show,
          onRegenerateClick: handleRegenerateApiKey,
          onToggleVisibility: () => setShowApiKey(!showApiKey),
        },
      },
    },
  ];

  return (
    <div className="space-y-4">
      {/* Header with title and refresh button */}
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">{t.title}</h2>
        <button
          onClick={handleRefresh}
          className="p-2 text-gray-600 hover:text-gray-900 rounded-lg hover:bg-gray-100"
          disabled={loading}
        >
          <RefreshCw className={`w-5 h-5 ${loading ? 'animate-spin' : ''}`} />
        </button>
      </div>

      {/* Success message */}
      {successMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg flex items-center mb-4">
          <Check className="w-5 h-5 mr-2" />
          {successMessage}
        </div>
      )}

      {/* Loading state */}
      {loading && !settings && (
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <Loader2 className="w-8 h-8 text-blue-500 animate-spin mx-auto mb-4" />
            <p className="text-gray-600">{t.loading}</p>
          </div>
        </div>
      )}

      {/* Error state */}
      {error && !settings && (
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <AlertCircle className="w-8 h-8 text-red-500 mx-auto mb-4" />
            <p className="text-red-600 mb-2">{t.error.general}</p>
            <button
              onClick={handleRefresh}
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              {t.error.retry}
            </button>
          </div>
        </div>
      )}

      {/* Settings sections */}
      {settings && (
        <div className="space-y-4">
          {settingsSections.map((section, index) => (
            <motion.div
              key={section.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-xl border border-gray-200 p-6 hover:shadow-lg transition-shadow"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className="p-3 bg-blue-50 rounded-lg">
                  <section.icon className="w-6 h-6 text-blue-500" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">{section.title}</h3>
                  <p className="text-gray-600 text-sm">{section.description}</p>
                </div>
              </div>

              <div className="space-y-4">
                {Object.entries(section.settings).map(([key, setting]) => (
                  <div key={key} className="flex items-center justify-between">
                    <label className="text-sm font-medium text-gray-700">{setting.label}</label>
                    {'options' in setting ? (
                      <select
                        value={setting.value}
                        onChange={setting.onChange}
                        className="bg-gray-50 border border-gray-200 rounded-lg px-3 py-2 text-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500"
                      >
                        {setting.options.map((option) => (
                          <option key={option} value={option}>{option}</option>
                        ))}
                      </select>
                    ) : 'regenerate' in setting ? (
                      <div className="flex items-center space-x-2">
                        <div className="relative">
                          <input
                            type={showApiKey ? "text" : "password"}
                            value={setting.value}
                            readOnly
                            className="bg-gray-50 border border-gray-200 rounded-lg px-3 py-2 text-sm text-gray-700 pr-10"
                          />
                          <button 
                            onClick={setting.onToggleVisibility}
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                          >
                            {showApiKey ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                          </button>
                        </div>
                        <button 
                          onClick={setting.onRegenerateClick}
                          disabled={regeneratingKey}
                          className="text-sm text-blue-500 hover:text-blue-600 font-medium flex items-center"
                        >
                          {regeneratingKey ? (
                            <>
                              <Loader2 className="w-3 h-3 mr-1 animate-spin" />
                              {t.sections.api.settings.apiKey.regenerate}
                            </>
                          ) : (
                            setting.regenerate
                          )}
                        </button>
                      </div>
                    ) : (
                      <button 
                        onClick={setting.onChange}
                        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                          setting.value ? 'bg-blue-500' : 'bg-gray-200 hover:bg-gray-300'
                        }`}
                      >
                        <span 
                          className={`inline-block h-4 w-4 transform rounded-full bg-white shadow transition-transform ${
                            setting.value ? 'translate-x-6' : 'translate-x-1'
                          }`} 
                        />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
}