import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Calendar, User } from 'lucide-react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getBlogPost, getRelatedPost } from '../lib/blog';
import { Helmet } from 'react-helmet-async';

interface BlogPostProps {
  language: 'en' | 'tr';
}

export function BlogPost({ language }: BlogPostProps) {
  const { slug } = useParams();
  const navigate = useNavigate();

  if (!slug) {
    return null;
  }

  const post = getBlogPost(language, slug);
  
  if (!post) {
    // Try to find the related post in the other language
    const otherLanguage = language === 'en' ? 'tr' : 'en';
    const relatedPost = getRelatedPost(slug, otherLanguage);
    
    if (relatedPost) {
      navigate(`/blog/${relatedPost.slug}`, { replace: true });
      return null;
    }
  }

  const translations = {
    en: {
      notFound: "Post not found",
      backToBlog: "Back to Blog",
      readTime: "min read",
      publishedOn: "Published on"
    },
    tr: {
      notFound: "Yazı bulunamadı",
      backToBlog: "Bloga Dön",
      readTime: "dk okuma",
      publishedOn: "Yayın tarihi"
    }
  };

  const currentLang = translations[language];

  if (!post) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-24">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              {currentLang.notFound}
            </h1>
            <Link
              to="/blog"
              className="inline-flex items-center space-x-2 text-blue-600 hover:text-blue-700"
            >
              <ArrowLeft className="w-4 h-4" />
              <span>{currentLang.backToBlog}</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // Calculate read time (roughly 200 words per minute)
  const wordCount = post.content.trim().split(/\s+/).length;
  const readTime = Math.ceil(wordCount / 200);

  return (
    <>
      <Helmet>
        <title>{post.title} | BeOneBot Blog</title>
        <meta name="description" content={post.excerpt} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.image_url} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
        <meta name="twitter:image" content={post.image_url} />
        <link rel="canonical" href={`https://beonebot.com/blog/${post.slug}`} />
        {language === 'tr' && <meta property="og:locale" content="tr_TR" />}
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-white to-blue-50 py-24">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <Link
            to="/blog"
            className="inline-flex items-center space-x-2 text-blue-600 hover:text-blue-700 mb-8"
          >
            <ArrowLeft className="w-4 h-4" />
            <span>{currentLang.backToBlog}</span>
          </Link>

          <article className="bg-white rounded-2xl shadow-xl overflow-hidden">
            <div className="aspect-w-16 aspect-h-9 relative">
              <img
                src={post.image_url}
                alt={post.title}
                className="w-full h-[500px] object-cover"
                loading="lazy"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 75vw, 1200px"
              />
            </div>
            
            <div className="p-8 md:p-12">
              <div className="flex flex-wrap items-center gap-4 text-sm text-gray-500 mb-6">
                <div className="flex items-center space-x-2">
                  <User className="w-4 h-4" />
                  <span>{post.author}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <Calendar className="w-4 h-4" />
                  <span>
                    {currentLang.publishedOn} {new Date(post.date).toLocaleDateString(language === 'tr' ? 'tr-TR' : 'en-US')}
                  </span>
                </div>
                <span className="text-blue-600">{post.category}</span>
                <span>• {readTime} {currentLang.readTime}</span>
              </div>

              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-8">
                {post.title}
              </h1>

              <p className="text-xl text-gray-600 mb-12 leading-relaxed">
                {post.excerpt}
              </p>
              
              <div 
                className="prose prose-lg max-w-none prose-headings:text-gray-900 prose-headings:font-bold prose-p:text-gray-600 prose-a:text-blue-600 prose-a:no-underline hover:prose-a:underline prose-img:rounded-xl prose-p:leading-relaxed"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
          </article>
        </div>
      </div>
    </>
  );
}