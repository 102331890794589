import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';

interface DemoNoticeProps {
  language: string;
  isDarkMode?: boolean;
}

export function DemoNotice({ language, isDarkMode = false }: DemoNoticeProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const translations = {
    en: {
      title: 'Demo Mode',
      description: 'You are currently in demo mode. Some features are limited. Upgrade to a paid plan to access all features.',
      upgrade: 'Upgrade Now',
    },
    tr: {
      title: 'Demo Modu',
      description: 'Şu anda demo modundasınız. Bazı özellikler sınırlıdır. Tüm özelliklere erişmek için ücretli bir plana yükseltin.',
      upgrade: 'Şimdi Yükselt',
    },
  };

  const t = translations[language as keyof typeof translations] || translations.tr;

  const handleUpgradeClick = () => {
    navigate('/', { state: { scrollTo: 'contact' } });
  };

  return (
    <div className={`fixed bottom-0 left-0 right-0 z-10 border-t max-w-full ${
      isDarkMode 
        ? 'bg-yellow-900/20 border-yellow-700/50 text-yellow-200' 
        : 'bg-yellow-50 border-yellow-200 text-yellow-800'
    } transition-colors duration-200`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <AlertTriangle className={`w-4 h-4 mr-2 ${isDarkMode ? 'text-yellow-400' : 'text-yellow-500'}`} />
          <span className="text-sm font-medium">{t.title}</span>
          <span className={`ml-2 text-sm ${isDarkMode ? 'text-yellow-300/80' : 'text-yellow-700'} hidden sm:inline`}>
            {t.description}
          </span>
        </div>
        <button 
          onClick={handleUpgradeClick}
          className={`text-xs font-medium px-3 py-1 rounded-lg ${
            isDarkMode 
              ? 'bg-yellow-700 text-white hover:bg-yellow-600' 
              : 'bg-yellow-500 text-white hover:bg-yellow-600'
          } transition-colors`}
        >
          {t.upgrade}
        </button>
      </div>
    </div>
  );
}